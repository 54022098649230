import { toast } from "react-toastify";
import { apiUrl } from "./constantVariables";
import axios from "axios";

class RestApiData {
  apiUrl;
  constructor() {}
  async restApi(method, url, data) {
    const token = localStorage.getItem("token");

    try {
      const request = {
        method,
        url: url,
        headers: { Authorization: `Bearer ${token}` },
        data,
      };
      const response = await axios(request);
      // toast.success(response?.data.message);
      return response;
    } catch (error) {
      if (error) {
        console.error("Response Data :->", error.message);
        // toast.error(error.message);
      }
      if (error.response.status === 401) {
        window.location.href = "/auth";
      }

      if (
        error.response?.statusText === "Bad Request" &&
        error.response.status === 400
      ) {
        console.log("400 Error Response ->", error.response);
        // return error.response;
        toast.error(error.response?.data?.message);
        // window.location.href = "/auth";
      } else {
        throw error;
      }
    }
  }

  async adminLogin(admin) {
    return this.restApi("post", `${apiUrl}admin/login`, admin);
  }

  // Categories

  async getAllCategories(page, sort, column, search) {
    return this.restApi(
      "get",
      `${apiUrl}category?page=${page}&sortOrder=${sort}&sortBy=${column}&search=${search}`,
      ""
    );
  }

  async addNewCategory(category) {
    return this.restApi("post", `${apiUrl}category`, category);
  }

  async updatedCategory(category) {
    return this.restApi("put", `${apiUrl}category`, category);
  }

  // Services

  async getAllServices(page, sort, column, search) {
    return this.restApi(
      "get",
      `${apiUrl}category/sub-category?page=${page}&sortOrder=${sort}&sortBy=${column}&search=${search}`,
      ""
    );
  }

  // Get Sub Category

  async getSubCategoriesData(data) {
    return this.restApi(
      "get",
      `${apiUrl}category/sub-category/search?name=${data}`,
      ""
    );
  }

  // Get Category

  async getCategoriesData(data) {
    return this.restApi("get", `${apiUrl}category/search?name=${data}`, "");
  }

  async addNewService(service) {
    return this.restApi("post", `${apiUrl}category/sub-category`, service);
  }

  async updatedService(category) {
    return this.restApi("put", `${apiUrl}category/sub-category`, category);
  }

  // Vendors

  async addVendor(vendor) {
    return this.restApi("post", `${apiUrl}vendor`, vendor);
  }

  async getVendors(page, sort, column, search) {
    return this.restApi(
      "get",
      `${apiUrl}vendor/all?page=${page}&sortOrder=${sort}&sortBy=${column}&search=${search}`,
      ""
    );
  }

  // Get unverify Vendor

  async getUnVerifyVendors(page, sort, column, search) {
    return this.restApi(
      "get",
      `${apiUrl}vendor/unverified?page=${page}&sortOrder=${sort}&sortBy=${column}&search=${search}`,
      ""
    );
  }

  async updatedVendor(vendor) {
    return this.restApi("put", `${apiUrl}vendor`, vendor);
  }

  async getCategoryServices(id) {
    return this.restApi(
      "get",
      `${apiUrl}category/service/search?categoryId=${id}`,
      ""
    );
  }

  // Vendor Services
  async getVendorsServices(id, page, sort, column, search) {
    return this.restApi(
      "get",
      `${apiUrl}vendor/service?vendorId=${id}&page=${page}&sortOrder=${sort}&sortBy=${column}&search=${search}`,
      ""
    );
  }

  async addNewVendorService(data) {
    return this.restApi("post", `${apiUrl}vendor/service`, data);
  }

  // Edit Vendor
  async editVendor(id) {
    return this.restApi("get", `${apiUrl}vendor?vendorId=${id}`, "");
  }

  async updateVendorService(data) {
    return this.restApi("put", `${apiUrl}vendor/service`, data);
  }
  async getServiceById(id) {
    return this.restApi("get", `${apiUrl}vendor/service/${id}`, "");
  }

  // vendor details
  async getVendorDetails(id) {
    return this.restApi("get", `${apiUrl}vendor?vendorId=${id}`, "");
  }

  //Vendor Leads
  async getVendorLeads(id, page, limit) {
    return this.restApi(
      "get",
      `${apiUrl}leads/vendorLeads?vendorId=${id}&page=${page}&limit=${limit}`,
      ""
    );
  }

  // Change Trending Status
  async changeTrendingStatus(data) {
    return this.restApi("post", `${apiUrl}category/trending-category`, data);
  }

  // Change show in header

  async changeShowHeaderStatus(data) {
    return this.restApi("post", `${apiUrl}category/show-in-header`, data);
  }

  // Changes feature category status
  async changeFeatureStatus(data) {
    return this.restApi("post", `${apiUrl}category/feature-category`, data);
  }

  //Get All Leads
  async getAllLeads(page, sort, column, search) {
    return this.restApi(
      "get",
      `${apiUrl}leads?page=${page}&sortOrder=${sort}&sortBy=${column}&search=${search}`,
      ""
    );
  }

  // Get Lead Details
  async getLeadDetails(id) {
    return this.restApi("get", `${apiUrl}leads/details?leadId=${id}`, "");
  }

  //Get All Customer
  async getAllCustomer(page, sort, column, search) {
    return this.restApi(
      "get",
      `${apiUrl}user/customers?page=${page}&sortOrder=${sort}&sortBy=${column}&search=${search}`,
      ""
    );
  }

  // Get single Customer
  async getCustomerDetails(id) {
    return this.restApi(
      "get",
      `${apiUrl}user/customer/detail?customerId=${id}`,
      ""
    );
  }

  // Get User Leads
  async getUserLeadList(id) {
    return this.restApi("get", `${apiUrl}leads/customer?customerId=${id}`, "");
  }

  // Get All Notification
  async getAllNotification(skip, limit) {
    return this.restApi(
      "get",
      `${apiUrl}notification/all?skip=${skip}&limit=${limit}`,
      ""
    );
  }
  // Search Address
  async handleSearchAddress() {
    return this.restApi(
      "post",
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyAeHRibvUJGUzzU6zi1cKYvtJqkvlpRuSg&libraries=places`,
      ""
    );
  }

  // Update Status
  async updateStatusCode(data) {
    return this.restApi("patch", `${apiUrl}leads/status`, data);
  }

  async updateProbabilityCode(data) {
    return this.restApi("patch", `${apiUrl}leads/probability`, data);
  }

  async updateVendorStatus(data) {
    return this.restApi("post", `${apiUrl}vendor/activeStatus`, data);
  }

  async updateVendorVerify(data) {
    return this.restApi("post", `${apiUrl}vendor/verify`, data);
  }

  async updateServiceStatus(data) {
    return this.restApi("post", `${apiUrl}vendor/service/activeStatus`, data);
  }
  // notification count
  async getNotificationCount() {
    return this.restApi("get", `${apiUrl}notification/count`, "");
  }

  // Dashboard
  async getDashboardVendorCount() {
    return this.restApi("get", `${apiUrl}user/admin/dashboard/user-counts`, "");
  }

  async getDashboardLeadsCount() {
    return this.restApi("get", `${apiUrl}leads/statusWiseLeadCount`, "");
  }

  // File Upload

  async uploadFileData(data) {
    return this.restApi("post", `${apiUrl}vendor/fileUpload`, data);
  }

  //Vendor service verified
  async updateVendorServiceVerify(data) {
    return this.restApi("post", `${apiUrl}vendor/service/verify`, data);
  }

  // User Logout
  async userLogout(token) {
    return this.restApi("post", `${apiUrl}user/logout`, token);
  }

  //Get Profiles List
  async getUserProfiles(column, sort) {
    return this.restApi(
      "get",
      `${apiUrl}admin/profile-role?sortBy=${column}&sortOrder=${sort}`,
      ""
    );
  }

  // Add Profile Role
  async addProfileRole(data) {
    return this.restApi("post", `${apiUrl}admin/profile-role`, data);
  }

  // Update Profile Role
  async updateProfileRole(data) {
    return this.restApi("put", `${apiUrl}admin/profile-role`, data);
  }

  // Delete Profile Role
  async deleteProfileRole(data) {
    return this.restApi("delete", `${apiUrl}admin/profile-role`, data);
  }

  //Get Users List
  async getUserList(page, sort, column, search) {
    return this.restApi(
      "get",
      `${apiUrl}admin/users?page=${page}&sortOrder=${sort}&sortBy=${column}&search=${search}`,
      ""
    );
  }

  // Add New User
  async addUserData(data) {
    return this.restApi("post", `${apiUrl}admin/user`, data);
  }

  // Update User Data
  async updateUserData(data) {
    return this.restApi("put", `${apiUrl}admin/user`, data);
  }

  //Update User Status
  async updateUserStatus(data) {
    return this.restApi("post", `${apiUrl}admin/user/active`, data);
  }
}

export const restRequest = new RestApiData();
