import React, { Fragment, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { restRequest } from "../../../../api-utils/apiQueries";
import { toast } from "react-toastify";

const AddNewProfile = ({
  showModal,
  toggle,
  selectedProfile,
  setIsUpdated,
}) => {
  const [profileName, setProfileName] = useState(
    selectedProfile ? selectedProfile?.name : ""
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      name: profileName,
    };
    if (selectedProfile && selectedProfile?._id) {
      data._id = selectedProfile._id;
    }

    let selected = selectedProfile;
    if (selected?._id) {
      let response = await restRequest.updateProfileRole(data);
      console.log("Update Response ->", response);
      toast.success(response.data.message);
      toggle();
      setIsUpdated(true);
    } else {
      let response = await restRequest.addProfileRole(data);
      console.log("Add Response ->", response);
      toast.success(response.data.message);
      toggle();
      setIsUpdated(true);
    }
  };

  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle} className="pt-4">
          <span className="fs-5 text-#534686">
            {selectedProfile ? "Edit Profile" : "Add New Profile"}{" "}
          </span>
        </ModalHeader>
        <ModalBody>
          <Form
            className="form-bookmark needs-validation"
            onSubmit={handleSubmit}
          >
            <FormGroup className="col-md-12">
              <Label>Profile Name</Label>
              <input
                className="form-control"
                value={profileName}
                type="text"
                autoComplete="off"
                onChange={(e) => setProfileName(e.target.value)}
                required
              />
            </FormGroup>
            <div className="d-flex gap-4 justify-content-end">
              <Btn
                attrBtn={{
                  color: "secondary ms-2",
                  onClick: toggle,
                }}
              >
                Cancel
              </Btn>
              {selectedProfile ? (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>
              ) : (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Save</Btn>
              )}
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddNewProfile;
