import React, { Fragment } from 'react'
import { H2, P } from '../../../AbstractElements'
import { EmailPassword } from '../../../Constant'

const FormHeader = () => {
  return (
    <Fragment>
      <H2 attrH2={{ className: "text-center" }}>{'Sign In With Email'}</H2>
      <P attrPara={{ className: 'text-center' }}>{EmailPassword}</P>
    </Fragment>
  )
}

export default FormHeader