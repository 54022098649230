import React, { createContext, useEffect, useState } from "react";
import { restRequest } from "./api-utils/apiQueries";
import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(0);

  // const fetchNotificationCount = async () => {
  //   let response = await restRequest.getNotificationCount();
  //   console.log("response count ->", response.data.data.count);
  //   let count = response.data.data.count;
  //   setNotificationCount(count);
  // };
  // useEffect(() => {
  //   fetchNotificationCount();
  // }, []);

  //   useEffect(() => {
  //     const handleVisibilityChange = () => {
  //       if (document.hidden) {
  //         setNotificationCount((prevCount) => prevCount + 1);
  //       }
  //     };

  //     document.addEventListener("visibilitychange", handleVisibilityChange);

  //     return () => {
  //       document.removeEventListener("visibilitychange", handleVisibilityChange);
  //     };
  //   }, []);

  return (
    <NotificationContext.Provider
      value={{ notificationCount, setNotificationCount }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
