import React, { Fragment, useEffect, useState } from "react";
import { restRequest } from "../../../api-utils/apiQueries";
import { useNavigate } from "react-router";
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import { Button, Card, CardBody, Col, Input, Row, Tooltip } from "reactstrap";
import { Edit3, Eye, Search, Trash2 } from "react-feather";
import DataTable from "react-data-table-component";
import { formatDate } from "../../commun-function/CommunFunction";
import { MdOutlineSpeakerNotes } from "react-icons/md";

const LeadsWrapper = () => {
  const [leads, setLeads] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [totalLeads, setTotalLeads] = useState();
  const [page, setPage] = useState(1);
  const [isUpdated, setIsUpdated] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState(0);
  const [vendorId, setVendorId] = useState();
  const [totalVendor, setTotalVendor] = useState(0);
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (index) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handlePageChange = (page) => {
    setPage(page);
    setIsUpdated(true);
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: false,
  };

  const handleSearchValue = (e) => {
    let data = e.target.value;
    setSearchValue(data);
  };

  const handleSort = (column, sortDirection) => {
    console.log("Column Data ->", column.id);
    setSortDirection(sortDirection === "desc" ? 0 : 1);
    setSortColumn(column.id);
    setIsUpdated(true);
  };

  const tableColumns = [
    {
      name: "Customer Name",
      selector: (row) => row?.user.person_name,
      id: "user.person_name",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.user.person_name}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Contact No",
      selector: (row) => row?.user.mobile_number,
      id: "user.mobile_number",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.user.mobile_number}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      id: "user.mobile_number",
      cell: (row) => (
        <span
          className={`text-white fw-medium p-1 px-2 rounded-3 ${
            row.status === 1
              ? "bg-primary"
              : row.status === 2
              ? "bg-warning"
              : row.status === 3
              ? "bg-success"
              : "bg-danger"
          }`}
        >
          {row.status === 1
            ? "New"
            : row.status === 2
            ? "Working"
            : row.status === 3
            ? "Deal Completed"
            : "Cancelled"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Service Name",
      selector: (row) => row?.service,
      id: "service",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.service}</span>
      ),
      sortable: true,
    },
    {
      name: "Vendor Name",
      selector: (row) => row?.vendor,
      id: "vendor",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.vendor}</span>
      ),
      sortable: true,
    },
    {
      name: "Added Date ",
      selector: (row) => row?.serviceCount,
      id: "createdAt",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {formatDate(row?.createdAt)}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row?.note,
      id: "createdAt",
      cell: (row, index) => (
        <span key={index} className="my-1">
          <Button
            className=" p-1 "
            color="primary"
            id={`Tooltip-${index}`}
            size="sm"
          >
            <MdOutlineSpeakerNotes size={20} />
          </Button>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen[index] || false}
            target={`Tooltip-${index}`}
            toggle={() => toggleTooltip(index)}
            className=""
          >
            <p className="border border-2 px-2 py-1 rounded-pill mb-1">
              {row.note ? row.note : "No Not"}
            </p>
          </Tooltip>
        </span>
      ),
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <button
          onClick={() => showLeadsDetails(d)}
          className="border border-none rounded text-success me-2"
        >
          <Eye />
        </button>,
      ],
    },
  ];

  console.log("Column name ->", sortColumn);
  const fetchVendors = async () => {
    let response = await restRequest.getAllLeads(
      page,
      sortDirection,
      sortColumn,
      searchValue
    );
    let data = response.data.data;
    console.log("Leads Response ->", response);
    setLeads(data.leads);
    setTotalPage(data.pager.totalPages);
    setTotalLeads(data.pager.totalRecords);
    setIsUpdated(false);
  };
  console.log("IS UPDATED ->", isUpdated);

  useEffect(() => {
    if (isUpdated) fetchVendors();
  }, [isUpdated, page]);

  const showLeadsDetails = (row) => {
    console.log("Row ->", row);
    navigate(`/leads/${row._id}`);
    setVendorId(row._id);
  };

  return (
    <Fragment>
      <Row>
        <div className="d-flex ">
          <Breadcrumbs mainTitle={`Leads (${totalLeads})`} />
          <div className="d-flex gap-4">
            {/* <Col className="left-header horizontal-wrapper ps-0"> */}
            <div
              className={`input-group left-header horizontal-wrapper`}
              style={{ width: "300px" }}
            >
              <Input
                type="text"
                placeholder="Search.."
                value={searchValue}
                onChange={handleSearchValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchValue(e.target.value);
                    setIsUpdated(true);
                  }
                }}
              />
              <span className="input-group-text mobile-search">
                <Search
                  onClick={() => {
                    setIsUpdated(true);
                  }}
                />
              </span>
            </div>
            {/* <Btn
            attrBtn={{
              color: "primary",
              className: "btn-block ",
              onClick: () => setShowModal(true),
            }}
          >
            <p className="mb-0 fs-7" style={{ width: "100px" }}>
              Add Vendor
            </p>
          </Btn> */}
            {/* </Col> */}
          </div>
        </div>
        <Col sm="12">
          <Card>
            <CardBody className="data-tables">
              <DataTable
                data={leads}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
                // selectableRows
                paginationServer
                paginationTotalRows={totalLeads}
                onChangePage={handlePageChange}
                paginationPerPage={20}
                paginationComponentOptions={paginationComponentOptions}
                onSort={handleSort}
                sortServer
              />
            </CardBody>
          </Card>
        </Col>
        {/* {showModal && (
        <AddNewVendor
          showModal={showModal}
          toggle={toggle}
          selectedVendor={selectedVendor}
          setIsUpdated={setIsUpdated}
        />
      )} */}
      </Row>
    </Fragment>
  );
};

export default LeadsWrapper;

// export const CustomMaterialPagination = ({
//   rowsPerPage,
//   rowCount,
//   onChangePage,
//   onChangeRowsPerPage,
//   currentPage,
// }) => (
//   <TablePagination
//     component="nav"
//     count={rowCount}
//     rowsPerPage={rowsPerPage}
//     page={currentPage - 1}
//     onChangePage={onChangePage}
//     onChangeRowsPerPage={({ target }) =>
//       onChangeRowsPerPage(Number(target.value))
//     }
//     ActionsComponent={TablePaginationActions}
//   />
// );
