import React, { Suspense, Fragment } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Loader from "../Layout/Loader";
import LayoutRoutes from "./LayoutRoutes";
import PrivateRoute from "./PrivateRoute";
import { authRoutes } from "./AuthRoutes";
import SignIn from "../Auth/Signin";

const Routers = () => {
  const login = localStorage.getItem("login");
  return (
    <Fragment>
      <BrowserRouter basename="/">
        <Suspense fallback={<Loader />}>
          <Routes>
            {login ? (
              <>
                {/* <Route
                  path={`${process.env.PUBLIC_URL}`}
                  element={<Navigate to="/dashboard" />}
                /> */}
                <Route path={`/`} element={<Navigate to={"/dashboard"} />} />
              </>
            ) : (
              ""
            )}
            <Route path={"/"} element={<PrivateRoute />}>
              <Route path={`/*`} element={<LayoutRoutes />} />
            </Route>

            <Route path={`/login`} element={<SignIn />} />
            {authRoutes.map(({ path, Component }, i) => (
              <Route path={path} element={Component} key={i} />
            ))}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Fragment>
  );
};
export default Routers;
