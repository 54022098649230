import React from "react";
import { PaddingLeft } from "../../../Constant";

const DotFile = ({ isActive }) => {
  const loaderStyle = {
    width: "10px",
    aspectRatio: "1",
    borderRadius: "50%",
    background: isActive ? "green" : "red",
    boxShadow: isActive ? "0 0 0 0 rgba(0, 0, 0, 0.10)" : "none",
    animation: isActive ? "l1 1s infinite" : "none",
  };

  return (
    <div>
      <style>
        {`
              @keyframes l1 {
                100% {
                  box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
                }
              }
            `}
      </style>
      <div style={loaderStyle}></div>
    </div>
  );
};

export default DotFile;
