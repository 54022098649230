import React, { useEffect, useState } from "react";
import VendorServicesWrapper from "./vendor-services/VendorServicesWrapper";
import { useParams } from "react-router";
import { restRequest } from "../../../api-utils/apiQueries";
import { Card, CardBody, Row, Col, CardHeader } from "reactstrap";
import {
  Breadcrumbs,
  Btn,
  H3,
  H4,
  H5,
  H6,
  Image,
  LI,
  P,
  UL,
} from "../../../AbstractElements";
import { TbSquareLetterPFilled, TbSquareLetterSFilled } from "react-icons/tb";
import { Mail, Phone, User } from "react-feather";
import {
  FaBuilding,
  FaLandmark,
  FaMountainCity,
  FaWhatsapp,
} from "react-icons/fa6";
import { FaMapMarkerAlt } from "react-icons/fa";
import VendorLeads from "./vendor-leads/VendorLeads";

const VendorDetails = () => {
  const { vendorId } = useParams();
  console.log("Id ->", vendorId);
  const [vendorData, setVendorData] = useState(null);
  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const fetchVendorDetailsData = async () => {
    let response = await restRequest.getVendorDetails(vendorId);
    let data = response.data.data;
    setVendorData(data);
    console.log("Detail Response ->", response.data.data);
  };

  useEffect(() => {
    if (vendorId) fetchVendorDetailsData();
  }, [vendorId]);

  console.log("Vendor Data ->", vendorData);

  return (
    <div>
      <Breadcrumbs mainTitle={"Vendor Details"} />
      {vendorData && (
        <div>
          <div className="user-profile">
            <Row>
              <Col sm="12">
                <Card
                  className="profile-header bg-size h-auto pb-0"
                  style={{
                    backgroundImage: ``,
                    backgroundSize: "cover",
                    backgroundPosition: "center center border",
                  }}
                >
                  <Row>
                    <Col sm="4">
                      <div className="profile-img-wrrap">
                        <Image
                          attrImage={{
                            className: "img-fluid bg-img-cover",
                            src: `${require("../../../assets/images/user-profile/bg-profile.jpg")}`,
                            alt: "",
                          }}
                        />
                      </div>
                      <div className="userpro-box">
                        <div className="img-wrraper">
                          <div className="avatar">
                            <Image
                              attrImage={{
                                className: "step1",
                                alt: "",
                                src: vendorData?.feature_image?.path,
                                dataintro: "This is Profile image",
                              }}
                            />
                          </div>
                          {/* <div className="icon-wrapper">
                            <i
                              className="icofont icofont-pencil-alt-5 step2"
                              data-intro="Change Profile image here"
                            ></i>
                          </div> */}
                        </div>
                        <div className="user-designation">
                          <div className="title">
                            <a target="_blank" href="#javascript">
                              <H4>{vendorData?.business_name}</H4>
                              <H6>{vendorData?.legal_business_name}</H6>
                              <H6>{vendorData?.gst}</H6>
                            </a>
                          </div>

                          <div className="follow">
                            <UL
                              attrUL={{
                                className: "follow-list flex-row simple-list",
                              }}
                            >
                              <LI>
                                <div className="follow-num counter">
                                  {vendorData?.leadsCount}
                                </div>
                                <span>Total leads</span>
                              </LI>
                              <LI>
                                <div className="follow-num counter">
                                  {vendorData?.serviceCount}
                                </div>
                                <span>Total services</span>
                              </LI>
                              <LI>
                                <div className="follow-num counter">0</div>
                                <span>Reviews</span>
                              </LI>
                            </UL>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* <Col
                      sm="6"
                      className="border h-[40vh]"
                      style={{ height: "100%" }}
                    >
                      <Card className="border [h-50vh]">
                        <CardHeader>
                          <H3 attrH3={{ className: "p-0" }}>Other Images </H3>
                        </CardHeader>
                        <div>
                          <CardBody className="card-body photos filter-cards-view">
                            <UL attrUL={{ className: "simple-list flex-row" }}>
                              {vendorData.other_image.map((item) => (
                                <LI key={item.id}>
                                  <div className="latest-post">
                                    <Image
                                      attrImage={{
                                        className: "img-fluid",
                                        alt: "",
                                        src: item.path,
                                      }}
                                    />
                                  </div>
                                </LI>
                              ))}
                            </UL>
                          </CardBody>
                        </div>
                      </Card>
                    </Col> */}
                    <Col sm="4">
                      <Card className="border">
                        <CardBody>
                          <div style={{ fontSize: "12px" }}>
                            <H3 className="underline">Business Time</H3>
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Day</th>
                                  <th scope="col">Open</th>
                                  <th scope="col">Close</th>
                                </tr>
                              </thead>
                              <tbody>
                                {daysOfWeek.map((day) => (
                                  <tr key={day} className=" p-0 m-0">
                                    <td className="  p-1 h-4">
                                      {day.charAt(0).toUpperCase() +
                                        day.slice(1)}
                                    </td>
                                    <td className="  p-1 h-4">
                                      {vendorData.business_time !== null &&
                                      vendorData.business_time[day]
                                        ? vendorData.business_time[day].open
                                        : "-"}
                                    </td>
                                    <td className=" p-1 h-4">
                                      {vendorData.business_time !== null &&
                                      vendorData.business_time[day]
                                        ? vendorData.business_time[day]?.close
                                        : "-"}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl="3" md="5" className="xl-35 box-col-30">
                <div className="default-according style-1 faq-accordion job-accordion">
                  <Row>
                    <Col xl="12">
                      <Card
                        data-intro="This is your Your detail"
                        className="step4"
                      >
                        <CardHeader>
                          <H3 attrH3={{ className: "p-0" }}>Vendor </H3>
                        </CardHeader>
                        <div>
                          <CardBody className="post-about">
                            <UL attrUL={{ className: "simple-list" }}>
                              <LI>
                                <div className="icon">
                                  <FaMapMarkerAlt />
                                </div>
                                <div>
                                  <H5>
                                    {vendorData?.business_address?.address
                                      ? vendorData?.business_address?.address
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Address
                                  </P>
                                </div>
                              </LI>
                              <LI>
                                <div className="icon">
                                  <FaBuilding />
                                </div>
                                <div>
                                  <H5>
                                    {vendorData?.business_address?.building_name
                                      ? vendorData?.business_address
                                          ?.building_name
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Building name
                                  </P>
                                </div>
                              </LI>{" "}
                              <LI>
                                <div className="icon">
                                  <FaMountainCity />
                                </div>
                                <div>
                                  <H5>
                                    {vendorData?.business_address?.city
                                      ? vendorData?.business_address?.city
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    City
                                  </P>
                                </div>
                              </LI>{" "}
                              <LI>
                                <div className="icon">
                                  <FaLandmark />
                                </div>
                                <div>
                                  <H5>
                                    {" "}
                                    {vendorData?.business_address?.landmark
                                      ? vendorData?.business_address?.landmark
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Landmark
                                  </P>
                                </div>
                              </LI>
                              <LI>
                                <div className="icon">
                                  <TbSquareLetterSFilled />
                                </div>
                                <div>
                                  <H5>
                                    {" "}
                                    {vendorData?.business_address?.street_name
                                      ? vendorData?.business_address
                                          ?.street_name
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Street name
                                  </P>
                                </div>
                              </LI>
                              <LI>
                                <div className="icon">
                                  <TbSquareLetterSFilled />
                                </div>
                                <div>
                                  <H5>
                                    {" "}
                                    {vendorData?.business_address?.state
                                      ? vendorData?.business_address?.state
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    State
                                  </P>
                                </div>
                              </LI>
                              <LI>
                                <div className="icon">
                                  <TbSquareLetterPFilled />
                                </div>
                                <div>
                                  <H5>
                                    {" "}
                                    {vendorData?.business_address?.pincode
                                      ? vendorData?.business_address?.pincode
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Pincode
                                  </P>
                                </div>
                              </LI>
                            </UL>
                          </CardBody>
                        </div>
                      </Card>
                    </Col>
                    <Col xl="12" md="12" sm="6">
                      <Card
                        data-intro="This is your Your detail"
                        className="step4"
                      >
                        <CardHeader>
                          <H3 attrH3={{ className: "p-0" }}>About Info </H3>
                        </CardHeader>
                        <div>
                          <CardBody className="post-about">
                            <UL attrUL={{ className: "simple-list" }}>
                              <LI>
                                <div className="icon">
                                  <User />
                                </div>
                                <div>
                                  <H5>
                                    {vendorData?.contact_info?.person_name
                                      ? vendorData?.contact_info?.person_name
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Person name
                                  </P>
                                </div>
                              </LI>
                              <LI>
                                <div className="icon">
                                  <Mail />
                                </div>
                                <div>
                                  <H5>
                                    {vendorData?.contact_info?.email
                                      ? vendorData?.contact_info?.email
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Email
                                  </P>
                                </div>
                              </LI>{" "}
                              <LI>
                                <div className="icon">
                                  <Phone />
                                </div>
                                <div>
                                  <H5>
                                    {vendorData?.contact_info?.mobile_number
                                      ? vendorData?.contact_info?.mobile_number
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Mobile number
                                  </P>
                                </div>
                              </LI>{" "}
                              <LI>
                                <div className="icon">
                                  <FaWhatsapp />
                                </div>
                                <div>
                                  <H5>
                                    {" "}
                                    {vendorData?.contact_info?.whatsapp_number
                                      ? vendorData?.contact_info
                                          ?.whatsapp_number
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Whatsapp number
                                  </P>
                                </div>
                              </LI>
                            </UL>
                          </CardBody>
                        </div>
                      </Card>
                    </Col>
                    <Col xl="12" md="12" sm="6">
                      <Card className="border [h-50vh]">
                        <CardHeader>
                          <H3 attrH3={{ className: "p-0" }}>Other Images </H3>
                        </CardHeader>
                        <div>
                          <CardBody className="card-body photos filter-cards-view">
                            <UL attrUL={{ className: "simple-list flex-row" }}>
                              {vendorData.other_image.map((item) => (
                                <LI key={item.id}>
                                  <div className="latest-post">
                                    <Image
                                      attrImage={{
                                        className: "img-fluid",
                                        alt: "",
                                        src: item.path,
                                      }}
                                    />
                                  </div>
                                </LI>
                              ))}
                            </UL>
                          </CardBody>
                        </div>
                      </Card>
                    </Col>
                    <Col xl="12" md="12" sm="6">
                      {/* <LatestPhotos /> */}
                    </Col>
                    <Col xl="12" md="12" sm="6">
                      {/* <FriendsProfile /> */}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xl="9" md="7" className="xl-65 box-col-70">
                <Row>
                  <VendorLeads
                    id={vendorId}
                    location={vendorData.business_address}
                  />
                  <VendorServicesWrapper
                    id={vendorId}
                    location={vendorData.business_address}
                  />
                  {/* <PostSecond /> */}
                  {/* <PostThird /> */}
                </Row>
                {/* <Col xl="9" md="7" className="xl-65 box-col-70"> */}
                {/* <Row>
                  <VendorLeads
                    id={vendorId}
                    location={vendorData.business_address}
                  /> */}
                {/* <PostSecond /> */}
                {/* <PostThird /> */}
                {/* </Row> */}
                {/* </Col> */}
              </Col>
            </Row>
          </div>
          {/* <div className="user-profile">
            <Row>
              <Col sm="12">
                <Card
                  className="profile-header bg-size h-auto"
                  style={{
                    backgroundImage: ``,
                    backgroundSize: "cover",
                    backgroundPosition: "center center border",
                  }}
                >
                  <Row>
                    <Col sm="3">
                      <div className="profile-img-wrrap">
                        <Image
                          attrImage={{
                            className: "img-fluid bg-img-cover",
                            src: `${require("../../../assets/images/user-profile/bg-profile.jpg")}`,
                            alt: "",
                          }}
                        />
                      </div>
                      <div className="userpro-box">
                        <div className="img-wrraper">
                          <div className="avatar">
                            <Image
                              attrImage={{
                                className: "step1",
                                alt: "",
                                src: ``,
                                dataintro: "This is Profile image",
                              }}
                            />
                          </div>
                          <div className="icon-wrapper">
                            <i
                              className="icofont icofont-pencil-alt-5 step2"
                              data-intro="Change Profile image here"
                            ></i>
                          </div>
                        </div>
                        <div className="user-designation">
                          <div className="title">
                            <a target="_blank" href="#javascript">
                              <H4>{vendorData.business_name}</H4>
                              <H6>{vendorData.legal_business_name}</H6>
                              <H6>{vendorData.gst}</H6>
                            </a>
                          </div>

                          <div className="follow">
                            <UL
                              attrUL={{
                                className: "follow-list flex-row simple-list",
                              }}
                            >
                              <LI>
                                <div className="follow-num counter"></div>
                                <span></span>
                              </LI>
                              <LI>
                                <div className="follow-num counter"></div>
                                <span></span>
                              </LI>
                              <LI>
                                <div className="follow-num counter"></div>
                                <span></span>
                              </LI>
                            </UL>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm="6" className="">
                      <Card className="border h-auto">
                        <CardHeader>
                          <H5 attrH5={{ className: "p-0 mb-0" }}>
                            <Btn
                              attrBtn={{
                                color: "transparent btn-link ps-0",
                                onClick: () => {
                                  setIsShow(!isShow);
                                },
                              }}
                            >
                              Other Images
                            </Btn>
                          </H5>
                        </CardHeader>
                        <div>
                          <CardBody className="card-body photos filter-cards-view">
                            <UL attrUL={{ className: "simple-list flex-row" }}>
                              {vendorData.other_image.map((item) => (
                                <LI key={item.id}>
                                  <div className="latest-post">
                                    <Image
                                      attrImage={{
                                        className: "img-fluid",
                                        alt: "",
                                        src: item.path,
                                      }}
                                    />
                                  </div>
                                </LI>
                              ))}
                            </UL>
                          </CardBody>
                        </div>
                      </Card>
                    </Col>
                    <Col sm="3">
                      <Card>
                        <CardBody>
                          <div style={{ fontSize: "12px" }}>
                            <H3 className="underline">Business Time</H3>
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Day</th>
                                  <th scope="col">Open</th>
                                  <th scope="col">Close</th>
                                </tr>
                              </thead>
                              <tbody>
                                {daysOfWeek.map((day) => (
                                  <tr key={day}>
                                    <td>
                                      {day.charAt(0).toUpperCase() +
                                        day.slice(1)}
                                    </td>
                                    <td>
                                      {vendorData.business_time[day]
                                        ? vendorData.business_time[day].open
                                        : "-"}
                                    </td>
                                    <td>
                                      {vendorData.business_time[day]
                                        ? vendorData.business_time[day].close
                                        : "-"}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl="3" md="5" className="xl-35 box-col-30">
                <div className="default-according style-1 faq-accordion job-accordion">
                  <Row>
                    <Col xl="12">
                      <AboutMe />
                      <Card
                        data-intro="This is your Your detail"
                        className="step4"
                      >
                        <CardHeader>
                          <H3 attrH3={{ className: "p-0" }}>Vendor </H3>
                        </CardHeader>
                        <div>
                          <CardBody className="post-about">
                            <UL attrUL={{ className: "simple-list" }}>
                              <LI>
                                <div className="icon">
                                  <User />
                                </div>
                                <div>
                                  <H5>
                                    {vendorData.business_address.address
                                      ? vendorData.business_address.address
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Address
                                  </P>
                                </div>
                              </LI>
                              <LI>
                                <div className="icon">
                                  <Mail />
                                </div>
                                <div>
                                  <H5>
                                    {vendorData.business_address.building_name
                                      ? vendorData.business_address
                                          .building_name
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Building name
                                  </P>
                                </div>
                              </LI>{" "}
                              <LI>
                                <div className="icon">
                                  <Phone />
                                </div>
                                <div>
                                  <H5>
                                    {vendorData.business_address.city
                                      ? vendorData.business_address.city
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    City
                                  </P>
                                </div>
                              </LI>{" "}
                              <LI>
                                <div className="icon">
                                  <FaWhatsapp />
                                </div>
                                <div>
                                  <H5>
                                    {" "}
                                    {vendorData.business_address.landmark
                                      ? vendorData.business_address.landmark
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Landmark
                                  </P>
                                </div>
                              </LI>
                              <LI>
                                <div className="icon">
                                  <FaWhatsapp />
                                </div>
                                <div>
                                  <H5>
                                    {" "}
                                    {vendorData.business_address.street_name
                                      ? vendorData.business_address.street_name
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Street name
                                  </P>
                                </div>
                              </LI>
                              <LI>
                                <div className="icon">
                                  <FaWhatsapp />
                                </div>
                                <div>
                                  <H5>
                                    {" "}
                                    {vendorData.business_address.state
                                      ? vendorData.business_address.state
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    State
                                  </P>
                                </div>
                              </LI>
                              <LI>
                                <div className="icon">
                                  <FaWhatsapp />
                                </div>
                                <div>
                                  <H5>
                                    {" "}
                                    {vendorData.business_address.pincode
                                      ? vendorData.business_address.pincode
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Pincode
                                  </P>
                                </div>
                              </LI>
                            </UL>
                          </CardBody>
                        </div>
                      </Card>
                    </Col>
                    <Col xl="12" md="12" sm="6">
                    <FollowersProfile />
                    <VendorServicesWrapper id={vendorId} />
                    </Col>
                    <Col xl="9" md="7" className="xl-65 box-col-70">
                      <Row>
                        <Col
                          sm="12"
                          data-intro="This is the your first Post"
                          className="step5"
                        >
                          <Card>
                            <div className="profile-post">
                              <PostHeader h6="22 Hours ago" />
                              <div className="post-body">
                                <div className="img-container">
                                  <div
                                    className="my-gallery"
                                    itemScope
                                    data-pswp-uid="1"
                                  >
                                    <Gallery options={option}>
                                      <figure
                                        itemProp="associatedMedia"
                                        style={{ marginBottom: "30px" }}
                                      >
                                        <Item
                                          original={require("../../../assets/images/user-profile/post1.jpg")}
                                          width="1600"
                                          height="950"
                                        >
                                          {({ ref, open }) => (
                                            <Link onClick={open}>
                                              <img
                                                ref={ref}
                                                className="img-fluid rounded-3 w-100"
                                                src={require("../../../assets/images/user-profile/post1.jpg")}
                                                itemProp="thumbnail"
                                                alt="gallery"
                                              />
                                            </Link>
                                          )}
                                        </Item>
                                      </figure>
                                    </Gallery>
                                  </div>
                                </div>
                                <PostReact h6={"+5 people react this post"} />
                                <SocialIcon />
                              </div>
                            </div>
                          </Card>
                          <VendorServicesWrapper id={vendorId} />
                        </Col>
                        <PostSecond />
                        <PostThird />
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div> */}
          {/* <div className="row g-2">
            <Card className="col-8 g-2 mx-2 me-5">
              <CardBody>
                <div className="row g-2 ">
                  <div className="col-8">
                    <H3 className="underline">Business Info</H3>
                    <div className="mt-3">
                      <p className="fs-6 mb-1">
                        <span>Business name :</span>
                        <span> {vendorData.business_name}</span>
                      </p>
                      <p className="fs-6 mb-1">
                        <span>Legal Business Name :</span>
                        <span> {vendorData.legal_business_name}</span>
                      </p>
                      <p className="fs-6 mb-1">
                        <span>GST :</span>
                        <span> {vendorData.gst}</span>
                      </p>
                      <p className="fs-6 mb-1">
                        <span>Address :</span>
                        <span> {vendorData.business_address.address}</span>
                      </p>
                      <p className="fs-6 mb-1">
                        <span>Building Name :</span>
                        <span>
                          {" "}
                          {vendorData.business_address.building_name}
                        </span>
                      </p>
                      <p className="fs-6 mb-1">
                        <span>City :</span>
                        <span> {vendorData.business_address.city}</span>
                      </p>
                      <p className="fs-6 mb-1">
                        <span>Landmark :</span>
                        <span> {vendorData.business_address.landmark}</span>
                      </p>
                      <p className="fs-6 mb-1">
                        <span>Street Name :</span>
                        <span> {vendorData.business_address.street_name}</span>
                      </p>
                      <p className="fs-6 mb-1">
                        <span>State :</span>
                        <span> {vendorData.business_address.state}</span>
                      </p>
                      <p className="fs-6 mb-1">
                        <span>Pincode :</span>
                        <span> {vendorData.business_address.pincode}</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-4" style={{ fontSize: "12px" }}>
                    <H3 className="underline">Business Time</H3>
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Day</th>
                          <th scope="col">Open</th>
                          <th scope="col">Close</th>
                        </tr>
                      </thead>
                      <tbody>
                        {daysOfWeek.map((day) => (
                          <tr key={day}>
                            <td>
                              {day.charAt(0).toUpperCase() + day.slice(1)}
                            </td>
                            <td>
                              {vendorData.business_time[day]
                                ? vendorData.business_time[day].open
                                : "-"}
                            </td>
                            <td>
                              {vendorData.business_time[day]
                                ? vendorData.business_time[day].close
                                : "-"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card className="col-3">
              <CardBody>
                <H3>About Info</H3>
                <div className="fs-6 mt-3">
                  <p className="fs-6 mb-2">
                    <span className="me-2">
                      <User size={16} />
                    </span>
                    <span> {vendorData.contact_info.person_name}</span>
                  </p>
                  <p className="fs-6 mb-2">
                    <span className="me-2">
                      <Mail size={16} />
                    </span>
                    <span> {vendorData.contact_info.email}</span>
                  </p>
                  <p className="mb-2 fs-6">
                    <span className="me-2">
                      <Phone size={16} />
                    </span>
                    <span> {vendorData.contact_info.mobile_number}</span>
                  </p>{" "}
                  <p className="mb-2 fs-6">
                    <span className="me-2">
                      <FaWhatsapp size={16} />
                    </span>
                    <span>
                      {" "}
                      {vendorData.contact_info.whatsapp_number
                        ? vendorData.contact_info.whatsapp_number
                        : "-"}
                    </span>
                  </p>{" "}
                </div>
              </CardBody>
            </Card>
          </div> */}
        </div>
      )}

      {/* <VendorServicesWrapper id={vendorId} /> */}
    </div>
  );
};

export default VendorDetails;
