import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { restRequest } from "../../../../api-utils/apiQueries";
import { Edit3, Eye, Search, Trash2 } from "react-feather";
import AddNewService from "./AddNewService";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Media,
  Row,
  Tooltip,
} from "reactstrap";
import { toast } from "react-toastify";

const ServicesWrapper = ({ mainTitle }) => {
  const [services, setServices] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isUpdated, setIsUpdated] = useState(true);
  const [selectedService, setSelectedService] = useState(null);
  const [totalSubCategories, setTotalSubCategories] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (index) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const toggle = () => {
    setShowModal(!showModal);
    setSelectedService(null);
  };
  const [searchValue, setSearchValue] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState(0);

  const updatedCategory = (row) => {
    setShowModal(true);
    setSelectedService(row);
  };

  const handleSort = (column, sortDirection) => {
    console.log("Column Data ->", column.id);
    setSortDirection(sortDirection === "desc" ? 0 : 1);
    setSortColumn(column.id);
    setIsUpdated(true);
  };

  const handleSearchValue = (e) => {
    let data = e.target.value;
    setSearchValue(data);
  };

  const handleSwitch = async (index, type) => {
    const newCategories = [...services];
    newCategories[index][type] = !newCategories[index][type];
    if (type === "trending_services") {
      let data = {
        category_id: newCategories[index]["_id"],
        type: "subCategory",
        status: newCategories[index]["trending_services"],
      };
      console.log("api function called");
      let response = await restRequest.changeTrendingStatus(data);
      console.log("Change Status Response ->", response.data);
      if (response.data) {
        toast.success(response.data.message);
      }
      // setIsUpdated(true);
    }
    if (type === "show_in_header") {
      let data = {
        category_id: newCategories[index]["_id"],
        type: "subCategory",
        status: newCategories[index]["show_in_header"],
      };
      console.log("api function called");
      let response = await restRequest.changeShowHeaderStatus(data);
      console.log("Change Status Response ->", response.data);
      // setIsUpdated(true);
      console.log("New Data ->", data);
      if (response.data) {
        toast.success(response.data.message);
      }
    }

    if (type === "featured_services") {
      let data = {
        category_id: newCategories[index]["_id"],
        type: "subCategory",
        status: newCategories[index]["featured_services"],
      };
      console.log("api function called");
      let response = await restRequest.changeFeatureStatus(data);
      console.log("Change Status Response ->", response.data);
      // setIsUpdated(true);
      console.log("New Data ->", data);
      if (response.data) {
        toast.success(response.data.message);
      }
    }
    setServices(newCategories);
  };

  const tableColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      id: "name",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row.name}</span>
      ),
      sortable: true,
    },
    {
      name: "Display Orders",
      selector: (row) => row.display_order,
      id: "display_order",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row.display_order}</span>
      ),
      sortable: true,
      grow: 0.5,
    },
    // {
    //   name: "Slug",
    //   selector: (row) => row.slug,
    //   id: "slug",
    //   cell: (row) => (
    //     <span className="text-secondary fw-medium">{row.slug}</span>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Status",
      selector: (row) => row.is_active,
      id: "is_active",
      cell: (row) => (
        <span
          className={`p-1 px-2 rounded-3 ${
            row.is_active ? "bg-success" : "bg-danger"
          }`}
          style={{ color: "" }}
        >
          {row.is_active ? "active" : "inactive"}
        </span>
      ),
      sortable: true,
      grow: 0.5,
    },
    {
      name: "Header Menu",
      selector: (row) => row.show_in_header,
      // id: "is_active",
      // sortable: true,
      cell: (row, index) => (
        <Media className="d-flex mt-2">
          <Media
            body
            className={`flex-grow-1 text-end icon-state switch-outline`}
          >
            <Label className="switch">
              <Input
                type="checkbox"
                checked={row.show_in_header}
                onChange={() => handleSwitch(index, "show_in_header")}
              />
              <span className="switch-state"></span>
            </Label>
          </Media>
          {/* <Label className="col-form-label m-r-10">Header Menu </Label> */}
        </Media>
      ),
      grow: 0.5,
    },
    {
      name: "Trending",
      selector: (row) => row.trending_services,
      // id: "is_active",
      // sortable: true,
      cell: (row, index) => (
        <Media className="d-flex mt-2">
          <Media
            body
            className={`flex-grow-1 text-end icon-state switch-outline`}
          >
            <Label className="switch">
              <Input
                type="checkbox"
                checked={row.trending_services}
                onChange={() => handleSwitch(index, "trending_services")}
              />
              <span className="switch-state"></span>
            </Label>
          </Media>
          {/* <Label className="col-form-label m-r-10">Trending </Label> */}
        </Media>
      ),
      grow: 0.5,
    },
    {
      name: "Featured",
      selector: (row) => row.featured_services,
      // id: "is_active",
      // sortable: true,
      cell: (row, index) => (
        <Media className="d-flex mt-2">
          <Media
            body
            className={`flex-grow-1 text-end icon-state switch-outline switch-md`}
          >
            <Label className="switch">
              <Input
                type="checkbox"
                checked={row.featured_services}
                onChange={() => handleSwitch(index, "featured_services")}
              />
              <span className="switch-state "></span>
            </Label>
          </Media>
          {/* <Label className="col-form-label m-r-10">Featured </Label> */}
        </Media>
      ),
      grow: 0.5,
    },
    {
      name: "Parent Category",
      selector: (row) => row.categories_id,
      id: "slug",
      cell: (row, index) => (
        <span key={index}>
          <Button
            className="me-1 p-1 px-2"
            color="primary"
            id={`Tooltip-${index}`}
            size="sm"
          >
            <Eye />
          </Button>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen[index] || false}
            target={`Tooltip-${index}`}
            toggle={() => toggleTooltip(index)}
            className=""
          >
            {row.categories_id.map((item, idx) => (
              <p
                className="border border-2 px-2 py-1 rounded-pill mb-1"
                key={idx}
              >
                {item.name}
              </p>
            ))}
          </Tooltip>
        </span>
      ),
      grow: 0.6,
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <button
          onClick={() => updatedCategory(d)}
          className="border border-none rounded text-success me-2"
        >
          <Edit3 />
        </button>,
        // <button
        //   onClick={() => {}}
        //   className="border border-none rounded text-danger"
        // >
        //   <Trash2 />
        // </button>,
      ],
    },
  ];

  const fetchServices = async () => {
    let response = await restRequest.getAllServices(
      page,
      sortDirection,
      sortColumn,
      searchValue
    );
    let data = response.data.data;
    console.log("Response ->", data);
    setServices(data.services);
    setTotalPage(data.pager.totalPages);
    setTotalSubCategories(data.pager.totalRecords);
    setIsUpdated(false);
  };
  // console.log("IS UPDATED ->", isUpdated);

  useEffect(() => {
    if (isUpdated) fetchServices();
  }, [isUpdated, page]);

  const handlePageChange = (page) => {
    setPage(page);
    setIsUpdated(true);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: false,
  };
  // console.log("Show modal ->", showModal);

  return (
    <Fragment>
      <Row>
        <div className="d-flex">
          <Breadcrumbs mainTitle={`Sub Categories (${totalSubCategories})`} />
          <div className="d-flex gap-4">
            <div
              className={`input-group left-header horizontal-wrapper`}
              style={{ width: "300px" }}
            >
              <Input
                type="text"
                placeholder="Search.."
                value={searchValue}
                onChange={handleSearchValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchValue(e.target.value);
                    setIsUpdated(true);
                  }
                }}
              />
              <span className="input-group-text mobile-search">
                <Search
                  onClick={() => {
                    setIsUpdated(true);
                  }}
                />
              </span>
            </div>
            <Btn
              attrBtn={{
                color: "primary",
                className: "d-flex w-[120px]",
                onClick: () => setShowModal(true),
              }}
            >
              <p className="mb-0 fs-7" style={{ width: "120px" }}>
                Add Sub Category
              </p>
            </Btn>
          </div>
        </div>
        <Col>
          <Card>
            <CardBody className="data-tables">
              <DataTable
                data={services}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
                // selectableRows
                paginationServer
                paginationTotalRows={totalSubCategories}
                onChangePage={handlePageChange}
                paginationPerPage={20}
                paginationComponentOptions={paginationComponentOptions}
                onSort={handleSort}
                sortServer
                // onSelectedRowsChange={handleRowSelected}
                // clearSelectedRows={toggleDelet}
              />
            </CardBody>
          </Card>
        </Col>
        {showModal && (
          <AddNewService
            showModal={showModal}
            toggle={toggle}
            selectedService={selectedService}
            setIsUpdated={setIsUpdated}
          />
        )}
      </Row>
    </Fragment>
  );
};

export default ServicesWrapper;
