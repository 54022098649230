import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Breadcrumbs,
  Btn,
  H3,
  H4,
  H5,
  H6,
  Image,
  LI,
  P,
  UL,
} from "../../../AbstractElements";
import { restRequest } from "../../../api-utils/apiQueries";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
} from "reactstrap";
import {
  Book,
  Briefcase,
  Droplet,
  Edit,
  Edit2,
  Edit3,
  Heart,
  Mail,
  MapPin,
  PenTool,
  Phone,
  User,
} from "react-feather";
import { FaPen, FaWhatsapp } from "react-icons/fa";
import { onlyDate } from "../../commun-function/CommunFunction";
import { fileURL } from "../../../api-utils/constantVariables";
import UpdateStatus from "./UpdateStatus";
import UpdateProbability from "./UpdateProbability";
import { NotificationContext } from "../../../NotificationContext";
const user = "../../../assets/user.png";

// export const AboutMeData = [
//   {
//     id: 1,
//     icon: <Briefcase />,
//     designation: "UX desginer at Pixelstrap",
//     place: "banglore - 2023",
//   },
//   {
//     id: 2,
//     icon: <Book />,
//     designation: "studied computer science",
//     place: "at london univercity - 2018",
//   },
//   {
//     id: 3,
//     icon: <Heart />,
//     designation: "relationship status",
//     place: "single",
//   },
//   {
//     id: 4,
//     icon: <MapPin />,
//     designation: "lived in london",
//     place: "last 5 year",
//   },
//   {
//     id: 5,
//     icon: <Droplet />,
//     designation: "blood group",
//     place: "O+ positive",
//   },
// ];

const LeadsDetails = () => {
  const { leadId } = useParams();
  const [leadData, setLeadData] = useState(null);
  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const [isStatus, setIsStatus] = useState(false);
  const [isProbability, setIsProbability] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const { setNotificationCount } = useContext(NotificationContext);

  const fetchNotificationCount = async () => {
    let response = await restRequest.getNotificationCount();
    console.log("response count ->", response.data.data.count);
    let count = response.data.data.count;
    setNotificationCount(count);
  };
  // useEffect(() => {
  //   fetchNotificationCount();
  // }, []);

  const toggleStatus = () => setIsStatus(!isStatus);
  const toggleProbability = () => setIsProbability(!isProbability);

  const fetchLeadDetail = async () => {
    let response = await restRequest.getLeadDetails(leadId);
    console.log("Lead Details Response ->", response?.data.data);
    let data = response?.data.data;
    setLeadData(data);
    setIsUpdated(false);
    fetchNotificationCount();
  };
  useEffect(() => {
    fetchLeadDetail();
  }, [leadId, isUpdated]);
  return (
    <div>
      <Breadcrumbs mainTitle={"Lead Details"} />
      {leadData && (
        <>
          <div className="user-profile">
            <Row>
              <Col sm="4">
                <Card className="profile-header bg-size h-auto">
                  <div className="userpro-box">
                    <div className="img-wrraper">
                      <div className="avatar">
                        <img
                          // attrImage={{
                          //   className: "step1",
                          //   alt: "",
                          //   src: `${
                          //     // leadData?.user?.image
                          //     //   ? leadData.user?.image?.path
                          //     //   :
                          //     require("../../../assets/king.png")
                          //   }`,
                          //   dataintro: "This is Profile image",
                          // }}
                          // src={}
                          src={`${
                            leadData?.user?.image
                              ? leadData.user?.image?.path
                              : require("../../../assets/user.png")
                          }`}
                        />
                      </div>
                      {/* <div className="icon-wrapper">
                            <i
                              className="icofont icofont-pencil-alt-5 step2"
                              data-intro="Change Profile image here"
                            ></i>
                          </div> */}
                    </div>
                    <div className="user-designation">
                      <div className="title">
                        <a target="_blank" href="#javascript">
                          <H4>{leadData.user.person_name}</H4>
                          <H6>{leadData.user.mobile_number}</H6>
                          <H6> {leadData.user?.whatsapp_number}</H6>
                        </a>
                      </div>

                      <div className="follow">
                        <UL
                          attrUL={{
                            className: "follow-list flex-row simple-list",
                          }}
                        >
                          <LI>
                            <div className="follow-num counter">
                              <p className="col d-flex gap-3">
                                <span
                                  className={`border text-center px-2 rounded-3 text-light ${
                                    leadData.status === 1
                                      ? "bg-primary"
                                      : leadData.status === 2
                                      ? "bg-warning"
                                      : leadData.status === 3
                                      ? "bg-success"
                                      : "bg-danger"
                                  }`}
                                >
                                  {leadData.status === 1
                                    ? "New"
                                    : leadData.status === 2
                                    ? "Working"
                                    : leadData.status === 3
                                    ? "Deal Completed"
                                    : "Cancelled"}
                                </span>

                                <span
                                  className="col-1"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setIsStatus(true)}
                                >
                                  <FaPen size={18} className="" />
                                </span>
                              </p>
                            </div>
                            <span>Status</span>
                          </LI>
                          <LI>
                            <div className="follow-num counter">
                              <p className="col d-flex gap-3">
                                <span
                                  className={`text-center px-2 rounded-3 text-light ${
                                    leadData?.probability === 1
                                      ? "bg-primary"
                                      : leadData?.probability === 2
                                      ? "bg-success"
                                      : "bg-danger"
                                  }`}
                                >
                                  {leadData?.probability === 1
                                    ? "Hot"
                                    : leadData?.probability === 2
                                    ? "Worm"
                                    : leadData?.probability === 3
                                    ? "Cold"
                                    : "-"}
                                </span>
                                <span
                                  className="col-1 mt-1"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setIsProbability(true)}
                                >
                                  <FaPen size={18} />
                                </span>
                              </p>
                            </div>
                            <span>Probability</span>
                          </LI>
                          {/* <LI>
                                <div className="follow-num counter">0</div>
                                <span>Revues</span>
                              </LI> */}
                        </UL>
                        {isStatus && (
                          <UpdateStatus
                            isStatus={isStatus}
                            toggle={toggleStatus}
                            id={leadId}
                            statusCode={leadData.status}
                            setIsUpdated={setIsUpdated}
                          />
                        )}
                        {isProbability && (
                          <UpdateProbability
                            isProbability={isProbability}
                            toggle={toggleProbability}
                            id={leadId}
                            probabilityCode={leadData?.probability}
                            setIsUpdated={setIsUpdated}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col sm="8">
                <Card
                  data-intro="This is your Your detail h-[400px]"
                  className="step4"
                >
                  <CardHeader>
                    <H3 attrH3={{ className: "p-0" }}>Lead Info</H3>
                  </CardHeader>
                  <div style={{ paddingBottom: "30px" }}>
                    <CardBody className="post-about row row-cols-2">
                      <UL attrUL={{ className: "simple-list" }}>
                        <LI>
                          <div className="icon">
                            <User />
                          </div>
                          <div>
                            <H5>{leadData.lead_type}</H5>
                            <P attrPara={{ className: "text-dark" }}>
                              Lead type
                            </P>
                          </div>
                        </LI>
                        <LI>
                          <div className="icon">
                            <Mail />
                          </div>
                          <div>
                            <H5>
                              {leadData.source === "service_list"
                                ? "Listing Page"
                                : leadData.source === "service_detail"
                                ? "Detail Page"
                                : leadData.source === "vendor_detail"
                                ? "Vender Page"
                                : leadData.source === "quick_enquiry"
                                ? "Quick Enquiry"
                                : ""}
                            </H5>
                            <P attrPara={{ className: "text-dark" }}>Source</P>
                          </div>
                        </LI>{" "}
                        <LI>
                          <div className="icon">
                            <Phone />
                          </div>
                          <div>
                            <H5>{onlyDate(leadData.createdAt)}</H5>
                            <P attrPara={{ className: "text-dark" }}>
                              Created At
                            </P>
                          </div>
                        </LI>{" "}
                        <LI>
                          <div className="icon">
                            <FaWhatsapp />
                          </div>
                          <div>
                            <H5>
                              {leadData.user.whatsapp_number
                                ? leadData.user.whatsapp_number
                                : "-"}
                            </H5>
                            <P attrPara={{ className: "text-dark" }}>
                              Whatsapp number
                            </P>
                          </div>
                        </LI>
                      </UL>
                      <div>
                        <H3 className="underline">Filter</H3>
                        <div className="mt-2">
                          <div className="d-flex align-items-center gap-3 mb-3">
                            <p className="mb-0 fs-6 text-secondary fw-semibold">
                              Categories :
                            </p>
                            <p>
                              {leadData?.filter_obj?.categories.length > 0 ? (
                                leadData?.filter_obj?.categories.map(
                                  (item, index) => {
                                    return (
                                      <span
                                        className=" text-center px-2 rounded-3 me-2 bg-primary p-1"
                                        key={index}
                                      >
                                        {item}
                                      </span>
                                    );
                                  }
                                )
                              ) : (
                                <span>-</span>
                              )}
                            </p>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <p className="mb-0 fs-6 text-secondary fw-semibold">
                              Sub Categories :
                            </p>
                            <p>
                              {" "}
                              {leadData?.filter_obj?.sub_categories.length >
                              0 ? (
                                leadData?.filter_obj?.sub_categories.map(
                                  (item, index) => {
                                    return (
                                      <span
                                        className=" text-center px-2 rounded-3 me-2 bg-primary p-1"
                                        key={index}
                                      >
                                        {item}
                                      </span>
                                    );
                                  }
                                )
                              ) : (
                                <span>-</span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </div>
                </Card>
              </Col>
            </Row>
            {leadData?.vendor && (
              <Row>
                <Col sm="4">
                  <div className=" mx-2 default-according style-1 faq-accordion job-accordion">
                    <Row>
                      <Card
                        data-intro="This is your Your detail"
                        className="step4"
                        style={{ paddingBottom: "40px" }}
                      >
                        <CardHeader>
                          <H3 attrH3={{ className: "p-0" }}>Vendor Info</H3>
                        </CardHeader>
                        <div className="">
                          <CardBody className="post-about d-flex self-start row row-cols-2">
                            <UL attrUL={{ className: "simple-list col" }}>
                              <LI>
                                <div className="icon">
                                  <User />
                                </div>
                                <div>
                                  <H5>{leadData.vendor.business_name}</H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Business name
                                  </P>
                                </div>
                              </LI>
                              <LI>
                                <div className="icon">
                                  <User />
                                </div>
                                <div>
                                  <H5>{leadData.vendor.legal_business_name}</H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Legal business name
                                  </P>
                                </div>
                              </LI>
                              <LI>
                                <div className="icon">
                                  <User />
                                </div>
                                <div>
                                  <H5>{leadData.vendor.gst}</H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    GST
                                  </P>
                                </div>
                              </LI>
                            </UL>
                            <UL attrUL={{ className: "simple-list col" }}>
                              <LI>
                                <div className="icon">
                                  <User />
                                </div>
                                <div>
                                  <H5>
                                    {leadData.vendor.contact_info.person_name}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Person name
                                  </P>
                                </div>
                              </LI>
                              <LI>
                                <div className="icon">
                                  <Mail />
                                </div>
                                <div>
                                  <H5>{leadData.vendor.contact_info.email}</H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Email
                                  </P>
                                </div>
                              </LI>{" "}
                              <LI>
                                <div className="icon">
                                  <Phone />
                                </div>
                                <div>
                                  <H5>
                                    {leadData.vendor.contact_info.mobile_number}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Mobile number
                                  </P>
                                </div>
                              </LI>{" "}
                              <LI>
                                <div className="icon">
                                  <FaWhatsapp />
                                </div>
                                <div>
                                  <H5>
                                    {leadData.vendor.contact_info
                                      .whatsapp_number
                                      ? leadData.vendor.contact_info
                                          .whatsapp_number
                                      : "-"}
                                  </H5>
                                  <P attrPara={{ className: "text-dark" }}>
                                    Whatsapp number
                                  </P>
                                </div>
                              </LI>
                            </UL>
                          </CardBody>
                        </div>
                      </Card>
                      {/* <PostSecond /> */}
                      {/* <PostThird /> */}
                    </Row>
                  </div>
                </Col>
                <Col>
                  <Card className="border [h-50vh]" style={{ height: "350px" }}>
                    <H3 attrH3={{ className: "px-4 pt-4" }}>Service </H3>
                    <CardBody className=" w-full py-1">
                      <div>
                        <div className="d-flex align-items-center">
                          <p className="fw-semibold fs-6 text-secondary mb-1">
                            Service Name:
                          </p>
                          <p className="text-secondary fs-6 ms-2">
                            {leadData.service?.name}
                          </p>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="fw-semibold fs-6 text-secondary mb-1">
                            Amount:
                          </p>
                          <p className="text-secondary fs-6 ms-2">
                            {leadData.service?.amount}
                          </p>
                        </div>{" "}
                        <div className="d-flex align-items-center">
                          <p className="fw-semibold fs-6 text-secondary mb-1">
                            Final Amount:
                          </p>
                          <p className="text-secondary fs-6 ms-2">
                            {leadData.service?.final_amount}
                          </p>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="fw-semibold fs-6 text-secondary mb-0">
                            Description:
                          </p>
                          <p
                            // style={{ fontSize: "20px" }}
                            className="fs-6 fw-normal ms-2"
                            dangerouslySetInnerHTML={{
                              __html: leadData.service?.description,
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="mt-4">
                        <H3>Other Images</H3>
                        <div className="d-flex flex-wrap">
                          {leadData.service?.other_image.map((item, index) => {
                            return (
                              <div className="m-2 " key={index}>
                                <img
                                  src={`${item.path}`}
                                  width={70}
                                  height={70}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </div>
          {/* =============================================================================================================================== */}
          {/* <div className="row g-2">
            <div className={`${leadData.service ? "col-4" : "row "}`}>
              <Card
                className={`${leadData.service ? "g-2 mx-2" : "col-4 me-4"}`}
              >
                <CardBody>
                  <H3 className="underline">Lead</H3>
                  <div className="my-3 position-relative">
                    <p className="row fs-6 mb-1">
                      <span className="col-5">Lead Type :</span>
                      <span className="col">{leadData.lead_type}</span>
                    </p>
                    <p className="row fs-6 mb-1">
                      <span className="col-5">Source :</span>
                      <span className="col">{leadData.source}</span>
                    </p>
                    <div className="row fs-6 mb-1">
                      <span className="col-5">status:</span>
                      <p className="col d-flex gap-3">
                        <span
                          className={`border text-center px-2 rounded-3 ${
                            leadData.status === 1
                              ? "bg-primary"
                              : leadData.status === 2
                              ? "bg-warning"
                              : leadData.status === 3
                              ? "bg-success"
                              : "bg-danger"
                          }`}
                        >
                          {leadData.status === 1
                            ? "New"
                            : leadData.status === 2
                            ? "Working"
                            : leadData.status === 3
                            ? "Deal Completed"
                            : "Cancelled"}
                        </span>

                        <span
                          className="col-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsStatus(true)}
                        >
                          <FaPen size={18} className="" />
                        </span>
                      </p>
                    </div>
                    <div className="row fs-6 mb-1">
                      <span className="col-5">Probability:</span>
                      <p className="col d-flex gap-3">
                        <span
                          className={`text-center px-2 rounded-3 ${
                            leadData?.probability === 1
                              ? "bg-primary"
                              : leadData?.probability === 2
                              ? "bg-success"
                              : "bg-danger"
                          }`}
                        >
                          {leadData?.probability === 1
                            ? "Hot"
                            : leadData?.probability === 2
                            ? "Worm"
                            : leadData?.probability === 3
                            ? "Cold"
                            : "-"}
                        </span>
                        <span
                          className="col-1 mt-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsProbability(true)}
                        >
                          <FaPen size={18} />
                        </span>
                      </p>
                    </div>
                    <p className="row fs-6 mb-1">
                      <span className="col-5">Created At:</span>
                      <span className="col">
                        {onlyDate(leadData.createdAt)}
                      </span>
                    </p>
                    {isStatus && (
                      <UpdateStatus
                        isStatus={isStatus}
                        toggle={toggleStatus}
                        id={leadId}
                        statusCode={leadData.status}
                        setIsUpdated={setIsUpdated}
                      />
                    )}
                    {isProbability && (
                      <UpdateProbability
                        isProbability={isProbability}
                        toggle={toggleProbability}
                        id={leadId}
                        probabilityCode={leadData?.probability}
                        setIsUpdated={setIsUpdated}
                      />
                    )}
                  </div>
                  <H3 className="underline">Filter</H3>
                  <div className="mt-2">
                    <div className="d-flex align-items-center gap-3 mb-3">
                      <p className="mb-0 fs-6 text-secondary fw-semibold">
                        Categories :
                      </p>
                      <p>
                        {leadData?.filter_obj?.categories.length > 0 ? (
                          leadData?.filter_obj?.categories.map(
                            (item, index) => {
                              return (
                                <span
                                  className=" text-center px-2 rounded-3 me-2 bg-primary p-1"
                                  key={index}
                                >
                                  {item}
                                </span>
                              );
                            }
                          )
                        ) : (
                          <span>-</span>
                        )}
                      </p>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <p className="mb-0 fs-6 text-secondary fw-semibold">
                        Sub Categories :
                      </p>
                      <p>
                        {" "}
                        {leadData?.filter_obj?.sub_categories.length > 0 ? (
                          leadData?.filter_obj?.sub_categories.map(
                            (item, index) => {
                              return (
                                <span
                                  className=" text-center px-2 rounded-3 me-2 bg-primary p-1"
                                  key={index}
                                >
                                  {item}
                                </span>
                              );
                            }
                          )
                        ) : (
                          <span>-</span>
                        )}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div
                className={`user-profile ${
                  leadData.service ? "g-2 mx-2" : "col-4 "
                }`}
              >
                <div className="default-according style-1 faq-accordion job-accordion">
                  <Card data-intro="This is your Your detail" className="step4">
                    <CardHeader>
                      <H3 attrH3={{ className: "p-0" }}>Customer</H3>
                    </CardHeader>
                    <div>
                      <CardBody className="post-about">
                        <UL attrUL={{ className: "simple-list" }}>
                          <LI>
                            <div className="icon">
                              <User />
                            </div>
                            <div>
                              <H5>{leadData.user.person_name}</H5>
                            </div>
                          </LI>
                          <LI>
                            <div className="icon">
                              <Mail />
                            </div>
                            <div>
                              <H5>
                                {leadData.user?.email
                                  ? leadData.user?.email
                                  : "-"}
                              </H5>
                            </div>
                          </LI>{" "}
                          <LI>
                            <div className="icon">
                              <Phone />
                            </div>
                            <div>
                              <H5>{leadData.user.mobile_number}</H5>
                            </div>
                          </LI>{" "}
                          <LI>
                            <div className="icon">
                              <FaWhatsapp />
                            </div>
                            <div>
                              <H5>
                                {" "}
                                {leadData.user.whatsapp_number
                                  ? leadData.user.whatsapp_number
                                  : "-"}
                              </H5>
                            </div>
                          </LI>
                        </UL>
                      </CardBody>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
            {leadData?.service && (
              <div className="col-8">
                <Card className="g-2 mx-2">
                  <CardBody>
                    <H3 className="underline">Service</H3>
                    <div className="row mt-4">
                      <div className="col-6">
                        <div className="d-flex">
                          <img
                            src={`${leadData.service.feature_image.path}`}
                            width={100}
                            height={100}
                            className="rounded-circle"
                          />
                          <div className="ms-3">
                            <p className="fw-bold fs-6">
                              {leadData.service.name}
                            </p>
                            <p className=" border border-2 border-2-dashed fs-6 p-2 text-center">
                              {leadData.service.amount}
                            </p>
                          </div>
                        </div>
                        <p className="mt-5 fw-semibold fs-6 text-secondary">
                          Description :{" "}
                          <span
                            style={{ fontSize: "20px" }}
                            className="fs-4 fw-normal"
                            dangerouslySetInnerHTML={{
                              __html: leadData.service.description,
                            }}
                          ></span>
                        </p>
                      </div>
                      <div className="col-6">
                        <H4>Other Images</H4>
                        <div className="d-flex flex-wrap">
                          {leadData.service.other_image.map((item, index) => {
                            return (
                              <div className="m-2 " key={index}>
                                <img
                                  src={`${item.path}`}
                                  width={100}
                                  height={100}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            )}
          </div> */}
          {/* {leadData?.vendor && (
            <div>
              <div className="ps-3">
                <H3>Vendor </H3>
              </div>
              <div className="row g-2">
                <div className="col-8">
                  <Card className="mx-2 g-2">
                    <CardBody>
                      <div className="row g-2 ">
                        <div className="col-8">
                          <H3 className="underline">Business Info</H3>
                          <div className="mt-3">
                            <p className="fs-6 mb-1">
                              <span>Business name :</span>
                              <span> {leadData.vendor.business_name}</span>
                            </p>
                            <p className="fs-6 mb-1">
                              <span>Legal Business Name :</span>
                              <span>
                                {" "}
                                {leadData.vendor.legal_business_name}
                              </span>
                            </p>
                            <p className="fs-6 mb-1">
                              <span>GST :</span>
                              <span> {leadData.vendor.gst}</span>
                            </p>
                            <p className="fs-6 mb-1">
                              <span>Address :</span>
                              <span>
                                {" "}
                                {leadData.vendor.business_address.address}
                              </span>
                            </p>
                            <p className="fs-6 mb-1">
                              <span>Building Name :</span>
                              <span>
                                {" "}
                                {leadData.vendor.business_address.building_name}
                              </span>
                            </p>
                            <p className="fs-6 mb-1">
                              <span>City :</span>
                              <span>
                                {" "}
                                {leadData.vendor.business_address.city}
                              </span>
                            </p>
                            <p className="fs-6 mb-1">
                              <span>Landmark :</span>
                              <span>
                                {" "}
                                {leadData.vendor.business_address.landmark}
                              </span>
                            </p>
                            <p className="fs-6 mb-1">
                              <span>Street Name :</span>
                              <span>
                                {" "}
                                {leadData.vendor.business_address.street_name}
                              </span>
                            </p>
                            <p className="fs-6 mb-1">
                              <span>State :</span>
                              <span>
                                {" "}
                                {leadData.vendor.business_address.state}
                              </span>
                            </p>
                            <p className="fs-6 mb-1">
                              <span>Pincode :</span>
                              <span>
                                {" "}
                                {leadData.vendor.business_address.pincode}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="col-4" style={{ fontSize: "12px" }}>
                          <H3 className="underline">Business Time</H3>
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Day</th>
                                <th scope="col">Open</th>
                                <th scope="col">Close</th>
                              </tr>
                            </thead>
                            <tbody>
                              {daysOfWeek.map((day) => (
                                <tr key={day}>
                                  <td>
                                    {day.charAt(0).toUpperCase() + day.slice(1)}
                                  </td>
                                  <td>
                                    {leadData.vendor.business_time[day]
                                      ? leadData.vendor.business_time[day].open
                                      : "-"}
                                  </td>
                                  <td>
                                    {leadData.vendor.business_time[day]
                                      ? leadData.vendor.business_time[day].close
                                      : "-"}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className={`user-profile col-4 `}>
                  <div className="default-according style-1 faq-accordion job-accordion">
                    <Card
                      data-intro="This is your Your detail"
                      className="step4"
                    >
                      <CardHeader>
                        <H3 attrH3={{ className: "p-0" }}>About Info</H3>
                      </CardHeader>
                      <div>
                        <CardBody className="post-about">
                          <UL attrUL={{ className: "simple-list" }}>
                            <LI>
                              <div className="icon">
                                <User />
                              </div>
                              <div>
                                <H5>
                                  {leadData.vendor.contact_info.person_name}
                                </H5>
                              </div>
                            </LI>
                            <LI>
                              <div className="icon">
                                <Mail />
                              </div>
                              <div>
                                <H5>{leadData.vendor.contact_info.email}</H5>
                              </div>
                            </LI>{" "}
                            <LI>
                              <div className="icon">
                                <Phone />
                              </div>
                              <div>
                                <H5>
                                  {leadData.vendor.contact_info.mobile_number}
                                </H5>
                              </div>
                            </LI>{" "}
                            <LI>
                              <div className="icon">
                                <FaWhatsapp />
                              </div>
                              <div>
                                <H5>
                                  {leadData.vendor.contact_info.whatsapp_number
                                    ? leadData.vendor.contact_info
                                        .whatsapp_number
                                    : "-"}
                                </H5>
                              </div>
                            </LI>
                          </UL>
                        </CardBody>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </>
      )}
    </div>
  );
};

export default LeadsDetails;
