import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router";
import { Button, Card, CardBody, Col, Input, Row, Tooltip } from "reactstrap";
import { restRequest } from "../../../../api-utils/apiQueries";
import { formatDate } from "../../../commun-function/CommunFunction";
import { Eye } from "react-feather";
import { Breadcrumbs } from "../../../../AbstractElements";
import { MdOutlineSpeakerNotes } from "react-icons/md";

const VendorLeads = ({ id, location }) => {
  const { vendorId } = useParams();
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState();
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [isUpdated, setIsUpdated] = useState(true);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState(0);
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState({});
  const limit = 5;

  const handlePageChange = (page) => {
    console.log("Page ->", page);

    setPage(page);
    setIsUpdated(true);
  };

  const toggleTooltip = (index) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: false,
  };

  const handleSort = (column, sortDirection) => {
    console.log("Column Data ->", column.id);
    setSortDirection(sortDirection === "desc" ? 0 : 1);
    setSortColumn(column.id);
    setIsUpdated(true);
  };

  const tableColumns = [
    {
      name: "Customer Name",
      selector: (row) => row?.user.person_name,
      id: "user.person_name",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.user.person_name}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Contact No",
      selector: (row) => row?.user.mobile_number,
      id: "user.mobile_number",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.user.mobile_number}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      id: "user.mobile_number",
      cell: (row) => (
        <span
          className={`text-white fw-medium p-1 px-2 rounded-3 ${
            row?.status === 1
              ? "bg-primary"
              : row?.status === 2
              ? "bg-warning"
              : row?.status === 3
              ? "bg-success"
              : row?.status === 4
              ? "bg-danger"
              : "bg-primary"
          }`}
        >
          {row?.status === 1
            ? "New"
            : row?.status === 2
            ? "Working"
            : row?.status === 3
            ? "Deal Completed"
            : row?.status === 3
            ? "Cancelled"
            : "-"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Service Name",
      selector: (row) => row?.service,
      id: "service",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.service}</span>
      ),
      sortable: true,
    },

    {
      name: "Added Date ",
      selector: (row) => row?.serviceCount,
      id: "createdAt",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {formatDate(row?.createdAt)}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row?.note,
      id: "createdAt",
      cell: (row, index) => (
        <span key={index} className="my-1">
          <Button
            className=" p-1 "
            color="primary"
            id={`Tooltip-${index}`}
            size="sm"
          >
            <MdOutlineSpeakerNotes size={20} />
          </Button>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen[index] || false}
            target={`Tooltip-${index}`}
            toggle={() => toggleTooltip(index)}
            className=""
          >
            <p className="border border-2 px-2 py-1 rounded-pill mb-1">
              {row?.note ? row?.note : "No Not"}
            </p>
          </Tooltip>
        </span>
      ),
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <button
          onClick={() => showLeadsDetails(d)}
          className="border border-none rounded text-success me-2"
        >
          <Eye />
        </button>,
      ],
    },
  ];

  const fetchVendorLeads = async () => {
    let response = await restRequest.getVendorLeads(id, page, limit);
    console.log("Response Vendor Leads ->", response.data.data);
    let data = response.data.data;
    console.log("Response vendor leads ->", data);
    setLeads(data.leads);
    setTotalPage(data.pager.totalPages);
    setTotalLeads(data.pager.totalRecords);
    setIsUpdated(false);
  };

  useEffect(() => {
    if (isUpdated) fetchVendorLeads();
  }, [isUpdated, page]);

  const showLeadsDetails = (row) => {
    console.log("Row ->", row);
    navigate(`/leads/${row._id}`);
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Breadcrumbs mainTitle={`Vendors leads `} />
            <CardBody className="data-tables">
              <DataTable
                data={leads}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
                // selectableRows
                paginationServer
                paginationTotalRows={totalLeads}
                onChangePage={handlePageChange}
                paginationPerPage={limit}
                paginationComponentOptions={paginationComponentOptions}
                onSort={handleSort}
                sortServer
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default VendorLeads;
