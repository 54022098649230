import React, { Fragment, useEffect, useState } from "react";
import Leftbar from "./LeftBar";
import Rightbar from "./RightBar";
import Searchbar from "./Search";
import { Row } from "reactstrap";
import { messaging, onMessage } from "../../firebase/firebaseConfig";

const Header = () => {
  return (
    <Fragment>
      <Row className="header-wrapper m-0" style={{ paddingBottom: "15px" }}>
        <Leftbar />
        <Searchbar />
        <Rightbar />
      </Row>
    </Fragment>
  );
};
export default Header;
