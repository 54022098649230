import React, { Fragment, useEffect, useState } from "react";
import { DollarSign, FileText, ShoppingBag, Users } from "react-feather";
import { Card, CardBody, Col, Row } from "reactstrap";
import { H3, P } from "../../../AbstractElements";
import Chart from "react-apexcharts";
import {
  IncomeChart,
  OrderChart,
  SalesChart,
  VisitorChart,
} from "../../../Data/Dashboard/Ecommerce/Chart";
import { restRequest } from "../../../api-utils/apiQueries";
import { HiMiniBuildingOffice } from "react-icons/hi2";
import { PiChartLineUpBold } from "react-icons/pi";

const SaleChart = () => {
  const [totalVendor, setTotalVendor] = useState(null);
  const [totalCustomer, setTotalCustomer] = useState(null);
  const [todayLeads, setTodayLeads] = useState(null);
  const [newLeads, setNewLeads] = useState(null);
  const [workingLeads, setWorkingLeads] = useState(null);
  const [completedLeads, setCompletedLeads] = useState(null);
  const [cancelledLeads, setCancelledLeads] = useState(null);
  const [customerActive, setCustomerActive] = useState(null);
  const [vendorActive, setVendorActive] = useState(null);

  const fetchVendorCustomerCount = async () => {
    let response = await restRequest.getDashboardVendorCount();
    console.log("Response Count ->", response.data.data);
    setTotalVendor(response.data.data.totalVendor);
    setTotalCustomer(response.data.data.totalCustomers);
    setCustomerActive(response.data.data.totalCustomerActive);
    setVendorActive(response.data.data.totalVendorActive);
  };

  const fetchLeadsCount = async () => {
    let response = await restRequest.getDashboardLeadsCount();
    // console.log("Response leads count ->", response.data.data);
    setTodayLeads(response.data.data.todayLeads);
    setNewLeads(response.data.data.totalNewLeads);
    setWorkingLeads(response.data.data.totalWorkingLeads);
    setCompletedLeads(response.data.data.totalDealCompletedLeads);
    setCancelledLeads(response.data.data.totalCancelLeads);
  };

  useEffect(() => {
    fetchVendorCustomerCount();
    fetchLeadsCount();
  }, []);

  const data = [
    {
      id: 1,
      name: "Total Vendor",
      icon: <HiMiniBuildingOffice />,
      value: totalVendor,
      chart: SalesChart,
    },
    {
      id: 2,
      name: "Total Customer",
      icon: <Users />,
      value: totalCustomer,
      chart: IncomeChart,
    },
    {
      id: 3,
      name: "Today Leads",
      icon: <PiChartLineUpBold />,
      value: todayLeads,
      chart: OrderChart,
    },
    {
      id: 4,
      name: "Today Visitor",
      icon: <Users />,
      value: "-",
      chart: VisitorChart,
    },
    {
      id: 5,
      name: "New Leads",
      icon: <PiChartLineUpBold />,
      value: newLeads,
      chart: VisitorChart,
    },
    {
      id: 6,
      name: "Working Leads",
      icon: <PiChartLineUpBold />,
      value: workingLeads,
      chart: SalesChart,
    },
    {
      id: 7,
      name: "Completed Leads",
      icon: <PiChartLineUpBold />,
      value: completedLeads,
      chart: VisitorChart,
    },
    {
      id: 8,
      name: "Cancelled Leads",
      icon: <PiChartLineUpBold />,
      value: cancelledLeads,
      chart: SalesChart,
    },
  ];

  const activeData = [
    {
      id: 1,
      name: "Active Vendor",
      icon: <Users />,
      value: vendorActive,
      chart: SalesChart,
    },
    {
      id: 2,
      name: "Active Customer",
      icon: <Users />,
      value: customerActive,
      chart: VisitorChart,
    },
  ];
  return (
    <Fragment>
      {data.map((item, i) => {
        return (
          <Col lg="3" sm="6" key={i}>
            <Card className="sale-chart">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <div className="sale-detail">
                      <div className="icon">{item.icon}</div>
                      <div className="sale-content">
                        <H3>{item.name}</H3>
                        <P>{item.value}</P>
                      </div>
                    </div>
                  </div>
                  <div
                    className="small-chart-view sales-chart"
                    id="sales-chart"
                  >
                    <Chart
                      options={item.chart.options}
                      series={item.chart.series}
                      type="bar"
                      height={100}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        );
      })}
      <Row>
        {activeData.map((item, i) => {
          return (
            <Col lg="3" sm="6" key={i}>
              <Card className="sale-chart">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <div className="sale-detail">
                        <div className="icon">{item.icon}</div>
                        <div className="sale-content">
                          <H3>{item.name}</H3>
                          <P>{item.value}</P>
                        </div>
                      </div>
                    </div>
                    <div
                      className="small-chart-view sales-chart"
                      id="sales-chart"
                    >
                      <Chart
                        options={item.chart.options}
                        series={item.chart.series}
                        type="bar"
                        height={100}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Fragment>
  );
};

export default SaleChart;
