import React, { Fragment } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import SaleChart from "./SaleChart";
import { Container, Row } from "reactstrap";
import RecentOrder from "./RecentOrder";
import TopProducts from "./TopProducts";
import CountrySales from "./CountrySales";
import BestSellers from "./BestSellers";
import ItemsSlider from "./ItemsSlider";
import WeekendView from "./WeekendView";

const DashboardWrapper = () => {
  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Dashboard"
        // title="Ecommerce"
        // parent="Dashboard"
      />
      <Container fluid={true} className="ecommerce-page">
        <Row>
          <SaleChart />
          <RecentOrder />
          <TopProducts />
          <CountrySales />
          <BestSellers />
          <ItemsSlider />
          <WeekendView />
        </Row>
      </Container>
    </Fragment>
  );
};

export default DashboardWrapper;
