import React, { Fragment, useEffect, useState } from "react";
import { restRequest } from "../../../api-utils/apiQueries";
import { useNavigate } from "react-router";
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import { Card, CardBody, Col, Input, Label, Media, Row } from "reactstrap";
import {
  Edit3,
  Eye,
  Search,
  Trash2,
  Lock,
  Key,
  ArrowDown,
} from "react-feather";
import DataTable from "react-data-table-component";
import { formatDate, onlyDate } from "../../commun-function/CommunFunction";
import AddNewUser from "./AddNewUser";
import { toast } from "react-toastify";

const UsersWrapper = () => {
  const [users, setUsers] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isUpdated, setIsUpdated] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  const toggle = () => {
    setShowModal(!showModal);
    setSelectedUser(null);
  };

  const editUserData = (data) => {
    setSelectedUser(data);
    setShowModal(true);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setIsUpdated(true);
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: false,
  };

  const handleSearchValue = (e) => {
    let data = e.target.value;
    setSearchValue(data);
  };

  const handleSort = (column, sortDirection) => {
    console.log("Column Data ->", column.id);
    setSortDirection(sortDirection === "desc" ? 0 : 1);
    setSortColumn(column.id);
    setIsUpdated(true);
  };

  const handleSwitch = async (index) => {
    if (window.confirm("Do you want to deactivate?")) {
      const newUsers = [...users];
      const user = newUsers[index];
      const updatedUser = {
        ...user,
        is_active: !user.is_active,
      };
      newUsers[index] = updatedUser;

      let data = {
        _id: updatedUser._id,
        is_active: updatedUser.is_active,
      };
      console.log("User Status", data);
      let response = await restRequest.updateUserStatus(data);
      console.log("Change Status Response ->", response.data);
      if (response.data) {
        toast.success(response.data.message);
        setIsUpdated(true);
      }
    }
  };

  const tableColumns = [
    {
      name: "Name",
      selector: (row) => row?.name,
      id: "name",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.name}</span>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      id: "email",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.email}</span>
      ),
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row?.mobile,
      id: "mobile",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.mobile}</span>
      ),
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => row?.is_active,
      id: "is_active",
      cell: (row, index) => (
        <Media className="d-flex mt-2">
          <Media
            body
            className={`flex-grow-1 text-end icon-state switch-outline`}
          >
            <Label className="switch">
              <Input
                type="checkbox"
                checked={row?.is_active}
                onChange={() => handleSwitch(index)}
              />
              <span className="switch-state"></span>
            </Label>
          </Media>
          {/* <Label className="col-form-label m-r-10">Header Menu </Label> */}
        </Media>
        // <span
        //   className={`text-white fw-medium p-1 px-2 rounded-3 ${
        //     row.status === 1
        //       ? "bg-primary"
        //       : row.status === 2
        //       ? "bg-warning"
        //       : row.status === 3
        //       ? "bg-success"
        //       : "bg-danger"
        //   }`}
        // >
        //   {row.status === 1
        //     ? "New"
        //     : row.status === 2
        //     ? "Working"
        //     : row.status === 3
        //     ? "Deal Completed"
        //     : "Cancelled"}
        // </span>
      ),
      sortable: true,
    },
    {
      name: `Profile`,
      selector: (row) => row?.profile.name,
      id: "profile.name",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.profile.name}</span>
      ),
      sortable: true,
    },

    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <button
          onClick={() => editUserData(d)}
          className="border border-none rounded text-success me-2"
        >
          <Edit3 />
        </button>,
        <button
          onClick={() => editUserData(d)}
          className="border border-none rounded text-danger me-2"
        >
          <Lock />
        </button>,
        <button
          onClick={() => editUserData(d)}
          className="border border-none rounded text-primary me-2"
        >
          <Key />
        </button>,
      ],
    },
  ];

  console.log("Column name ->", sortColumn);
  const fetchVendors = async () => {
    let response = await restRequest.getUserList(
      page,
      sortDirection,
      sortColumn,
      searchValue
    );
    console.log("Users Response ->", response.data.data);
    let data = response.data.data;
    setUsers(data.data);
    setTotalPage(data.pager.totalPages);
    setTotalUsers(data.pager.totalRecords);
    setIsUpdated(false);
  };
  console.log("IS UPDATED ->", isUpdated);

  useEffect(() => {
    if (isUpdated) fetchVendors();
  }, [isUpdated, page]);

  const showLeadsDetails = (row) => {
    console.log("Row ->", row);
    navigate(`/customer-listing/${row._id}`);
  };

  return (
    <Fragment>
      <Row>
        <div className="d-flex ">
          <Breadcrumbs mainTitle={`Users (${totalUsers})`} />
          <div className="d-flex gap-4">
            {/* <Col className="left-header horizontal-wrapper ps-0"> */}
            <div
              className={`input-group left-header horizontal-wrapper`}
              style={{ width: "300px" }}
            >
              <Input
                type="text"
                placeholder="Search.."
                value={searchValue}
                onChange={handleSearchValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchValue(e.target.value);
                    setIsUpdated(true);
                  }
                }}
              />
              <span className="input-group-text mobile-search">
                <Search
                  onClick={() => {
                    setIsUpdated(true);
                  }}
                />
              </span>
            </div>
            <Btn
              attrBtn={{
                color: "success",
                className: "btn-block ",
                onClick: () => navigate("/users/profile"),
              }}
            >
              <p className="mb-0 fs-7" style={{ width: "150px" }}>
                Manage user Profiles
              </p>
            </Btn>
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn-block ",
                onClick: () => setShowModal(true),
              }}
            >
              <p className="mb-0 fs-7" style={{ width: "70px" }}>
                Add User
              </p>
            </Btn>
            {/* </Col> */}
          </div>
        </div>
        <Col sm="12">
          <Card>
            <CardBody className="data-tables">
              <DataTable
                data={users}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
                paginationServer
                paginationTotalRows={totalUsers}
                onChangePage={handlePageChange}
                paginationPerPage={20}
                paginationComponentOptions={paginationComponentOptions}
                onSort={handleSort}
                // sortIcon={<ArrowDown className="d-block" />}
                // sortServer
              />
            </CardBody>
          </Card>
        </Col>
        {showModal && (
          <AddNewUser
            showModal={showModal}
            toggle={toggle}
            selectedUser={selectedUser}
            setIsUpdated={setIsUpdated}
          />
        )}
      </Row>
    </Fragment>
  );
};

export default UsersWrapper;
