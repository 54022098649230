import React, { Fragment, useEffect, useState } from "react";
import { restRequest } from "../../../api-utils/apiQueries";
import { useNavigate } from "react-router";
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import { Card, CardBody, Col, Input, Row } from "reactstrap";
import { Edit3, Eye, Search, Trash2 } from "react-feather";
import DataTable from "react-data-table-component";
import { formatDate } from "../../commun-function/CommunFunction";

const UserLeads = ({ customerId }) => {
  const [userLeads, setUserLeads] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [isUpdated, setIsUpdated] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState(0);
  const [totalVendor, setTotalVendor] = useState(0);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setPage(page);
    setIsUpdated(true);
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
  };

  const handleSearchValue = (e) => {
    let data = e.target.value;
    setSearchValue(data);
  };

  const handleSort = (column, sortDirection) => {
    console.log("Column Data ->", column.id);
    setSortDirection(sortDirection === "desc" ? 0 : 1);
    setSortColumn(column.id);
    setIsUpdated(true);
  };

  const tableColumns = [
    {
      name: "Lead Type",
      selector: (row) => row?.lead_type,
      id: "user.person_name",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.lead_type}</span>
      ),
      sortable: true,
    },
    {
      name: "Source",
      selector: (row) => row?.source,
      id: "user.mobile_number",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.source}</span>
      ),
      sortable: true,
    },
    {
      name: "Service",
      selector: (row) => row?.service,
      id: "user.mobile_number",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.service}</span>
      ),
      sortable: true,
    },
    {
      name: "Vendor",
      selector: (row) => row?.vendor,
      id: "user.mobile_number",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.vendor}</span>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      id: "user.mobile_number",
      cell: (row) => (
        <span
          className={`p-1 px-2 rounded-3 ${
            row.status === 1
              ? "bg-primary"
              : row.status === 2
              ? "bg-success"
              : "bg-danger"
          }`}
        >
          {row.status === 1
            ? "Pending"
            : row.status === 2
            ? "Response"
            : "Reject"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row?.message,
      id: "user.mobile_number",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.message}</span>
      ),
      sortable: true,
    },

    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <button
          onClick={() => showLeadsDetails(d)}
          className="border border-none rounded text-success me-2"
        >
          <Eye />
        </button>,
      ],
    },
  ];

  const fetchUserLeads = async () => {
    let response = await restRequest.getUserLeadList(customerId);
    console.log("User leads Response ->", response.data.data);
    let data = response.data.data;
    setUserLeads(data);
  };

  useEffect(() => {
    fetchUserLeads();
  }, [customerId]);

  const showLeadsDetails = (row) => {
    console.log("Row ->", row);
    navigate(`/leads/${row._id}`);
  };

  return (
    <Fragment>
      <Row>
        <div className="d-flex ">
          <Breadcrumbs mainTitle={`User Leads`} />
          <div className="d-flex gap-4">
            {/* <Col className="left-header horizontal-wrapper ps-0"> */}
            {/* <div
            className={`input-group left-header horizontal-wrapper`}
            style={{ width: "300px" }}
          >
            <Input
              type="text"
              placeholder="Search.."
              value={searchValue}
              onChange={handleSearchValue}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearchValue(e.target.value);
                  setIsUpdated(true);
                }
              }}
            />
            <span className="input-group-text mobile-search">
              <Search
                onClick={() => {
                  setIsUpdated(true);
                }}
              />
            </span>
          </div> */}
            {/* <Btn
        attrBtn={{
          color: "primary",
          className: "btn-block ",
          onClick: () => setShowModal(true),
        }}
      >
        <p className="mb-0 fs-7" style={{ width: "100px" }}>
          Add Vendor
        </p>
      </Btn> */}
            {/* </Col> */}
          </div>
        </div>
        <Col sm="12">
          <Card>
            <CardBody className="data-tables">
              <DataTable
                data={userLeads}
                columns={tableColumns}
                striped={true}
                center={true}
                //   pagination
                // selectableRows
                paginationServer
                paginationTotalRows={totalPage * 20}
                onChangePage={handlePageChange}
                // paginationComponentOptions={paginationComponentOptions}
                onSort={handleSort}
                sortServer
                // onColumnOrderChange=

                // onSelectedRowsChange={handleRowSelected}
                // clearSelectedRows={toggleDelet}
              />
            </CardBody>
          </Card>
        </Col>
        {/* {showModal && (
    <AddNewVendor
      showModal={showModal}
      toggle={toggle}
      selectedVendor={selectedVendor}
      setIsUpdated={setIsUpdated}
    />
  )} */}
      </Row>
    </Fragment>
  );
};

export default UserLeads;
