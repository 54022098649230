import Routers from "./Route";
import ProductProvider from "./_helper/Ecommerce/Product/ProductProvider";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import { ToastContainer } from "react-toastify";
import { app } from "./firebase/firebaseConfig";
import { getMessaging, onMessage } from "firebase/messaging";
import { useContext, useEffect, useState } from "react";
import { NotificationContext } from "./NotificationContext";

const App = () => {
  const { setNotificationCount, notificationCount } =
    useContext(NotificationContext);

  useEffect(() => {
    const messaging = getMessaging(app);
    onMessage(messaging, async (payload) => {
      console.log(" message received ->", payload);

      setNotificationCount((prev) => prev + 1);

      if (Notification.permission === "granted") {
        console.log("Permission granted success");

        const notificationTitle = payload.notification?.title || "Notification";
        const notificationOptions = {
          body: payload.notification?.body || "You have a new message.",
          icon: payload.notification?.icon || "/firebase-logo.png",
        };

        new Notification(notificationTitle, notificationOptions);

        const sound = new Audio("/sound.mp3");
        let resp = await sound.play();

        if (resp !== undefined) {
          resp
            .then((_) => {
              console.log("Sound Created");
            })
            .catch((error) => {
              console.log("Error playing sound:", error);
            });
        }
      }
    });
  }, [notificationCount]);

  const registerServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
      try {
        const registration = await navigator.serviceWorker.register(
          "/firebase-messaging-sw.js"
        );
        console.log("Service Worker registered:", registration);
      } catch (error) {
        console.error("Service Worker registration failed:", error);
      }
    } else {
      console.error("Service Worker is not supported in this browser.");
    }
  };
  useEffect(() => {
    registerServiceWorker();
  }, [notificationCount]);

  return (
    <>
      <AnimationThemeProvider>
        <CustomizerProvider>
          <ProductProvider>
            <Routers />
          </ProductProvider>
        </CustomizerProvider>
      </AnimationThemeProvider>
      <ToastContainer />
    </>
  );
};
export default App;
