import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import DataTable from "react-data-table-component";
import { restRequest } from "../../../api-utils/apiQueries";
import { Edit3, Eye, Search, Trash2 } from "react-feather";
import { Card, CardBody, Col, Input, Label, Media, Row } from "reactstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const UnverifiedVendors = () => {
  const [vendors, setVendors] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isUpdated, setIsUpdated] = useState(true);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState(0);
  const [vendorId, setVendorId] = useState();
  const [totalVendor, setTotalVendor] = useState(0);
  const navigate = useNavigate();

  const toggle = () => {
    setShowModal(!showModal);
    setSelectedVendor(null);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setIsUpdated(true);
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: false,
  };

  const updatedVendor = async (row) => {
    setShowModal(true);
    let response = await restRequest.editVendor(row._id);

    let data = response.data.data;
    setSelectedVendor(data);
    // console.log("Row Response Data", response.data.data);
  };
  const handleSearchValue = (e) => {
    let data = e.target.value;
    setSearchValue(data);
  };

  const handleSort = (column, sortDirection) => {
    console.log("Column Data ->", column.id);
    setSortDirection(sortDirection === "desc" ? 0 : 1);
    setSortColumn(column.id);
    setIsUpdated(true);
  };

  const handleSwitch = async (index) => {
    const newVendors = [...vendors];
    const vendor = newVendors[index];

    const updatedVendor = {
      ...vendor,
      is_verified: !vendor.is_verified,
    };
    newVendors[index] = updatedVendor;

    console.log("Vendor ->", updatedVendor);

    let data = {
      id: updatedVendor._id,
      is_verified: updatedVendor.is_verified,
    };
    console.log("api function called", data);
    let response = await restRequest.updateVendorVerify(data);
    console.log("Change Status Response ->", response.data);
    if (response.data) {
      toast.success(response.data.message);
    }
    setIsUpdated(true);
  };

  const tableColumns = [
    {
      name: "Business Name",
      selector: (row) => row?.business_name,
      id: "business_name",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row.business_name}</span>
      ),
      sortable: true,
    },
    {
      name: "Legal Business Name",
      selector: (row) => row?.legal_business_name,
      id: "contact_info.mobile_number",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.legal_business_name}
        </span>
      ),
      // sortable: true,
    },
    {
      name: "City",
      selector: (row) => row?.business_address?.city,
      id: "contact_info.mobile_number",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.business_address?.city}
        </span>
      ),
      // sortable: true,
    },
    {
      name: "Pincode",
      selector: (row) => row?.business_address?.pincode,
      id: "contact_info.mobile_number",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.business_address?.pincode}
        </span>
      ),
      // sortable: true,
    },
    {
      name: "Total Services ",
      selector: (row) => row?.serviceCount,
      id: "contact_info.mobile_number",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.serviceCount}</span>
      ),
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.contact_info?.person_name,
      id: "contact_info.person_name",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.contact_info?.person_name}
        </span>
      ),
      sortable: () => handleSort("Name"),
    },

    {
      name: "Email",
      selector: (row) => row?.contact_info?.email,
      id: "contact_info.email",
      cell: (row) => <span>{row?.contact_info?.email}</span>,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row?.contact_info?.mobile_number,
      id: "contact_info.mobile_number",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.contact_info?.mobile_number}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Verify",
      selector: (row) => row.featured_category,
      // id: "is_active",
      // sortable: true,
      cell: (row, index) => (
        <Media className="d-flex mt-2">
          <Media
            body
            className={`flex-grow-1 text-end icon-state switch-outline switch-md`}
          >
            <Label className="switch">
              <Input
                type="checkbox"
                checked={row.featured_category}
                onChange={() => handleSwitch(index)}
              />
              <span className="switch-state "></span>
            </Label>
          </Media>
          {/* <Label className="col-form-label m-r-10">Featured </Label> */}
        </Media>
      ),
      grow: 0.5,
    },
    // {
    //   name: "Active",
    //   selector: (row) => row?.is_active,
    //   // id: "is_active",
    //   // sortable: true,
    //   cell: (row, index) => (
    //     <Media className="d-flex mt-2">
    //       {/* <Media
    //         body
    //         className={`flex-grow-1 text-end icon-state switch-outline`}
    //       >
    //         <Label className="switch">
    //           <Input
    //             type="checkbox"
    //             checked={row?.is_active}
    //             onChange={() => handleSwitch(index)}
    //           />
    //           <span className="switch-state"></span>
    //         </Label>
    //       </Media> */}
    //       {/* <Label className="col-form-label m-r-10">Header Menu </Label> */}
    //     </Media>
    //   ),
    // },

    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        // <button
        //   onClick={() => showVendorDetails(d)}
        //   className="border border-none rounded text-success me-2"
        // >
        //   <Eye />
        // </button>,
        // <button
        //   onClick={() => updatedVendor(d)}
        //   className="border border-none rounded text-success me-2"
        // >
        //   <Edit3 />
        // </button>,
        // <button
        //   onClick={() => {}}
        //   className="border border-none rounded text-danger"
        // >
        //   <Trash2 />
        // </button>,
      ],
    },
  ];

  console.log("Column name ->", sortColumn);
  const fetchVendors = async () => {
    let response = await restRequest.getUnVerifyVendors(
      page,
      sortDirection,
      sortColumn,
      searchValue
    );
    let data = response.data.data;
    console.log("Response Un Verify vendor ->", data);
    setVendors(data.vendors);
    setTotalPage(data.pager.totalPages);
    setTotalVendor(data.pager.totalRecords);
    setIsUpdated(false);
  };
  console.log("IS UPDATED ->", isUpdated);

  useEffect(() => {
    if (isUpdated) fetchVendors();
  }, [isUpdated, page]);

  const showVendorDetails = (row) => {
    console.log("Row ->", row);
    navigate(`/verified-vendors/${row._id}`);
    setVendorId(row._id);
  };

  return (
    <Fragment>
      <Row>
        <div className="d-flex ">
          <Breadcrumbs mainTitle={`Unverified Vendors (${totalVendor})`} />
          <div className="d-flex gap-4">
            {/* <Col className="left-header horizontal-wrapper ps-0"> */}
            <div
              className={`input-group left-header horizontal-wrapper`}
              style={{ width: "300px" }}
            >
              <Input
                type="text"
                placeholder="Search.."
                value={searchValue}
                onChange={handleSearchValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchValue(e.target.value);
                    setIsUpdated(true);
                  }
                }}
              />
              <span className="input-group-text mobile-search">
                <Search
                  onClick={() => {
                    setIsUpdated(true);
                  }}
                />
              </span>
            </div>
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn-block ",
                onClick: () => setShowModal(true),
              }}
            >
              <p className="mb-0 fs-7" style={{ width: "100px" }}>
                Add Vendor
              </p>
            </Btn>
            {/* </Col> */}
          </div>
        </div>
        <Col>
          <Card>
            <CardBody className="data-tables">
              <DataTable
                data={vendors}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
                // selectableRows
                paginationServer
                paginationTotalRows={totalPage * 20}
                onChangePage={handlePageChange}
                paginationPerPage={20}
                paginationComponentOptions={paginationComponentOptions}
                onSort={handleSort}
                sortServer
                // onColumnOrderChange=

                // onSelectedRowsChange={handleRowSelected}
                // clearSelectedRows={toggleDelet}
              />
            </CardBody>
          </Card>
        </Col>
        {/* {showModal && (
        <AddNewVendor
          showModal={showModal}
          toggle={toggle}
          selectedVendor={selectedVendor}
          setIsUpdated={setIsUpdated}
        />
      )} */}
      </Row>
    </Fragment>
  );
};

export default UnverifiedVendors;
