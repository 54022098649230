import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { Card, CardBody, Col, Input, Row } from "reactstrap";
import { Delete, Edit3, Eye, Search, Trash2 } from "react-feather";
import DataTable from "react-data-table-component";
import { formatDate, onlyDate } from "../../../commun-function/CommunFunction";
import { restRequest } from "../../../../api-utils/apiQueries";
import AddNewProfile from "./AddNewProfile";
import { toast } from "react-toastify";

const ProfilesWrapper = () => {
  const [profileRole, setProfileRole] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isUpdated, setIsUpdated] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const navigate = useNavigate();

  const toggle = () => {
    setShowModal(!showModal);
    setSelectedProfile(null);
  };

  const editProfileData = (data) => {
    setSelectedProfile(data);
    setShowModal(true);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setIsUpdated(true);
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: false,
  };

  const handleSearchValue = (e) => {
    let data = e.target.value;
    setSearchValue(data);
  };

  const handleSort = (column, sortDirection) => {
    console.log("Column Data ->", column.id);
    setSortDirection(sortDirection === "desc" ? 0 : 1);
    setSortColumn(column.id);
    setIsUpdated(true);
  };

  const tableColumns = [
    {
      name: "Profile Name",
      selector: (row) => row?.role,
      id: "role",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.role}</span>
      ),
      sortable: true,
    },
    {
      name: "Users",
      selector: (row) => row?.count,
      id: "count",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.count}</span>
      ),
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <button
          onClick={() => editProfileData(d)}
          className="border border-none rounded text-success me-2"
        >
          <Edit3 />
        </button>,
        d.name !== "Super Admin" && (
          <button
            onClick={() => handleDeleteProfileRole(d._id)}
            className="border border-none rounded text-danger me-2"
          >
            <Trash2 />
          </button>
        ),
      ],
    },
  ];

  console.log("Column name ->", sortColumn);
  const fetchVendors = async () => {
    let response = await restRequest.getUserProfiles(sortColumn, sortDirection);
    let data = response.data.data;
    console.log("Profile Response ->", response);
    setProfileRole(data);
    // setTotalPage(data.pager.totalPages);
    // setTotalUsers(data.pager.totalRecords);
    setIsUpdated(false);
  };
  console.log("IS UPDATED ->", isUpdated);

  useEffect(() => {
    if (isUpdated) fetchVendors();
  }, [isUpdated, page]);

  const handleDeleteProfileRole = async (id) => {
    let data = {
      _id: id,
    };
    if (window.confirm("Are you sure , you want delete profile role?")) {
      let response = await restRequest.deleteProfileRole(data);
      console.log("Delete Profile Response ->", response);
      toast.success(response.data.message);
      setIsUpdated(true);
    }
  };

  const showLeadsDetails = (row) => {
    console.log("Row ->", row);
    navigate(`/customer-listing/${row._id}`);
  };

  return (
    <Fragment>
      <Row>
        <div className="d-flex ">
          <Breadcrumbs mainTitle={`profiles `} />
          <div className="d-flex gap-4">
            {/* <Col className="left-header horizontal-wrapper ps-0"> */}
            <div
              className={`input-group left-header horizontal-wrapper`}
              style={{ width: "300px" }}
            >
              <Input
                type="text"
                placeholder="Search.."
                value={searchValue}
                onChange={handleSearchValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchValue(e.target.value);
                    setIsUpdated(true);
                  }
                }}
              />
              <span className="input-group-text mobile-search">
                <Search
                  onClick={() => {
                    setIsUpdated(true);
                  }}
                />
              </span>
            </div>
            {/* <Btn
              attrBtn={{
                color: "success",
                className: "btn-block ",
                onClick: () => navigate("/users/profile"),
              }}
            >
              <p className="mb-0 fs-7" style={{ width: "150px" }}>
                Manage user Profiles
              </p>
            </Btn> */}
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn-block ",
                onClick: () => setShowModal(true),
              }}
            >
              <p className="mb-0 fs-7" style={{ width: "75px" }}>
                Add Profile
              </p>
            </Btn>
            {/* </Col> */}
          </div>
        </div>
        <Col sm="12">
          <Card>
            <CardBody className="data-tables">
              <DataTable
                data={profileRole}
                columns={tableColumns}
                striped={true}
                center={true}
                // pagination
                // paginationServer
                // paginationTotalRows={totalUsers}
                // onChangePage={handlePageChange}
                // paginationPerPage={20}
                // paginationComponentOptions={paginationComponentOptions}
                onSort={handleSort}
                sortServer
              />
            </CardBody>
          </Card>
        </Col>
        {showModal && (
          <AddNewProfile
            showModal={showModal}
            toggle={toggle}
            selectedProfile={selectedProfile}
            setIsUpdated={setIsUpdated}
          />
        )}
      </Row>
    </Fragment>
  );
};

export default ProfilesWrapper;
