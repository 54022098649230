import {
  FileText,
  GitPullRequest,
  HelpCircle,
  Layout,
  Users,
} from "react-feather";

export const MENUITEMS = [
  {
    menutitle: "Pages",
    Items: [
      {
        path: `/dashboard`,
        icon: Layout,
        title: "Dashboard",
        bookmark: true,
        type: "link",
      },
      {
        title: "Catalog",
        icon: FileText,
        active: false,
        type: "sub",
        children: [
          {
            title: "Categories",
            type: "link",
            path: `/catalog/categories`,
          },
          {
            title: "Sub Categories",
            type: "link",
            path: `/catalog/sub-categories`,
          },
        ],
      },
      {
        title: "Vendors",
        icon: FileText,
        active: false,
        type: "sub",
        children: [
          {
            title: "Verified Vendors",
            type: "link",
            path: `/verified-vendors`,
          },
          {
            title: "Unverified Vendors",
            type: "link",
            path: `unverified-vendors`,
          },
        ],
      },
      // {
      //   path: `/vendors`,
      //   icon: Layout,
      //   title: "Vendors",
      //   bookmark: true,
      //   type: "link",
      // },
      {
        path: `/leads`,
        icon: Layout,
        title: "Leads",
        bookmark: true,
        type: "link",
      },
      {
        path: `/customer-listing`,
        icon: Layout,
        title: "Customer Listing",
        bookmark: true,
        type: "link",
      },
      // {
      //   path: `/users`,
      //   icon: Users,
      //   title: "Users",
      //   bookmark: true,
      //   type: "link",
      // },
      {
        title: "Users",
        icon: Users,
        active: false,
        type: "sub",
        children: [
          {
            title: "users",
            type: "link",
            path: `/users`,
          },
          {
            title: "Profiles",
            type: "link",
            path: `/users/profile`,
          },
        ],
      },
    ],
  },
];
