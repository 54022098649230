import React, { Fragment, useEffect, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import {
  EmailAddress,
  ForgotPassword,
  Password,
  RememberPassword,
  SignIn,
} from "../../../Constant";
import { Link, useNavigate } from "react-router-dom";
import SocialAuth from "./SocialAuth";
import FormHeader from "./FormHeader";
import { toast } from "react-toastify";
import { restRequest } from "../../../api-utils/apiQueries";
// import useFcmToken from "../../../firebase/useFcmToken";
import { messaging } from "../../../firebase/firebaseConfig";
import { getToken, onMessage } from "firebase/messaging";

const LoginTab = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    nameError: false,
    passwordError: false,
  });
  const [authError, setAuthError] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  // const { fcmToken } = useFcmToken();

  const navigate = useNavigate();
  // console.log("FCM Token ->", fcmToken);

  // const loginAuth = async (e) => {
  //   e.preventDefault();
  //   if (email === "test@gmail.com" && password === "Admin@123") {
  //     toast.success("Login Success...!");
  //     navigate(`/dashboard`);
  //     localStorage.setItem("login", true);
  //   } else {
  //     toast.error("Please Enter valid email or password...!");
  //   }
  // };

  const [fcmToken, setFcmToken] = useState(null);

  // useEffect(() => {
  //   const requestPermission = async () => {
  //     try {
  //       console.log("INSIODE FUNCTION ====");

  //       const permission = await Notification.requestPermission();
  //       console.log("After RequestPermission FUNCTION ====", permission);
  //       if (permission === "granted") {
  //         console.log("Notification permission granted.");
  //         try {
  //           const token = await getToken(messaging, {
  //             vapidKey: process.env.REACT_APP_VAPID_KEY,
  //           });
  //           console.log("FCM Token:", token);
  //           setFcmToken(token);
  //         } catch (error) {
  //           console.log(error);
  //           console.error("Error getting FCM token", error);
  //         }
  //       } else {
  //         console.log("Notification permission not granted");
  //       }
  //     } catch (error) {
  //       console.error("Error requesting notification permission", error);
  //     }
  //   };
  //   console.log("Before Permission");
  //   requestPermission();

  //   const unsubscribe = onMessage(messaging, (payload) => {
  //     console.log("Message received: ", payload);
  //     const notificationTitle = payload.notification.title;
  //     const notificationOptions = {
  //       body: payload.notification.body,
  //       icon: payload.notification.icon,
  //     };
  //     new Notification(notificationTitle, notificationOptions);
  //   });

  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  useEffect(() => {
    const requestPermission = async () => {
      try {
        console.log("INSIDE FUNCTION ====");

        const permission = await Notification.requestPermission();
        console.log("After RequestPermission FUNCTION ====", permission);

        if (permission === "granted") {
          console.log("Notification permission granted.");
          // Check if service worker is registered
          if ("serviceWorker" in navigator) {
            const registration = await navigator.serviceWorker.ready;
            console.log("Service Worker is registered.");

            try {
              const token = await getToken(messaging, {
                vapidKey: process.env.REACT_APP_VAPID_KEY,
              });
              console.log("FCM Token:", token);
              setFcmToken(token);
              localStorage.setItem("fcm", token);
            } catch (error) {
              console.log(error);
              console.error("Error getting FCM token", error);
            }
          } else {
            console.error("Service Worker is not supported in this browser.");
          }
        } else {
          console.log("Notification permission not granted");
        }
      } catch (error) {
        console.error("Error requesting notification permission", error);
      }
    };

    console.log("Before Permission");
    requestPermission();

    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Message received: ", payload);
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon,
      };
      new Notification(notificationTitle, notificationOptions);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const loginAuth = async (e) => {
    e.preventDefault();
    console.log("Function Called");
    setIsLoader(true);

    if (email === "" || password === "") {
      setError({
        nameError: admin.email === "" ? true : false,
        passwordError: admin.password === "" ? true : false,
      });
      setIsLoader(false);
      return;
    }
    let admin = {
      email: email,
      password: password,
      fcm_token: fcmToken,
    };

    console.log("User ->", admin);

    try {
      const response = await restRequest.adminLogin(admin);
      const adminData = response.data.data;
      console.log("Response ->", adminData);
      localStorage.setItem("sidebar_types", "horizontal-wrapper");
      localStorage.setItem("token", adminData.JWToken);
      localStorage.setItem("user", JSON.stringify(adminData.user));

      navigate("/dashboard");
      setAuthError(false);
      setIsLoader(false);
      return;
    } catch (error) {
      console.error("Login error:", error);
      setAuthError(true);
    } finally {
      setIsLoader(false);
    }
  };

  return (
    <Fragment>
      <Form className="theme-form" onSubmit={loginAuth}>
        <FormHeader />
        <FormGroup className="form-group">
          <Label className="col-form-label">{EmailAddress}</Label>
          <Input
            className="form-control"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            defaultValue={"test@gmail.com"}
          />
        </FormGroup>
        <FormGroup className="form-group">
          <Label className="col-form-label">{Password}</Label>
          <div className="form-input position-relative">
            <Input
              className="form-control"
              type={togglePassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              defaultValue={"test123"}
              required
            />
            <div
              className="show-hide"
              onClick={() => setTogglePassword(!togglePassword)}
            >
              <span className={togglePassword ? "" : "show"}></span>
            </div>
          </div>
        </FormGroup>
        {/* <FormGroup className="form-group mb-0">
          <div className="checkbox p-0">
            <Input id="checkbox1" type="checkbox" />
            <Label className="text-muted" for="checkbox1">
              {RememberPassword}
            </Label>
          </div>
          <Link
            className="link"
            to={`${process.env.PUBLIC_URL}/pages/auth/forget-pwd`}
          >
            {ForgotPassword}
          </Link>
        </FormGroup> */}
        <div className="text-end mt-3">
          <Btn
            attrBtn={{
              color: "primary w-100",
              className: "btn-block",
              // onClick: (e) => loginAuth(e),
            }}
            type="submit"
          >
            {SignIn}
          </Btn>
        </div>
        {/* <SocialAuth /> */}
      </Form>
    </Fragment>
  );
};
export default LoginTab;
