import React, { Fragment, useEffect, useState } from "react";
import { Form, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import Select from "react-select";
import { Btn } from "../../../AbstractElements";
import { restRequest } from "../../../api-utils/apiQueries";

const UpdateStatus = ({ isStatus, toggle, id, statusCode, setIsUpdated }) => {
  const [status, setStatus] = useState([
    { value: 1, label: "New" },
    { value: 2, label: "Working" },
    { value: 3, label: "Deal Completed" },
    { value: 4, label: "Cancelled" },
  ]);
  const [statusNote, setStatusNote] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    if (statusCode) {
      let selected = status.find((status) => status.value === statusCode);
      setSelectedStatus(selected);
    }
  }, [statusCode]);

  const handleUpdateStatus = async (e) => {
    e.preventDefault();
    if (!selectedStatus) return;

    let obj = {
      lead_id: id,
      status: selectedStatus.value,
      note: statusNote,
    };
    console.log("Update Status ->", obj);

    let response = await restRequest.updateStatusCode(obj);
    console.log("Response Update Status ->", response.status);
    if (response.status === 200) {
      setIsUpdated(true);
      toggle();
    }
  };

  return (
    <Fragment>
      <Modal isOpen={isStatus} toggle={toggle}>
        <ModalHeader toggle={toggle} className="pt-4">
          <span className="fs-5 text-#534686">Update Status</span>
        </ModalHeader>
        <ModalBody className="position-relative">
          <Form onSubmit={handleUpdateStatus}>
            <div>
              <Label>Select Status Code</Label>
              <Select
                options={status}
                value={selectedStatus}
                onChange={(option) => setSelectedStatus(option)}
              />
            </div>
            <Label className="mt-3">Note</Label>
            <div>
              <textarea
                className="w-100 p-2"
                value={statusNote}
                onChange={(e) => setStatusNote(e.target.value)}
              />
            </div>
            <div className="d-flex gap-4 justify-content-end mt-5">
              <Btn
                attrBtn={{
                  color: "secondary ms-2",
                  onClick: toggle,
                }}
              >
                Cancel
              </Btn>

              <Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default UpdateStatus;
