import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Breadcrumbs, H3, H4, H5 } from "../../../AbstractElements";
import { restRequest } from "../../../api-utils/apiQueries";
import { Card, CardBody } from "reactstrap";
import { Mail, Phone, User } from "react-feather";
import { onlyDate } from "../../commun-function/CommunFunction";
import UserLeads from "./UserLeads";
import { MdOutlineWhatsapp } from "react-icons/md";
import { GrLocation } from "react-icons/gr";

const CustomerDetails = () => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);

  const fetchCustomerDetail = async () => {
    let response = await restRequest.getCustomerDetails(customerId);
    console.log("Customer Response ->", response.data.data);
    let data = response.data.data;
    setCustomer(data);
  };

  useEffect(() => {
    fetchCustomerDetail();
  }, [customerId]);

  return (
    <div>
      <Breadcrumbs mainTitle={"Customer Details"} />
      {customer && (
        <div>
          <Card className=" g-2 mx-2">
            <CardBody>
              <div className="d-flex gap-5 ">
                <p className="mb-0 fs-6 d-flex align-items-center gap-2">
                  <span>
                    <User size={16} />
                  </span>
                  <span className="text-secondary">
                    {customer.person_name ? customer.person_name : "-"}
                  </span>
                </p>
                <p className="mb-0 fs-6 d-flex align-items-center gap-2">
                  <span className="pt-1">
                    <Mail size={16} className="" />
                  </span>
                  <span className="text-secondary">
                    {customer.email ? customer.email : "-"}
                  </span>
                </p>{" "}
                <p className="mb-0 fs-6 d-flex align-items-center gap-2">
                  <span>
                    <Phone size={16} />
                  </span>
                  <span className="text-secondary">
                    {customer.mobile_number ? customer.mobile_number : "-"}
                  </span>
                </p>{" "}
                <p className="mb-0 fs-6 d-flex align-items-center gap-2">
                  <span>
                    <MdOutlineWhatsapp size={16} />
                  </span>
                  <span className="text-secondary">
                    {customer.whatsapp_number ? customer.whatsapp_number : "-"}
                  </span>
                </p>{" "}
              </div>
              <div className="d-flex gap-5 mt-3">
                <p className="mb-2 fs-6 d-flex gap-3">
                  <span className="fw-semibold text-secondary">
                    Date of Birth :
                  </span>
                  <span className="text-secondary">
                    {customer.dob ? onlyDate(customer.dob) : "-"}
                  </span>
                </p>{" "}
                <p className="mb-2 fs-6 d-flex gap-3">
                  <span className="fw-semibold text-secondary">
                    Marital Status :
                  </span>
                  <span className="text-secondary">
                    {customer.marital_status ? customer.marital_status : "-"}
                  </span>
                </p>
                <p className="mb-2 fs-6 d-flex gap-3">
                  <span className="fw-semibold text-secondary">
                    Occupation :
                  </span>
                  <span className="text-secondary">
                    {customer.occupation ? customer.occupation : "-"}
                  </span>
                </p>{" "}
              </div>
              <div className="d-flex align-items-center mt-2 gap-3">
                <p className="mb-0 mt-1">
                  <GrLocation size={16} />
                </p>
                {customer.address ? (
                  <div className="d-flex gap-2 text-secondary">
                    <p className="mb-0 fs-6">{customer.address.full_address}</p>
                    ,<p className="mb-0 fs-6">{customer.address.city}</p>,
                    <p className="mb-0 fs-6">{customer.address.state}</p>,
                    <p className="mb-0 fs-6">{customer.address.pincode}</p>
                  </div>
                ) : (
                  <p className="mb-0">-</p>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      )}
      <div>
        <UserLeads customerId={customerId} />
      </div>
    </div>
  );
};

export default CustomerDetails;
