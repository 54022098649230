// /* global google */
// import React, { Fragment, useEffect, useState } from "react";
// import {
//   APIProvider,
//   Map,
//   AdvancedMarker,
//   Pin,
//   InfoWindow,
// } from "@vis.gl/react-google-maps";
// import {
//   Button,
//   Modal,
//   ModalBody,
//   ModalFooter,
//   ModalHeader,
//   Tooltip,
// } from "reactstrap";
// import { Btn } from "../../../AbstractElements";
// import { FaMapMarkerAlt } from "react-icons/fa";
// import { IoCloseSharp } from "react-icons/io5";

// const ViewMap = ({
//   showMap,
//   handleMap,
//   setAdd,
//   setState,
//   setCity,
//   setPincode,
//   setLetLong,
// }) => {
//   const [marker, setMarker] = useState(null);
//   const [address, setAddress] = useState(null);
//   const [position, setPosition] = useState({
//     lat: 19.077552173255093,
//     lng: 72.87833290723918,
//   });
//   const [query, setQuery] = useState(null);

//   useEffect(() => {
//     if (address) {
//       setAdd(address.fullAddress);
//       setState(address.state);
//       setCity(address.city);
//       setPincode(address.pincode);
//       setLetLong(position);
//     }
//   }, [address]);

//   const handleMapClick = async (ev) => {
//     let latLng = { lat: 0, lng: 0 };
//     if (ev.detail && ev.detail.latLng) {
//       latLng = ev.detail.latLng;
//     } else if (ev.domEvent && ev.domEvent.latLng) {
//       latLng = ev.domEvent.latLng;
//     }

//     if (latLng) {
//       const newMarker = { lat: latLng.lat, lng: latLng.lng };
//       setMarker(newMarker);
//       // console.log("on click event:", newMarker.lat, newMarker.lng);

//       // Fetch address details
//       const addressDetails = await getAddressDetails(
//         newMarker.lat,
//         newMarker.lng
//       );

//       setPosition({ lat: newMarker.lat, lng: newMarker.lng });
//       setAddress(addressDetails);

//       console.log("on click ->", position);
//     } else {
//       console.log("Click event does not have latLng information.");
//     }
//   };

//   const getAddressDetails = async (lat, lng) => {
//     const geocoder = new google.maps.Geocoder();
//     const latlng = { lat, lng };

//     return new Promise((resolve, reject) => {
//       geocoder.geocode({ location: latlng }, (results, status) => {
//         if (status === "OK" && results && results.length > 0) {
//           const addressComponents = results[0].address_components;
//           let fullAddress = results[0].formatted_address;
//           let city = "";
//           let state = "";
//           let pincode = "";

//           addressComponents.forEach((component) => {
//             const types = component.types;
//             if (types.includes("locality")) {
//               city = component.long_name;
//             }
//             if (types.includes("administrative_area_level_1")) {
//               state = component.long_name;
//             }
//             if (types.includes("postal_code")) {
//               pincode = component.long_name;
//             }
//           });

//           resolve({ fullAddress, city, state, pincode });
//         } else {
//           reject(
//             "Geocode was not successful for the following reason: " + status
//           );
//         }
//       });
//     });
//   };

//   return (
//     <Fragment>
//       {/* <Tooltip isOpen={"Pic Address"}> */}
//       <Btn
//         data-bs-toggle="tooltip"
//         data-bs-placement="top"
//         data-bs-custom-class="custom-tooltip"
//         data-bs-title="Pic Address"
//         attrBtn={{ color: "primary", onClick: handleMap }}
//       >
//         <FaMapMarkerAlt />
//       </Btn>
//       {/* </Tooltip> */}
//       <Modal
//         size="xl"
//         isOpen={showMap}
//         title="Map"
//         toggler={handleMap}
//         className="w-full"
//       >
//         <ModalHeader className="w-full">Pic Address</ModalHeader>
//         <ModalBody>
//           <APIProvider apiKey={"AIzaSyAeHRibvUJGUzzU6zi1cKYvtJqkvlpRuSg"}>
//             <input
//               // ref={autocompleteRef}
//               type="text"
//               placeholder="Search for a location"
//               className="form-control mb-3"
//               value={query}
//               onKeyDown={handleKeyDown}
//             />
//             <div style={{ height: "50vh", width: "100%" }}>
//               <Map
//                 defaultZoom={9}
//                 defaultCenter={position}
//                 mapId={"da37f3254c6a6d1c"}
//                 onClick={handleMapClick}
//               >
//                 {marker && <AdvancedMarker position={marker} />}
//               </Map>
//             </div>
//           </APIProvider>
//         </ModalBody>
//         <ModalFooter>
//           <div
//             className="position-absolute end-0 top-0 p-3"
//             onClick={() => handleMap()}
//           >
//             <IoCloseSharp size={24} />
//           </div>
//           <div
//             className="border p-2 px-4 rounded-2 fs-5 bg-primary"
//             style={{ cursor: "pointer" }}
//             onClick={handleMap}
//           >
//             ok
//           </div>
//         </ModalFooter>
//       </Modal>
//     </Fragment>
//   );
// };

// export default ViewMap;

// =======================================================================================================

import React, { Fragment, useEffect, useRef, useState } from "react";
import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import "./Search.css";
import { Search } from "react-feather";

const ViewMap = ({
  showMap,
  handleMap,
  setAdd,
  setState,
  setCity,
  setPincode,
  setLetLong,
}) => {
  const [marker, setMarker] = useState(null);
  const [address, setAddress] = useState(null);
  const [position, setPosition] = useState({
    lat: 19.077552173255093,
    lng: 72.87833290723918,
  });
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  // const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);
  const autocompleteService = useRef(null);
  const [zoom, setZoom] = useState(9);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  useEffect(() => {
    // Ensure the Google Maps API script is loaded
    if (window.google && window.google.maps && window.google.maps.places) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    } else {
      console.error("Google Maps API not loaded");
    }
  }, []);

  useEffect(() => {
    if (address) {
      setAdd(address.fullAddress);
      setState(address.state);
      setCity(address.city);
      setPincode(address.pincode);
      setLetLong(position);
    }
  }, [address]);

  useEffect(() => {
    const updateMap = async () => {
      setIsLoading(true);
      if (mapRef.current && position) {
        const map = mapRef.current;
        if (map) {
          map.setCenter(position);
          map.setZoom(14);
          await new Promise((resolve) => setTimeout(resolve, 500));
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    updateMap();
  }, [position]);

  const handleKeyDown = async (event) => {
    if (event.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (event.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else if (event.key === "Enter") {
      event.preventDefault();
      setHighlightedIndex(-1);
      handleSuggestionClick(suggestions[highlightedIndex]);
      const geocoder = new window.google.maps.Geocoder();
      console.log("GeoCoder ->", geocoder);
      console.log("Set Query ->", query);
      geocoder.geocode({ address: query }, (results, status) => {
        // console.log("Status ->", status, "And Result ->", results);

        if (status === "OK" && results[0]) {
          const place = results[0];
          const newPosition = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          console.log("new Position ->", newPosition);

          setPosition(newPosition);
          setMarker(newPosition);
          setZoom(15);

          if (mapRef.current && mapRef.current.panTo) {
            mapRef.current.panTo(newPosition);
          }
          console.log("Function called");
          console.log("New Position ->", newPosition);
          setSuggestions([]);
          setAddress({
            fullAddress: place.formatted_address,
            state:
              place.address_components.find((component) =>
                component.types.includes("administrative_area_level_1")
              )?.long_name || "",
            city:
              place.address_components.find((component) =>
                component.types.includes("locality")
              )?.long_name || "",
            pincode:
              place.address_components.find((component) =>
                component.types.includes("postal_code")
              )?.long_name || "",
          });
        } else {
          console.error(
            "Geocode was not successful for the following reason:",
            status
          );
        }
      });
    }
  };

  const handleMapClick = async (ev) => {
    let latLng = { lat: 0, lng: 0 };
    if (ev.detail && ev.detail.latLng) {
      latLng = ev.detail.latLng;
    } else if (ev.domEvent && ev.domEvent.latLng) {
      latLng = ev.domEvent.latLng;
    }

    if (latLng) {
      const newMarker = { lat: latLng.lat, lng: latLng.lng };
      setMarker(newMarker);

      const addressDetails = await getAddressDetails(
        newMarker.lat,
        newMarker.lng
      );

      setPosition({ lat: newMarker.lat, lng: newMarker.lng });
      setAddress(addressDetails);
    } else {
      console.log("Click event does not have latLng information.");
    }
  };

  const getAddressDetails = async (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat, lng };

    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK" && results && results.length > 0) {
          const addressComponents = results[0].address_components;
          let fullAddress = results[0].formatted_address;
          let city = "";
          let state = "";
          let pincode = "";

          addressComponents.forEach((component) => {
            const types = component.types;
            if (types.includes("locality")) {
              city = component.long_name;
            }
            if (types.includes("administrative_area_level_1")) {
              state = component.long_name;
            }
            if (types.includes("postal_code")) {
              pincode = component.long_name;
            }
          });

          resolve({ fullAddress, city, state, pincode });
        } else {
          reject(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    });
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setHighlightedIndex(-1);
    setShowSuggestions(true);
    if (event.target.value.length > 2 && autocompleteService.current) {
      autocompleteService.current.getPlacePredictions(
        { input: event.target.value },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setSuggestions(predictions);
          } else {
            setSuggestions([]);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (place) => {
    console.log("Place ->", place);
    console.log("Marker ->", marker);

    setQuery(place.description);
    setShowSuggestions(false);

    const geocoder = new window.google.maps.Geocoder();
    console.log("GeoCoder ->", geocoder);
    console.log("Set Query ->", query);
    geocoder.geocode({ address: place?.description }, (results, status) => {
      // console.log("Status ->", status, "And Result ->", results);

      if (status === "OK" && results[0]) {
        const place = results[0];
        const newPosition = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        console.log("new Position ->", newPosition);

        setPosition(newPosition);
        setMarker(newPosition);
        setZoom(15);

        if (mapRef.current && mapRef.current.panTo) {
          mapRef.current.panTo(newPosition);
        }
        console.log("Function called");
        console.log("New Position ->", newPosition);
        setSuggestions([]);
        setAddress({
          fullAddress: place.formatted_address,
          state:
            place.address_components.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.long_name || "",
          city:
            place.address_components.find((component) =>
              component.types.includes("locality")
            )?.long_name || "",
          pincode:
            place.address_components.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "",
        });
      } else {
        console.error(
          "Geocode was not successful for the following reason:",
          status
        );
      }
    });
  };

  return (
    <Fragment>
      <Btn
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-custom-class="custom-tooltip"
        data-bs-title="Pick Address"
        attrBtn={{ color: "primary", onClick: handleMap }}
      >
        <FaMapMarkerAlt />
      </Btn>
      <Modal
        size="xl"
        isOpen={showMap}
        title="Map"
        toggler={handleMap}
        className="w-full"
      >
        <ModalHeader className="w-full">Pick Address</ModalHeader>
        <ModalBody>
          <APIProvider apiKey={"AIzaSyAeHRibvUJGUzzU6zi1cKYvtJqkvlpRuSg"}>
            <div className="position-relative my-1 ">
              <Search
                className="fs-3 position-absolute text-primary"
                style={{ top: "8px", left: "10px" }}
              />
              <div className="">
                <input
                  className="form-control form-control-sm form-control-solid ps-5"
                  placeholder="Search for a location"
                  value={query}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onFocus={() => setShowSuggestions(true)}
                />
                {showSuggestions && (
                  <div className="custom-datalist">
                    {suggestions.map((suggestion, index) => (
                      <div
                        className={`custom-option ${
                          index === highlightedIndex ? "highlighted" : ""
                        }`}
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                        // onKeyDown={() => handleKeyDown(suggestion)}
                      >
                        {suggestion.description}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div style={{ height: "50vh", width: "100%" }}>
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <Map
                  key={`${position.lat}-${position.lng}`}
                  defaultZoom={zoom}
                  defaultCenter={position}
                  mapId={"da37f3254c6a6d1c"}
                  onClick={handleMapClick}
                  ref={mapRef}
                >
                  {marker && <AdvancedMarker position={marker} />}
                </Map>
              )}
            </div>
          </APIProvider>
        </ModalBody>
        <ModalFooter>
          <div
            className="position-absolute end-0 top-0 p-3"
            onClick={() => handleMap()}
          >
            <IoCloseSharp size={24} />
          </div>
          <div
            className="border p-2 px-4 rounded-2 fs-5 bg-primary"
            style={{ cursor: "pointer" }}
            onClick={handleMap}
          >
            OK
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ViewMap;
