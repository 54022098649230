// Error-pages
import ErrorPage1 from "../Components/Pages/Error/Error_page1";
import ErrorPage2 from "../Components/Pages/Error/Error_page2";
import ErrorPage3 from "../Components/Pages/Error/Error_page3";
import ErrorPage4 from "../Components/Pages/Error/Error_page4";
import ErrorPage5 from "../Components/Pages/Error/Error_page5";

// Login
import LoginSimple from "../Components/Pages/Auth/Login";
import LoginOne from "../Components/Pages/Auth/LoginOne";
import LoginTwo from "../Components/Pages/Auth/LoginTwo";
import LoginValidation from "../Components/Pages/Auth/LoginValidation";
import LoginTooltip from "../Components/Pages/Auth/LoginTooltip";
import LoginSweetalert from "../Components/Pages/Auth/LoginSweetalert";

//Register pages
import RegisterSimple from "../Components/Pages/Auth/RegisterSimple";
import RegisterBgImg from "../Components/Pages/Auth/RegisterBgImg";
import RegisterVideo from "../Components/Pages/Auth/RegisterVideo";
import UnlockUser from "../Components/Pages/Auth/UnlockUser";
import ForgotPwd from "../Components/Pages/Auth/ForgetPwd";
import ResetPwd from "../Components/Pages/Auth/ResetPassword";
import Maintenance from "../Components/Pages/Auth/Maintenance";

//ComingSoon Pages
import Comingsimple from "../Components/Pages/CommingSoon/ComingSimple";
import Comingwithbgvideo from "../Components/Pages/CommingSoon/ComingwithbgVideo";
import ComingbgImage from "../Components/Pages/CommingSoon/ComingSoonbgImg";

export const authRoutes = [
  //Login and Register pages
  { path: `/auth/login-simple`, Component: <LoginSimple /> },
  { path: `/auth/login-bg-img`, Component: <LoginOne /> },
  { path: `/auth/login-img`, Component: <LoginTwo /> },
  { path: `/auth/login-validation`, Component: <LoginValidation /> },
  { path: `/auth/login-tooltip`, Component: <LoginTooltip /> },
  { path: `/auth/login-sweetalert`, Component: <LoginSweetalert /> },
  { path: `/auth/register-simple`, Component: <RegisterSimple /> },
  { path: `/auth/register-bg-img`, Component: <RegisterBgImg /> },
  { path: `/auth/sign-up-two`, Component: <RegisterVideo /> },
  { path: `/auth/unlock-user`, Component: <UnlockUser /> },
  { path: `/auth/forget-pwd`, Component: <ForgotPwd /> },
  { path: `/auth/reset-pwd`, Component: <ResetPwd /> },
  { path: `/auth/maintenance`, Component: <Maintenance /> },

  // coming soon
  { path: `/comingsimple/comingsoon`, Component: <Comingsimple /> },
  { path: `/comingsimple/coming-bg-video`, Component: <Comingwithbgvideo /> },
  { path: `/comingsimple/coming-bg-img`, Component: <ComingbgImage /> },

  //Error
  { path: `/error/error-page1`, Component: <ErrorPage1 /> },
  { path: `/error/error-page2`, Component: <ErrorPage2 /> },
  { path: `/error/error-page3`, Component: <ErrorPage3 /> },
  { path: `/error/error-page4`, Component: <ErrorPage4 /> },
  { path: `/error/error-page5`, Component: <ErrorPage5 /> },
];
