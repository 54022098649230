import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Toast,
  Input,
  InputGroup,
  Col,
  Container,
  Card,
  CardBody,
} from "reactstrap";
import { Btn, H3, P } from "../../../AbstractElements";
import DatePicker from "react-datepicker";
import { restRequest } from "../../../api-utils/apiQueries";
import { toast } from "react-toastify";
import { FaMapMarkerAlt } from "react-icons/fa";
import ViewMap from "./ViewMap";
import Dropzone from "react-dropzone-uploader";
import { fileURL } from "../../../api-utils/constantVariables";

const setTime = (date) => {
  console.log("Time ->", date);
  const [time, modifier] = date?.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  // Convert 12-hour format to 24-hour format
  if (modifier === "PM" && hours !== 12) {
    hours += 12;
  }
  if (modifier === "AM" && hours === 12) {
    hours = 0;
  }

  // Create a new Date object for the current date
  const currentDate = new Date();
  currentDate.setHours(hours, minutes, 0, 0);

  const weekday = currentDate.toLocaleString("en-US", { weekday: "short" });
  const month = currentDate.toLocaleString("en-US", { month: "short" });
  const day = String(currentDate.getDate()).padStart(2, "0");
  const year = currentDate.getFullYear();
  const hour = String(currentDate.getHours()).padStart(2, "0");
  const minute = String(currentDate.getMinutes()).padStart(2, "0");
  const second = String(currentDate.getSeconds()).padStart(2, "0");

  // Get the timezone offset in hours and minutes
  const timezoneOffset = -currentDate.getTimezoneOffset();
  const timezoneHours = String(Math.floor(timezoneOffset / 60)).padStart(
    2,
    "0"
  );
  const timezoneMinutes = String(timezoneOffset % 60).padStart(2, "0");
  const timezoneSign = timezoneOffset >= 0 ? "+" : "-";

  // Construct the final string
  const formattedDate = `${weekday} ${month} ${day} ${year} ${hour}:${minute}:${second} GMT${timezoneSign}${timezoneHours}${timezoneMinutes} (India Standard Time)`;

  // Print the output
  console.log(formattedDate);
  return new Date(formattedDate);
};

const AddNewVendor = ({ showModal, toggle, selectedVendor, setIsUpdated }) => {
  // console.log("Selected Vendor ->", selectedVendor);
  const [businessName, setBusinessName] = useState("");
  const [legalBusinessName, setLegalBusinessName] = useState("");
  const [businessRegistrationNumber, setBusinessRegistrationNumber] =
    useState("");
  const [establishmentDate, setEstablishmentDate] = useState("");
  const [personName, setPersonName] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsappMobile, setWhatsappMobile] = useState("");
  const [email, setEmail] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [address, setAddress] = useState("");
  const [streetName, setStreetName] = useState("");
  const [landmark, setLandmark] = useState("");
  const [area, setArea] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [sunday, setSunday] = useState(false);
  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [saturday, setSaturday] = useState(false);
  const [sundayOpen, setSundayOpen] = useState("");
  const [sundayClose, setSundayClose] = useState("");
  const [mondayOpen, setMondayOpen] = useState("");
  const [mondayClose, setMondayClose] = useState("");
  const [tuesdayOpen, setTuesdayOpen] = useState("");
  const [tuesdayClose, setTuesdayClose] = useState("");
  const [wednesdayOpen, setWednesdayOpen] = useState("");
  const [wednesdayClose, setWednesdayClose] = useState("");
  const [thursdayOpen, setThursdayOpen] = useState("");
  const [thursdayClose, setThursdayClose] = useState("");
  const [fridayOpen, setFridayOpen] = useState("");
  const [fridayClose, setFridayClose] = useState("");
  const [saturdayOpen, setSaturdayOpen] = useState("");
  const [saturdayClose, setSaturdayClose] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [letLong, setLetLong] = useState(null);
  const [featureImg, setFeatureImg] = useState(null);
  const [image, setImage] = useState(null);
  const [otherImages, setOtherImages] = useState([]);
  const [selectedOtherImage, setSelectedOtherImage] = useState([]);
  const [isLatLngError, setIsLatLngError] = useState(false);
  const [personError, setPersonError] = useState({
    nameError: false,
    mobileError: false,
    emailError: false,
  });
  const [isUploaded, setIsUploaded] = useState(true);

  // single file
  const handleFeatureImgChange = async ({ meta, file }, status) => {
    setIsUploaded(false);
    if (status === "done") {
      let formData = new FormData();
      formData.append("from", "vendor");
      formData.append("filed_name", "feature_image");
      formData.append("file", file);
      let response = await restRequest.uploadFileData(formData);
      console.log("File Response", response.data.data);
      if (response.data.code === 200) {
        setIsUploaded(true);
      }
      setFeatureImg(response.data.data);
    } else if (status === "removed") {
      setFeatureImg([]);
    }
  };

  //Multi file
  const handleOtherImagesChange = async ({ meta, file }, status) => {
    setIsUploaded(false);
    if (status === "done") {
      // Create form data for the API request
      let formData = new FormData();
      formData.append("from", "vendor");
      formData.append("filed_name", "other_image");
      formData.append("file", file);

      try {
        // Make the API request to upload the file
        let response = await restRequest.uploadFileData(formData);
        console.log("File Response", response.data.data);
        if (response.data.code === 200) {
          setIsUploaded(true);
        }
        // Update the state with the new file data
        setSelectedOtherImage((prev) => [...prev, response.data.data]);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else if (status === "removed") {
      // Remove the image from state
      setSelectedOtherImage((prev) =>
        prev.filter((img) => img.name !== file.name)
      );
    }
  };

  const handleImageRemove = (name) => {
    console.log("Image name ->", name);

    setOtherImages((prev) => prev.filter((img) => img.originalname !== name));
  };

  const handleMap = () => {
    setIsLatLngError(false);
    setShowMap(!showMap);
  };
  // console.log(
  //   "Before Update Date ->",
  //   new Date(selectedVendor?.establishment_on)
  // );

  useEffect(() => {
    console.log("Selected Vendor ->", selectedVendor);

    if (selectedVendor) {
      setBusinessName(
        selectedVendor.business_name ? selectedVendor.business_name : ""
      );
      setLegalBusinessName(
        selectedVendor.legal_business_name
          ? selectedVendor.legal_business_name
          : ""
      );
      setBusinessRegistrationNumber(
        selectedVendor.gst ? selectedVendor.gst : ""
      );
      setEstablishmentDate(
        selectedVendor.establishment_on
          ? new Date(selectedVendor.establishment_on)
          : ""
      );

      setPersonName(
        selectedVendor?.contact_info
          ? selectedVendor.contact_info.person_name
          : ""
      );
      setMobile(
        selectedVendor?.contact_info
          ? selectedVendor.contact_info.mobile_number
          : ""
      );
      setWhatsappMobile(
        selectedVendor?.contact_info
          ? selectedVendor?.contact_info.whatsapp_number
          : ""
      );
      setEmail(
        selectedVendor?.contact_info ? selectedVendor.contact_info.email : ""
      );
      setBuildingName(
        selectedVendor?.business_address
          ? selectedVendor.business_address.building_name
          : ""
      );
      setAddress(
        selectedVendor?.business_address
          ? selectedVendor.business_address.address
          : ""
      );
      setStreetName(
        selectedVendor?.business_address
          ? selectedVendor.business_address.street_name
          : ""
      );
      setLandmark(
        selectedVendor?.business_address
          ? selectedVendor.business_address.landmark
          : ""
      );
      setArea(
        selectedVendor?.business_address
          ? selectedVendor.business_address.area
          : ""
      );
      setPincode(
        selectedVendor?.business_address
          ? selectedVendor.business_address.pincode
          : ""
      );
      setState(
        selectedVendor?.business_address
          ? selectedVendor.business_address.state
          : ""
      );
      setCity(
        selectedVendor?.business_address
          ? selectedVendor.business_address.city
          : ""
      );
      setSunday(selectedVendor?.business_time?.sunday ? true : false);
      setMonday(selectedVendor?.business_time?.monday ? true : false);
      setTuesday(selectedVendor?.business_time?.tuesday ? true : false);
      setWednesday(selectedVendor?.business_time?.wednesday ? true : false);
      setThursday(selectedVendor?.business_time?.thursday ? true : false);
      setFriday(selectedVendor?.business_time?.friday ? true : false);
      setSaturday(selectedVendor?.business_time?.saturday ? true : false);
      setSundayOpen(
        selectedVendor?.business_time?.sunday?.open
          ? setTime(selectedVendor?.business_time?.sunday?.open)
          : ""
      );
      setSundayClose(
        selectedVendor?.business_time?.sunday?.close
          ? setTime(selectedVendor?.business_time?.sunday?.close)
          : ""
      );
      setMondayOpen(
        selectedVendor?.business_time?.monday?.open
          ? setTime(selectedVendor?.business_time?.monday?.open)
          : ""
      );
      setMondayClose(
        selectedVendor?.business_time?.monday?.close
          ? setTime(selectedVendor?.business_time?.monday?.close)
          : ""
      );
      setTuesdayOpen(
        selectedVendor?.business_time?.tuesday?.open
          ? setTime(selectedVendor?.business_time?.tuesday?.open)
          : ""
      );
      setTuesdayClose(
        selectedVendor?.business_time?.tuesday?.close
          ? setTime(selectedVendor?.business_time?.tuesday?.close)
          : ""
      );
      setWednesdayOpen(
        selectedVendor?.business_time?.wednesday?.open
          ? setTime(selectedVendor?.business_time?.wednesday?.open)
          : ""
      );
      setWednesdayClose(
        selectedVendor?.business_time?.wednesday?.close
          ? setTime(selectedVendor?.business_time?.wednesday?.close)
          : ""
      );
      setThursdayOpen(
        selectedVendor?.business_time?.thursday?.open
          ? setTime(selectedVendor?.business_time?.thursday?.open)
          : ""
      );
      setThursdayClose(
        selectedVendor?.business_time?.thursday?.close
          ? setTime(selectedVendor?.business_time?.thursday?.close)
          : ""
      );
      setFridayOpen(
        selectedVendor?.business_time?.friday?.open
          ? setTime(selectedVendor?.business_time?.friday?.open)
          : ""
      );
      setFridayClose(
        selectedVendor?.business_time?.friday?.close
          ? setTime(selectedVendor?.business_time?.friday?.close)
          : ""
      );
      setSaturdayOpen(
        selectedVendor?.business_time?.saturday?.open
          ? setTime(selectedVendor?.business_time?.saturday?.open)
          : ""
      );
      setSaturdayClose(
        selectedVendor?.business_time?.saturday?.close
          ? setTime(selectedVendor?.business_time?.saturday?.close)
          : ""
      );
      setOtherImages(
        selectedVendor?.other_image ? selectedVendor?.other_image : []
      );
      setImage(
        selectedVendor?.other_image ? selectedVendor?.feature_image : null
      );
    }
  }, [selectedVendor]);

  function formatTime(date) {
    console.log("add Time ->", date);
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    console.log("Date ->", new Date(date).toLocaleTimeString("en-US", options));
    return new Date(date).toLocaleTimeString("en-US", options);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!letLong) {
      setIsLatLngError(true);
    }

    if (personName === "" || mobile === "" || email === "") {
      setPersonError({
        nameError: personName !== "" ? false : true,
        mobileError: mobile !== "" ? false : true,
        emailError: email !== "" ? false : true,
      });
    }
    let month = new Date(
      establishmentDate !== ""
        ? establishmentDate
        : selectedVendor?.establishment_on
    ).getMonth();
    console.log("Month ->", month);
    let year = new Date(
      establishmentDate !== ""
        ? establishmentDate
        : selectedVendor?.establishment_on
    ).getFullYear();

    //Convert FormData
    const objectToFormData = (
      obj,
      formData = new FormData(),
      parentKey = ""
    ) => {
      if (
        obj &&
        typeof obj === "object" &&
        !(obj instanceof Date) &&
        !(obj instanceof File)
      ) {
        Object.keys(obj).forEach((key) => {
          const value = obj[key];
          const newKey = parentKey ? `${parentKey}[${key}]` : key;

          if (Array.isArray(value)) {
            value.forEach((v, i) => {
              objectToFormData(v, formData, `${newKey}[${i}]`);
            });
          } else if (typeof value === "object" && value !== null) {
            objectToFormData(value, formData, newKey);
          } else {
            formData.append(newKey, value);
          }
        });
      } else {
        formData.append(parentKey, obj);
      }
      return formData;
    };
    let allOtherImages = [...otherImages, ...selectedOtherImage];

    let newVendor = {
      business_name: businessName !== "" ? businessName : null,
      legal_business_name: legalBusinessName !== "" ? legalBusinessName : null,
      gst:
        businessRegistrationNumber !== "" ? businessRegistrationNumber : null,
      establishment_on: `${year}-${
        month + 1 < 10 ? `0${month + 1}` : month + 1
      }`,
      // establishmentDate !== ""
      // : selectedVendor?.establishment_on
      // ? selectedVendor?.establishment_on
      // : null,
      // contact_info: {
      person_name: personName !== "" ? personName : null,
      mobile_number: mobile !== "" ? mobile : null,
      whatsapp_number: whatsappMobile !== "" ? whatsappMobile : null,
      email: email !== "" ? email : null,
      // },
      business_address: {
        building_name: buildingName !== "" ? buildingName : null,
        address: address !== "" ? address : null,
        street_name: streetName !== "" ? streetName : null,
        landmark: landmark !== "" ? landmark : null,
        area: area !== "" ? area : null,
        pincode: pincode ? pincode : null,
        city: city ? city : null,
        state: state ? state : null,
      },
      lat: letLong
        ? letLong.lat
        : selectedVendor?.business_address?.lat
        ? selectedVendor?.business_address?.lat
        : null,
      lng: letLong
        ? letLong.lng
        : selectedVendor?.business_address?.lng
        ? selectedVendor?.business_address?.lng
        : null,
      feature_image:
        featureImg !== null ? featureImg : selectedVendor?.feature_image,

      business_time: {
        monday: monday
          ? {
              open: mondayOpen
                ? formatTime(mondayOpen)
                : selectedVendor?.business_time?.monday?.open
                ? selectedVendor?.business_time?.monday?.open
                : null,
              close: mondayClose
                ? formatTime(mondayClose)
                : selectedVendor?.business_time?.monday?.close
                ? selectedVendor?.business_time?.monday?.close
                : null,
            }
          : null,
        tuesday: tuesday
          ? {
              open: tuesdayOpen
                ? formatTime(tuesdayOpen)
                : selectedVendor?.business_time?.tuesday?.open
                ? selectedVendor?.business_time?.tuesday?.open
                : null,
              close: tuesdayClose
                ? formatTime(tuesdayClose)
                : selectedVendor?.business_time?.tuesday?.close
                ? selectedVendor?.business_time?.tuesday?.close
                : null,
            }
          : null,
        wednesday: wednesday
          ? {
              open: wednesdayOpen
                ? formatTime(wednesdayOpen)
                : selectedVendor?.business_time?.wednesday?.open
                ? selectedVendor?.business_time?.wednesday?.open
                : null,
              close: wednesdayClose
                ? formatTime(wednesdayClose)
                : selectedVendor?.business_time?.wednesday?.close
                ? selectedVendor?.business_time?.wednesday?.close
                : null,
            }
          : null,
        thursday: thursday
          ? {
              open: thursdayOpen
                ? formatTime(thursdayOpen)
                : selectedVendor?.business_time?.thursday?.open
                ? selectedVendor?.business_time?.thursday?.open
                : null,
              close: thursdayClose
                ? formatTime(thursdayClose)
                : selectedVendor?.business_time?.thursday?.close
                ? selectedVendor?.business_time?.thursday?.close
                : null,
            }
          : null,
        friday: friday
          ? {
              open: fridayOpen
                ? formatTime(fridayOpen)
                : selectedVendor?.business_time?.friday?.open
                ? selectedVendor?.business_time?.friday?.open
                : null,
              close: fridayClose
                ? formatTime(fridayClose)
                : selectedVendor?.business_time?.friday?.close
                ? selectedVendor?.business_time?.friday?.close
                : null,
            }
          : null,
        saturday: saturday
          ? {
              open: saturdayOpen
                ? formatTime(saturdayOpen)
                : selectedVendor?.business_time?.saturday?.open
                ? selectedVendor?.business_time?.saturday?.open
                : null,
              close: saturdayClose
                ? formatTime(saturdayClose)
                : selectedVendor?.business_time?.saturday?.close
                ? selectedVendor?.business_time?.saturday?.close
                : null,
            }
          : null,
        sunday: sunday
          ? {
              open: sundayOpen
                ? formatTime(sundayOpen)
                : selectedVendor?.business_time?.sunday?.open
                ? selectedVendor?.business_time?.sunday?.open
                : null,
              close: sundayClose
                ? formatTime(sundayClose)
                : selectedVendor?.business_time?.sunday?.close
                ? selectedVendor?.business_time?.sunday?.close
                : null,
            }
          : null,
      },
      other_image: allOtherImages,
    };

    console.log(
      "After Update Date ->",
      // new Date(selectedVendor.establishment_on)
      establishmentDate !== ""
        ? `${year}-${month}`
        : selectedVendor?.establishment_on
        ? selectedVendor?.establishment_on
        : null
    );
    if (selectedVendor && selectedVendor?._id) {
      newVendor.vendor_id = selectedVendor._id;
    }

    // newVendor.contact_info = JSON.stringify(newVendor.contact_info);
    // newVendor.business_address = JSON.stringify(newVendor.business_address);
    // newVendor.business_time = JSON.stringify(newVendor.business_time);

    // let formData = new FormData();
    // Object.entries(newVendor).forEach(([key, value]) => {
    //   formData.append(key, value);
    // });
    // const formData = objectToFormData(newVendor);

    // Append other images if they exist and are an array
    // if (otherImages && otherImages.length > 0) {
    //   otherImages.forEach((image) => {
    //     formData.append("other_image", image);
    //   });
    // }

    let selected = selectedVendor ? selectedVendor : null;
    if (selected?._id) {
      let response = await restRequest.updatedVendor(newVendor);
      if (response?.data) {
        setIsUpdated(true);
        toggle();
        toast.success(response.data.message);
      }
      console.log("Updated Vendor ->", newVendor);
    } else {
      console.log("New Vendor ->", newVendor);
      let response = await restRequest.addVendor(newVendor);
      // console.log(response?.data);
      if (response?.data) {
        setIsUpdated(true);
        toggle();
        toast.success(response.data.message);
      }
    }
  };

  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle} className="pt-4">
          <span className="fs-5 text-#534686">
            {" "}
            {selectedVendor ? "Update Vendor" : "Add Vendor"}{" "}
          </span>
        </ModalHeader>
        <ModalBody className="position-relative">
          <Form
            className="form-bookmark needs-validation"
            onSubmit={handleSubmit}
          >
            <Container fluid={true} className="add-product">
              <Row>
                <div
                  className="form-row "
                  style={{
                    maxHeight: "700px",
                    overflowY: "auto",
                  }}
                >
                  <Col lg="6">
                    <Card className="border m-1">
                      <CardBody>
                        <H3>Business Information</H3>
                        <div className="product-info ">
                          <FormGroup className="col-md-12">
                            <Label>Business Name</Label>
                            <input
                              className="form-control"
                              value={businessName}
                              type="text"
                              autoComplete="off"
                              onChange={(e) => setBusinessName(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <Label>Legal Business Name</Label>
                            <input
                              className="form-control"
                              value={legalBusinessName}
                              type="text"
                              autoComplete="off"
                              onChange={(e) =>
                                setLegalBusinessName(e.target.value)
                              }
                            />
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <Label>Business Registration Number(GST)</Label>
                            <input
                              className="form-control"
                              type="text"
                              value={businessRegistrationNumber}
                              onChange={(e) =>
                                setBusinessRegistrationNumber(e.target.value)
                              }
                            />
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <Label>Establishment Date</Label>
                            {/* <Col xl="5" sm="9"> */}
                            <div className="input-group">
                              <DatePicker
                                className="form-control digits"
                                selected={establishmentDate}
                                onChange={(date) => setEstablishmentDate(date)}
                                dateFormat="MMMM,yyyy"
                              />
                            </div>
                            {/* </Col> */}
                          </FormGroup>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6">
                    <Card className="border m-1">
                      <CardBody>
                        <H3>Contact Information</H3>
                        <div className="product-info">
                          <FormGroup className="col-md-12">
                            <Label>Person Name</Label>
                            <input
                              className="form-control"
                              value={personName}
                              type="text"
                              autoComplete="off"
                              onChange={(e) => {
                                setPersonName(e.target.value);
                                // setPersonError({
                                //   ...personError,
                                //   nameError: false,
                                // });
                              }}
                            />
                            {personError.nameError && (
                              <p className="text-danger">
                                Person name is require
                              </p>
                            )}
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <Label>Mobile Number</Label>
                            <input
                              className="form-control"
                              value={mobile}
                              type="number"
                              autoComplete="off"
                              onChange={(e) => {
                                setMobile(e.target.value);
                                // setPersonError({
                                //   ...personError,
                                //   mobileError: false,
                                // });
                              }}
                            />
                            {personError.mobileError && (
                              <p className="text-danger">
                                Mobile number is require
                              </p>
                            )}
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <Label>Whatsapp Number</Label>
                            <input
                              className="form-control"
                              value={whatsappMobile}
                              type="number"
                              autoComplete="off"
                              onChange={(e) => {
                                setWhatsappMobile(e.target.value);
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <Label> Email Address</Label>
                            <input
                              className="form-control"
                              value={email}
                              type="email"
                              autoComplete="off"
                              onChange={(e) => {
                                setEmail(e.target.value);
                                // setPersonError({
                                //   ...personError,
                                //   emailError: false,
                                // });
                              }}
                            />
                            {personError.emailError && (
                              <p className="text-danger">Email is require</p>
                            )}
                          </FormGroup>
                        </div>
                      </CardBody>
                    </Card>{" "}
                  </Col>
                  <Col lg="6">
                    <Card className="border m-1">
                      <CardBody>
                        <div className="d-flex justify-content-between">
                          <H3>Business Address</H3>
                          <ViewMap
                            showMap={showMap}
                            handleMap={handleMap}
                            setAdd={setAddress}
                            setState={setState}
                            setCity={setCity}
                            setPincode={setPincode}
                            setLetLong={setLetLong}
                          />
                        </div>
                        {isLatLngError && (
                          <p className="text-danger">
                            Please pick address from map for set lat lng
                          </p>
                        )}
                        <div className="product-info">
                          <FormGroup className="col-md-12">
                            {/* <Label className="d-block">Business Address</Label> */}
                            <Label>Building Name</Label>
                            <input
                              className="form-control"
                              value={buildingName}
                              type="text"
                              autoComplete="off"
                              onChange={(e) => setBuildingName(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <Label> Address</Label>
                            <input
                              className="form-control"
                              value={address}
                              type="text"
                              autoComplete="off"
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <Label>Street Name</Label>
                            <input
                              className="form-control"
                              value={streetName}
                              type="text"
                              autoComplete="off"
                              onChange={(e) => setStreetName(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <Label>Landmark</Label>
                            <input
                              className="form-control"
                              value={landmark}
                              type="text"
                              autoComplete="off"
                              onChange={(e) => setLandmark(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <Label>Area</Label>
                            <input
                              className="form-control"
                              value={area}
                              type="text"
                              autoComplete="off"
                              onChange={(e) => setArea(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <Label>Pincode</Label>
                            <input
                              className="form-control"
                              value={pincode}
                              type="number"
                              autoComplete="off"
                              onChange={(e) => setPincode(e.target.value)}
                            />
                            {/* <Select
                  options={pincode}
                  value={selectedPincode}
                  onChange={(options) => setSelectedPincode(options)}
                /> */}
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <Label>State</Label>
                            <input
                              className="form-control"
                              value={state}
                              type="text"
                              autoComplete="off"
                              onChange={(e) => setState(e.target.value)}
                            />
                            {/* <Select
                  options={state}
                  value={selectedState}
                  onChange={(options) => setSelectedState(options)}
                /> */}
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <Label>City</Label>
                            <input
                              className="form-control"
                              value={city}
                              type="text"
                              autoComplete="off"
                              onChange={(e) => setCity(e.target.value)}
                            />
                            {/* <Select
                  options={city}
                  value={selectedCity}
                  onChange={(options) => setSelectedCity(options)}
                /> */}
                          </FormGroup>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6">
                    <Card className="border m-1">
                      <CardBody>
                        <H3>Update Business Time</H3>
                        <div className="product-info">
                          <FormGroup className="col-md-12">
                            {/* <Label className="d-block">Update Business Time</Label> */}
                            <div className="d-flex justify-content-between">
                              <div className="form-check checkbox checkbox-primary mb-0">
                                <Input
                                  id="sunday"
                                  type="checkbox"
                                  checked={sunday}
                                  value={sunday}
                                  onChange={(e) => setSunday(e.target.checked)}
                                />
                                <Label htmlFor="sunday">Sunday</Label>
                              </div>
                              {sunday && (
                                <div className="d-flex">
                                  <Label className="col-sm-3 col-form-label text-end me-2">
                                    Open
                                  </Label>
                                  <Col xl="3" sm="4" lg="5">
                                    <InputGroup
                                      className="date d-flex"
                                      id="dt-minimum"
                                      data-target-input="nearest"
                                    >
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={sundayOpen}
                                        onChange={(date) => setSundayOpen(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                      />
                                      {/* <div className="input-group-text">
                          <i className="fa fa-clock-o"></i>
                        </div> */}
                                    </InputGroup>
                                  </Col>
                                  <Label className="col-sm-3 col-form-label text-end me-2">
                                    Close
                                  </Label>
                                  <Col xl="3" sm="4" lg="5">
                                    <InputGroup
                                      className="date"
                                      id="dt-minimum"
                                      data-target-input="nearest"
                                    >
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={sundayClose}
                                        onChange={(date) =>
                                          setSundayClose(date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                      />
                                      {/* <div className="input-group-text">
                          <i className="fa fa-clock-o"></i>
                        </div> */}
                                    </InputGroup>
                                  </Col>
                                </div>
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <div className="d-flex justify-content-between">
                              <div className="form-check checkbox checkbox-primary mb-0">
                                <input
                                  className="form-check-input"
                                  id="monday"
                                  type="checkbox"
                                  // value={monday}
                                  checked={monday}
                                  onChange={(e) => setMonday(e.target.checked)}
                                />
                                <Label htmlFor="monday">Monday</Label>
                              </div>
                              {monday && (
                                <div className="d-flex">
                                  <Label className="col-sm-3 col-form-label text-end me-2">
                                    Open
                                  </Label>
                                  <Col xl="3" sm="4" lg="4">
                                    <InputGroup
                                      className="date d-flex"
                                      id="dt-minimum"
                                      data-target-input="nearest"
                                    >
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={mondayOpen}
                                        onChange={(date) => setMondayOpen(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                      />
                                    </InputGroup>
                                  </Col>
                                  <Label className="col-sm-3 col-form-label text-end me-2">
                                    Close
                                  </Label>
                                  <Col xl="3" sm="4" lg="4">
                                    <InputGroup
                                      className="date"
                                      id="dt-minimum"
                                      data-target-input="nearest"
                                    >
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={mondayClose}
                                        onChange={(date) =>
                                          setMondayClose(date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                      />
                                    </InputGroup>
                                  </Col>
                                </div>
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup className="col-md-12">
                            <div className="d-flex justify-content-between">
                              <div className="form-check checkbox checkbox-primary mb-0">
                                <Input
                                  id="tuesday"
                                  type="checkbox"
                                  checked={tuesday ? true : false}
                                  onChange={(e) => setTuesday(e.target.checked)}
                                />
                                <Label htmlFor="tuesday">Tuesday</Label>
                              </div>
                              {tuesday && (
                                <div className="d-flex">
                                  <Label className="col-sm-3 col-form-label text-end me-2">
                                    Open
                                  </Label>
                                  <Col xl="3" sm="3" lg="4">
                                    <InputGroup
                                      className="date d-flex"
                                      id="dt-minimum"
                                      data-target-input="nearest"
                                    >
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={tuesdayOpen}
                                        onChange={(date) =>
                                          setTuesdayOpen(date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                      />
                                    </InputGroup>
                                  </Col>
                                  <Label className="col-sm-3 col-form-label text-end me-2">
                                    Close
                                  </Label>
                                  <Col xl="3" sm="3" lg="4">
                                    <InputGroup
                                      className="date"
                                      id="dt-minimum"
                                      data-target-input="nearest"
                                    >
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={tuesdayClose}
                                        onChange={(date) =>
                                          setTuesdayClose(date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                      />
                                    </InputGroup>
                                  </Col>
                                </div>
                              )}
                            </div>
                          </FormGroup>{" "}
                          <FormGroup className="col-md-12">
                            <div className="d-flex justify-content-between">
                              <div className="form-check checkbox checkbox-primary mb-0">
                                <Input
                                  id="wednesday"
                                  type="checkbox"
                                  checked={wednesday}
                                  onChange={(e) =>
                                    setWednesday(e.target.checked)
                                  }
                                />
                                <Label htmlFor="wednesday">Wednesday</Label>
                              </div>
                              {wednesday && (
                                <div className="d-flex ">
                                  <Label className="col-sm-3 col-form-label text-end me-2">
                                    Open
                                  </Label>
                                  <Col xl="3" sm="4" lg="4">
                                    <InputGroup
                                      className="date d-flex"
                                      id="dt-minimum"
                                      data-target-input="nearest"
                                    >
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={wednesdayOpen}
                                        onChange={(date) =>
                                          setWednesdayOpen(date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                      />
                                    </InputGroup>
                                  </Col>
                                  <Label className="col-sm-3 col-form-label text-end me-2">
                                    Close
                                  </Label>
                                  <Col xl="3" sm="3" lg="4">
                                    <InputGroup
                                      className="date"
                                      id="dt-minimum"
                                      data-target-input="nearest"
                                    >
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={wednesdayClose}
                                        onChange={(date) =>
                                          setWednesdayClose(date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                      />
                                    </InputGroup>
                                  </Col>
                                </div>
                              )}
                            </div>
                          </FormGroup>{" "}
                          <FormGroup className="col-md-12">
                            <div className="d-flex justify-content-between">
                              <div className="form-check checkbox checkbox-primary mb-0">
                                <Input
                                  id="thursday"
                                  type="checkbox"
                                  checked={thursday}
                                  onChange={(e) =>
                                    setThursday(e.target.checked)
                                  }
                                />
                                <Label htmlFor="thursday">Thursday</Label>
                              </div>
                              {thursday && (
                                <div className="d-flex ">
                                  <Label className="col-sm-3 col-form-label text-end me-2">
                                    Open
                                  </Label>
                                  <Col xl="3" sm="3" lg="4">
                                    <InputGroup
                                      className="date d-flex"
                                      id="dt-minimum"
                                      data-target-input="nearest"
                                    >
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={thursdayOpen}
                                        onChange={(date) =>
                                          setThursdayOpen(date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                      />
                                    </InputGroup>
                                  </Col>
                                  <Label className="col-sm-3 col-form-label text-end me-2">
                                    Close
                                  </Label>
                                  <Col xl="3" sm="3" lg="4">
                                    <InputGroup
                                      className="date"
                                      id="dt-minimum"
                                      data-target-input="nearest"
                                    >
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={thursdayClose}
                                        onChange={(date) =>
                                          setThursdayClose(date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                      />
                                    </InputGroup>
                                  </Col>
                                </div>
                              )}
                            </div>
                          </FormGroup>{" "}
                          <FormGroup className="col-md-12">
                            <div className="d-flex justify-content-between">
                              <div className="form-check checkbox checkbox-primary mb-0">
                                <Input
                                  id="friday"
                                  type="checkbox"
                                  checked={friday}
                                  onChange={(e) => setFriday(e.target.checked)}
                                />
                                <Label htmlFor="friday">Friday</Label>
                              </div>
                              {friday && (
                                <div className="d-flex ">
                                  <Label className="col-sm-3 col-form-label text-end me-2">
                                    Open
                                  </Label>
                                  <Col xl="3" sm="3" lg="4">
                                    <InputGroup
                                      className="date d-flex"
                                      id="dt-minimum"
                                      data-target-input="nearest"
                                    >
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={fridayOpen}
                                        onChange={(date) => setFridayOpen(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                      />
                                    </InputGroup>
                                  </Col>
                                  <Label className="col-sm-3 col-form-label text-end me-2">
                                    Close
                                  </Label>
                                  <Col xl="3" sm="3" lg="4">
                                    <InputGroup
                                      className="date"
                                      id="dt-minimum"
                                      data-target-input="nearest"
                                    >
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={fridayClose}
                                        onChange={(date) =>
                                          setFridayClose(date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                      />
                                    </InputGroup>
                                  </Col>
                                </div>
                              )}
                            </div>
                          </FormGroup>{" "}
                          <FormGroup className="col-md-12 ">
                            <div className="d-flex justify-content-between">
                              <div className="form-check checkbox checkbox-primary mb-0">
                                <Input
                                  id="saturday"
                                  type="checkbox"
                                  checked={saturday}
                                  onChange={(e) =>
                                    setSaturday(e.target.checked)
                                  }
                                />
                                <Label htmlFor="saturday">Saturday</Label>
                              </div>
                              {saturday && (
                                <div className="d-flex pe-10">
                                  <Label className="col-sm-3 col-form-label text-end me-2">
                                    Open
                                  </Label>
                                  <Col xl="3" sm="3" lg="4">
                                    <InputGroup
                                      className="date d-flex"
                                      id="dt-minimum"
                                      data-target-input="nearest"
                                    >
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={saturdayOpen}
                                        onChange={(date) =>
                                          setSaturdayOpen(date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                      />
                                    </InputGroup>
                                  </Col>
                                  <Label className="col-sm-3 col-form-label text-end me-2">
                                    Close
                                  </Label>
                                  <Col xl="3" sm="3" lg="4">
                                    <InputGroup
                                      className="date"
                                      id="dt-minimum"
                                      data-target-input="nearest"
                                    >
                                      <DatePicker
                                        className="form-control datetimepicker-input digits"
                                        selected={saturdayClose}
                                        onChange={(date) =>
                                          setSaturdayClose(date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                      />
                                    </InputGroup>
                                  </Col>
                                </div>
                              )}
                            </div>
                          </FormGroup>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="ps-3">
                      <FormGroup>
                        <div className="dz-message needsclick">
                          <Label>Image</Label>
                          {image && (
                            <div
                              style={{
                                position: "relative",
                                width: "100px",
                                height: "100px",
                              }}
                            >
                              <img
                                src={image?.path}
                                alt={image?.name}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                              <div
                                onClick={() => setImage(null)}
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  backgroundColor: "red",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "50%",
                                  width: "20px",
                                  height: "20px",
                                  cursor: "pointer",
                                  paddingLeft: "5px",
                                }}
                              >
                                &times;
                              </div>
                            </div>
                          )}
                          <Dropzone
                            onChangeStatus={handleFeatureImgChange}
                            maxFiles={1}
                            multiple={false}
                            canCancel={false}
                            inputContent="Drop A File"
                            accept=".jpg,.jpeg,.png,.gif,.svg,.webp,.heif"
                            styles={{
                              dropzoneActive: { borderColor: "green" },
                            }}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="dz-message needsclick">
                          <Label>Other Images</Label>
                          <div className="d-flex flex-wrap gap-3 mb-2">
                            {otherImages.map((file) => (
                              <div
                                key={file.name}
                                style={{
                                  position: "relative",
                                  width: "100px",
                                  height: "100px",
                                }}
                              >
                                <img
                                  src={file.path}
                                  alt={file.name}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                                <div
                                  onClick={() =>
                                    handleImageRemove(file.originalname)
                                  }
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                    backgroundColor: "red",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "50%",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  &times;
                                </div>
                              </div>
                            ))}
                          </div>

                          <Dropzone
                            onChangeStatus={handleOtherImagesChange}
                            multiple
                            inputContent="Drop Files"
                            accept=".jpg,.jpeg,.png,.gif,.svg,.webp,.heif"
                          />
                        </div>
                      </FormGroup>
                    </div>
                  </Col>
                </div>
              </Row>
            </Container>
            <div className="d-flex gap-4 justify-content-end">
              <Btn
                attrBtn={{
                  color: "secondary ms-2",
                  onClick: toggle,
                }}
              >
                Cancel
              </Btn>
              {/* {selectedVendor ? (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>
              ) : (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Save</Btn>
              )} */}
              {!isUploaded ? (
                <Btn attrBtn={{ color: "primary", type: "button" }}>
                  Wait...
                </Btn>
              ) : selectedVendor ? (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>
              ) : (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Save</Btn>
              )}
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddNewVendor;
