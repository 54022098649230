import React, { Fragment, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Toast,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { restRequest } from "../../../../api-utils/apiQueries";
import { toast } from "react-toastify";
import { fileURL } from "../../../../api-utils/constantVariables";
import { Edit, Edit2, Edit3 } from "react-feather";

const AddNewCategory = ({
  showModal,
  toggle,
  selectedCategory,
  setIsUpdated,
}) => {
  const [categoryName, setCategoryName] = useState(
    selectedCategory ? selectedCategory.name : ""
  );
  const [categorySlug, setCategorySlug] = useState(
    selectedCategory ? selectedCategory.slug : ""
  );
  const [categoryImage, setCategoryImage] = useState(null);
  const [iconCode, setIconCode] = useState(
    selectedCategory ? selectedCategory.icon_code : ""
  );
  const [displayOrder, setDisplayOrder] = useState(
    selectedCategory ? selectedCategory.display_order : null
  );
  const [showHeaderMenu, setShowHeaderMenu] = useState(
    selectedCategory ? selectedCategory.show_in_header : false
  );
  const [trendingCategory, setTrendingCategory] = useState(
    selectedCategory ? selectedCategory.trending_category : false
  );
  const [featuredCategory, setFeaturedCategory] = useState(
    selectedCategory ? selectedCategory.featured_category : false
  );
  const [status, setStatus] = useState(
    selectedCategory ? selectedCategory.is_active : true
  );

  const [updateImage, setUpdateImage] = useState(false);
  const [isUploaded, setIsUploaded] = useState(true);

  console.log(selectedCategory);

  const handleInputFile = async (e) => {
    setIsUploaded(false);
    let formData = new FormData();
    formData.append("from", "category");
    formData.append("filed_name", "");
    formData.append("file", e.target.files[0]);
    console.log("Change file ->", e.target.files[0]);

    let response = await restRequest.uploadFileData(formData);
    console.log("File Response", response.data);
    if (response.data.code === 200) {
      setIsUploaded(true);
    }
    setCategoryImage(response.data.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newCategory = {
      name: categoryName,
      slug: categorySlug,
      image: categoryImage !== null ? categoryImage : selectedCategory.image,
      icon_code: iconCode,
      display_order: displayOrder,
      show_in_header: showHeaderMenu,
      trending_category: trendingCategory,
      featured_category: featuredCategory,
      is_active: status,
    };

    if (selectedCategory && selectedCategory._id) {
      newCategory.category_id = selectedCategory._id;
    }

    let selected = selectedCategory;
    console.log("Selected Id ->", selected);
    if (selected?._id) {
      console.log("Updated ->", newCategory);
      let response = await restRequest.updatedCategory(newCategory);
      console.log("Update Response ->", response.data);
      if (response.data) {
        setIsUpdated(true);
        toast.success(response.data.message);
        toggle();
      }
    } else {
      let response = await restRequest.addNewCategory(newCategory);
      console.log("Response ->", response.data);
      if (response.data) {
        setIsUpdated(true);
        toast.success(response.data.message);
        toggle();
      }
    }

    console.log("New Category ->", newCategory);
  };

  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle} className="pt-4">
          <span className="fs-5 text-#534686">
            {" "}
            {selectedCategory ? "Update Category" : "Add New Category"}{" "}
          </span>
        </ModalHeader>
        <ModalBody>
          <Form
            className="form-bookmark needs-validation"
            onSubmit={handleSubmit}
          >
            <div className="form-row">
              <FormGroup className="col-md-12">
                <Label>Category Name</Label>
                <input
                  className="form-control"
                  value={categoryName}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => setCategoryName(e.target.value)}
                />
                {/* <span style={{ color: "red" }}>
                  {errors.url && "Url is required"}
                </span> */}
              </FormGroup>
              <FormGroup className="col-md-12">
                <Label>Category Slug</Label>
                <input
                  className="form-control"
                  value={categorySlug}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => setCategorySlug(e.target.value)}
                />
              </FormGroup>

              <FormGroup className="col-md-12">
                <Label>Icon Code</Label>
                <input
                  className="form-control"
                  type="text"
                  value={iconCode}
                  onChange={(e) => setIconCode(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="col-md-12">
                <Label>Display Order</Label>
                <input
                  className="form-control"
                  name="desc"
                  type="number"
                  value={displayOrder}
                  onChange={(e) => setDisplayOrder(e.target.value)}
                />
              </FormGroup>
              <Row>
                <FormGroup className="col-md-6 mb-0">
                  <input
                    className="form-check-input me-2"
                    name="showHeaderMenu"
                    id="header"
                    type="checkbox"
                    checked={showHeaderMenu}
                    onChange={(e) => setShowHeaderMenu(e.target.checked)}
                  />
                  <label htmlFor="header">Show Header Menu</label>
                </FormGroup>
                <FormGroup className="col-md-6 mb-0">
                  <input
                    className="form-check-input me-2"
                    id="trending"
                    name="desc"
                    type="checkbox"
                    checked={trendingCategory}
                    onChange={(e) => setTrendingCategory(e.target.checked)}
                  />
                  <label htmlFor="trending">Trending Category</label>
                </FormGroup>
                <FormGroup className="col-md-6 mb-0">
                  <input
                    className="form-check-input me-2"
                    name=""
                    id="featured"
                    type="checkbox"
                    checked={featuredCategory}
                    onChange={(e) => setFeaturedCategory(e.target.checked)}
                  />
                  <label htmlFor="featured">Featured Category</label>
                </FormGroup>
              </Row>
              {/* <FormGroup className="col-md-6 mb-0"> */}
              {selectedCategory && (
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={status ? true : false}
                    onChange={(e) => setStatus(e.target.checked)}
                  />
                  <Label>{status ? "active" : "inactive"} </Label>
                </div>
              )}
              <FormGroup className="col-md-12">
                {selectedCategory?.image ? (
                  <div>
                    <div className="d-flex align-items-center py-2">
                      <Label>Category Image</Label>
                      <p
                        className=" ms-5 mb-3"
                        onClick={() => setUpdateImage(true)}
                      >
                        <Edit className="" />
                      </p>
                    </div>
                    {!updateImage ? (
                      <img
                        src={`${selectedCategory?.image?.path}`}
                        width={70}
                        height={70}
                        className="border rounded-circle"
                        // onClick={() => setUpdateImage(true)}
                      />
                    ) : (
                      <input
                        className="form-control"
                        type="file"
                        autoComplete="off"
                        onChange={handleInputFile}
                      />
                    )}
                  </div>
                ) : (
                  <FormGroup>
                    <Label>Category Image</Label>
                    <input
                      className="form-control"
                      type="file"
                      autoComplete="off"
                      onChange={handleInputFile}
                    />
                  </FormGroup>
                )}
              </FormGroup>
              {/* </FormGroup> */}
            </div>
            <div className="d-flex gap-4 justify-content-end">
              <Btn
                attrBtn={{
                  color: "secondary ms-2",
                  onClick: toggle,
                }}
              >
                Cancel
              </Btn>
              {!isUploaded ? (
                <Btn attrBtn={{ color: "primary", type: "button" }}>
                  Wait...
                </Btn>
              ) : selectedCategory ? (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>
              ) : (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Save</Btn>
              )}
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddNewCategory;
