import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { unstable_batchedUpdates } from "react-dom";
import { NotificationProvider } from "./NotificationContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

unstable_batchedUpdates(() => {
  console.error = () => {};
});

class ErrorBoundary extends React.Component {
  componentDidCatch(error) {
    if (error.message.includes("ToastContainer")) {
      return;
    }
  }

  render() {
    return this.props.children;
  }
}

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker.addEventListener("message", (event) => {
//     if (event.data && event.data.action === "playSound") {
//       const audio = new Audio(
//         "https://commondatastorage.googleapis.com/codeskulptor-assets/Evillaugh.ogg"
//       );
//       audio.play().catch((error) => {
//         console.error("Error playing sound:", error);
//       });
//     }
//   });
// }

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    // Listen for messages from the service worker
    navigator.serviceWorker.addEventListener("message", (event) => {
      if (event.data && event.data.type === "PLAY_SOUND") {
        const sound = new Audio("/sound.mp3"); // Path to your sound file
        sound.play().catch((error) => {
          console.error("Error playing sound:", error);
        });
      }
    });
  });
}

const updateServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready;
      registration.update();
      console.log("Service Worker updated.");
    } catch (error) {
      console.error("Service Worker update failed:", error);
    }
  }
};

updateServiceWorker();

root.render(
  <ErrorBoundary>
    <NotificationProvider>
      <App />
    </NotificationProvider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
