import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const token = localStorage.getItem("token");
  // ? JSON.parse(localStorage.getItem("login"))
  // : false;
  return token ? <Outlet /> : <Navigate exact to={`/login`} />;
};
export default PrivateRoute;
