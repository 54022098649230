import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Toast,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { restRequest } from "../../../../api-utils/apiQueries";
import { toast } from "react-toastify";
import SelectField from "../../../../CommonElements/Select/SelectField";
import Select from "react-select";
import { Edit, Edit2 } from "react-feather";
import { fileURL } from "../../../../api-utils/constantVariables";

const AddNewService = ({
  showModal,
  toggle,
  selectedService,
  setIsUpdated,
}) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(
    selectedService
      ? selectedService.categories_id.map((item) => {
          return { value: item._id, label: item.name };
        })
      : []
  );
  const [name, setName] = useState(selectedService ? selectedService.name : "");
  const [slug, setSlug] = useState(selectedService ? selectedService.slug : "");
  const [categoryImage, setCategoryImage] = useState(null);
  const [iconCode, setIconCode] = useState(
    selectedService ? selectedService.icon_code : ""
  );
  const [displayOrder, setDisplayOrder] = useState(
    selectedService ? selectedService?.display_order : null
  );
  const [showHeaderMenu, setShowHeaderMenu] = useState(
    selectedService ? selectedService.show_in_header : false
  );
  const [trendingCategory, setTrendingCategory] = useState(
    selectedService ? selectedService.trending_services : false
  );
  const [featuredCategory, setFeaturedCategory] = useState(
    selectedService ? selectedService.featured_services : false
  );
  const [status, setStatus] = useState(
    selectedService ? selectedService.is_active : true
  );
  const [updateImage, setUpdateImage] = useState(false);
  const [isUploaded, setIsUploaded] = useState(true);

  console.log(selectedService);
  console.log("Status ->", status);

  const handleInputFile = async (e) => {
    setIsUploaded(false);
    let formData = new FormData();
    formData.append("from", "subCategory");
    formData.append("file", e.target.files[0]);

    let response = await restRequest.uploadFileData(formData);
    console.log("File Response", response.data.data);
    setCategoryImage(response.data.data);
  };

  const handleCategories = async (inputValue) => {
    const newOption = { value: inputValue.toLowerCase(), label: inputValue };
    setCategories((prevOptions) => [...prevOptions, newOption]);
    setSelectedCategories((prevOption) => [...prevOption, newOption]);
    if (inputValue) {
      const response = await restRequest.getCategoriesData(inputValue);
      const data = response.data?.data;
      if (response.data.code === 200) {
        setIsUploaded(true);
      }
      // console.log("Data ->", data);
    }
  };
  console.log("Selected ->", selectedCategories);

  const getCategories = async (category) => {
    const response = await restRequest.getCategoriesData(category);
    const data = response.data.data;
    console.log("Category Data ->".data);
    const setData = data.map((role) => ({
      value: role._id,
      label: role.name,
    }));
    setCategories(setData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(
      "Categories ->",
      selectedCategories.map((item) => item.value)
    );
    let newCategory = {
      categories_id: selectedCategories.map((item) => item.value),
      name: name,
      slug: slug,
      image: categoryImage !== null ? categoryImage : selectedService.image,
      icon_code: iconCode,
      display_order: displayOrder,
      show_in_header: showHeaderMenu,
      trending_services: trendingCategory,
      featured_services: featuredCategory,
      is_active: status,
    };

    if (selectedService && selectedService._id) {
      newCategory.service_id = selectedService._id;
    }

    // let formData = new FormData();
    // Object.entries(newCategory).forEach(([key, value]) => {
    //   formData.append(key, value);
    // });

    let selected = selectedService ? selectedService : null;
    console.log("Selected Id ->", selected);
    if (selected?._id) {
      console.log("Updated ->", newCategory);
      let response = await restRequest.updatedService(newCategory);
      console.log("Update Response ->", response.data);
      if (response.data) {
        setIsUpdated(true);
        toast.success(response.data.message);
        toggle();
      }
    } else {
      let response = await restRequest.addNewService(newCategory);
      console.log("Response ->", response.data);
      if (response.data) {
        setIsUpdated(true);
        toast.success(response.data.message);
        toggle();
      }
    }

    console.log("New Category ->", newCategory);
  };

  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle} className="pt-4">
          <span className="fs-5 text-#534686">
            {" "}
            {selectedService
              ? "Update Sub Category"
              : "Add New Sub Category"}{" "}
          </span>
        </ModalHeader>
        <ModalBody>
          <Form
            className="form-bookmark needs-validation"
            onSubmit={handleSubmit}
          >
            <div
              className="form-row h-50 overflow-auto"
              style={{ height: "700px", overflowY: "auto" }}
            >
              <FormGroup className="col-md-12">
                <label className="text-dark-md pb-1">Categories </label>
                <SelectField
                  options={categories}
                  selectedOption={selectedCategories}
                  getData={getCategories}
                  handleOptionCreate={handleCategories}
                  setSelectedOption={setSelectedCategories}
                />
                {/* <Select
                  options={categories}
                  value={selectedCategories}
                  isMulti
                  onChange={(options) => setSelectedCategories(options)}
                /> */}
              </FormGroup>
              <FormGroup className="col-md-12">
                <Label>Name</Label>
                <input
                  className="form-control"
                  value={name}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => setName(e.target.value)}
                />
                {/* <span style={{ color: "red" }}>
                  {errors.url && "Url is required"}
                </span> */}
              </FormGroup>
              <FormGroup className="col-md-12">
                <Label>Slug</Label>
                <input
                  className="form-control"
                  value={slug}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => setSlug(e.target.value)}
                />
              </FormGroup>

              <FormGroup className="col-md-12">
                <Label>Icon Code</Label>
                <input
                  className="form-control"
                  type="text"
                  value={iconCode}
                  onChange={(e) => setIconCode(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="col-md-12">
                <Label>Display Order</Label>
                <input
                  className="form-control"
                  name="desc"
                  type="number"
                  value={displayOrder}
                  onChange={(e) => setDisplayOrder(e.target.value)}
                />
              </FormGroup>
              <Row>
                <FormGroup className="col-md-6 mb-0">
                  <input
                    className="form-check-input me-2"
                    name="showHeaderMenu"
                    id="header"
                    type="checkbox"
                    checked={showHeaderMenu}
                    onChange={(e) => setShowHeaderMenu(e.target.checked)}
                  />
                  <Label htmlFor="header">Show Header Menu</Label>
                </FormGroup>
                <FormGroup className="col-md-6 mb-0">
                  <input
                    className="form-check-input me-2"
                    name="desc"
                    id="trending"
                    type="checkbox"
                    checked={trendingCategory}
                    onChange={(e) => setTrendingCategory(e.target.checked)}
                  />
                  <Label htmlFor="trending">Trending Service</Label>
                </FormGroup>
                <FormGroup className="col-md-6 mb-0">
                  <input
                    className="form-check-input me-2"
                    name=""
                    id="featured"
                    type="checkbox"
                    checked={featuredCategory}
                    onChange={(e) => setFeaturedCategory(e.target.checked)}
                  />
                  <Label htmlFor="featured">Featured Service</Label>
                </FormGroup>
              </Row>
              {/* <FormGroup className="col-md-6 mb-0"> */}
              {selectedService && (
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={status ? true : false}
                    onChange={(e) => setStatus(e.target.checked)}
                  />
                  <Label>{status ? "active" : "inactive"} </Label>
                </div>
              )}
              <FormGroup className="col-md-12">
                {selectedService?.image ? (
                  <div>
                    <div className="d-flex align-items-center py-2">
                      <Label>Service Image</Label>
                      <p
                        className=" ms-5 mb-3"
                        onClick={() => setUpdateImage(true)}
                      >
                        <Edit className="" />
                      </p>
                    </div>
                    {!updateImage ? (
                      <img
                        src={`${selectedService.image?.path}`}
                        width={50}
                        height={50}
                        className="border rounded-circle"
                      />
                    ) : (
                      <input
                        className="form-control"
                        type="file"
                        autoComplete="off"
                        onChange={handleInputFile}
                      />
                    )}
                  </div>
                ) : (
                  <FormGroup>
                    <Label>Service Image</Label>
                    <input
                      className="form-control"
                      type="file"
                      autoComplete="off"
                      onChange={handleInputFile}
                    />
                  </FormGroup>
                )}
              </FormGroup>
              {/* </FormGroup> */}
            </div>
            <div className="d-flex gap-4 justify-content-end">
              <Btn
                attrBtn={{
                  color: "secondary ms-2",
                  onClick: toggle,
                }}
              >
                Cancel
              </Btn>
              {/* {selectedService ? (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>
              ) : (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Save</Btn>
              )} */}
              {!isUploaded ? (
                <Btn attrBtn={{ color: "primary", type: "button" }}>
                  Wait...
                </Btn>
              ) : selectedService ? (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>
              ) : (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Save</Btn>
              )}
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddNewService;
