import React, { Fragment, useEffect, useState } from "react";
import { Form, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import Select from "react-select";
import { Btn } from "../../../AbstractElements";
import { restRequest } from "../../../api-utils/apiQueries";

const UpdateProbability = ({
  isProbability,
  toggle,
  id,
  probabilityCode,
  setIsUpdated,
}) => {
  const [probability, setProbability] = useState([
    { value: 1, label: "Hot" },
    { value: 2, label: "Worm" },
    { value: 3, label: "Cold" },
  ]);
  const [selectedProbability, setSelectedProbability] = useState(null);

  useEffect(() => {
    if (probabilityCode) {
      let selected = probability.find(
        (status) => status.value === probabilityCode
      );
      setSelectedProbability(selected);
    }
  }, [probabilityCode]);

  const handleUpdateProbability = async (e) => {
    e.preventDefault();
    if (!selectedProbability) return;

    let obj = {
      lead_id: id,
      status: selectedProbability.value,
    };
    console.log("Function called");

    let response = await restRequest.updateProbabilityCode(obj);
    console.log("Response Update Status ->", response.status);
    if (response.status === 200) {
      setIsUpdated(true);
      toggle();
    }
  };

  return (
    <Fragment>
      <Modal isOpen={isProbability} toggle={toggle}>
        <ModalHeader toggle={toggle} className="pt-4">
          <span className="fs-5 text-#534686">Update Probability Status</span>
        </ModalHeader>
        <ModalBody className="position-relative">
          <Form onSubmit={handleUpdateProbability}>
            <div>
              <Label>Select Probability Status Code</Label>
              <Select
                options={probability}
                value={selectedProbability}
                onChange={(option) => setSelectedProbability(option)}
              />
            </div>
            <div className="d-flex gap-4 justify-content-end mt-5">
              <Btn
                attrBtn={{
                  color: "secondary ms-2",
                  onClick: toggle,
                }}
              >
                Cancel
              </Btn>

              <Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default UpdateProbability;
