import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import DataTable from "react-data-table-component";
import { restRequest } from "../../../../api-utils/apiQueries";
import AddNewCategory from "./AddNewCategory";
import { Edit3, Search, Trash2 } from "react-feather";
import { Card, CardBody, Col, Input, Label, Media, Row } from "reactstrap";
import { toast } from "react-toastify";

const CategoryWrapper = ({ mainTitle }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [categories, setCategories] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isUpdated, setIsUpdated] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState(0);
  const [totalCategories, setTotalCategories] = useState(0);
  const toggle = () => {
    setShowModal(!showModal);
    setSelectedCategory(null);
  };

  const updatedCategory = (row) => {
    setShowModal(true);
    setSelectedCategory(row);
    // console.log(`You clicked me! ${title}`);
  };

  const handleSort = (column, sortDirection) => {
    console.log("Column Data ->", column.id);
    setSortDirection(sortDirection === "desc" ? 0 : 1);
    setSortColumn(column.id);
    setIsUpdated(true);
  };

  const handleSearchValue = (e) => {
    let data = e.target.value;
    setSearchValue(data);
  };

  const handleSwitch = async (index, type) => {
    const newCategories = [...categories];
    console.log("Category ->", newCategories[index]);
    newCategories[index][type] = !newCategories[index][type];

    if (type === "trending_category") {
      let data = {
        category_id: newCategories[index]["_id"],
        type: "category",
        status: newCategories[index]["trending_category"],
      };
      console.log("api function called");
      let response = await restRequest.changeTrendingStatus(data);
      console.log("Change Status Response ->", response.data);
      if (response.data) {
        toast.success(response.data.message);
      }
      // setIsUpdated(true);
    }
    if (type === "show_in_header") {
      let data = {
        category_id: newCategories[index]["_id"],
        type: "category",
        status: newCategories[index]["show_in_header"],
      };
      console.log("api function called");
      let response = await restRequest.changeShowHeaderStatus(data);
      console.log("Change Status Response ->", response.data);
      // setIsUpdated(true);
      console.log("New Data ->", data);
      if (response.data) {
        toast.success(response.data.message);
      }
    }

    if (type === "featured_category") {
      let data = {
        category_id: newCategories[index]["_id"],
        type: "category",
        status: newCategories[index]["featured_category"],
      };
      console.log("api function called");
      let response = await restRequest.changeFeatureStatus(data);
      console.log("Change Status Response ->", response.data);
      // setIsUpdated(true);
      console.log("New Data ->", data);
      if (response.data) {
        toast.success(response.data.message);
      }
    }
    setCategories(newCategories);
  };

  const tableColumns = [
    {
      name: "Category Name",
      selector: (row) => row.name,
      id: "name",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row.name}</span>
      ),
      sortable: true,
    },
    {
      name: "Orders",
      selector: (row) => row.display_order,
      id: "display_order",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row.display_order}</span>
      ),
      sortable: true,
    },
    // {
    //   name: "Slug",
    //   selector: (row) => row.slug,
    //   id: "slug",
    //   cell: (row) => (
    //     <span className="text-secondary fw-medium">{row.slug}</span>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Status",
      selector: (row) => row.is_active,
      id: "is_active",
      cell: (row) => (
        <span
          className={`p-1 px-2 rounded-3 ${
            row.is_active ? "bg-success" : "bg-danger"
          }`}
          style={{ color: "" }}
        >
          {row.is_active ? "active" : "inactive"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Header Menu",
      selector: (row) => row.show_in_header,
      // id: "is_active",
      // sortable: true,
      cell: (row, index) => (
        <Media className="d-flex mt-2">
          <Media
            body
            className={`flex-grow-1 text-end icon-state switch-outline`}
          >
            <Label className="switch">
              <Input
                type="checkbox"
                checked={row.show_in_header}
                onChange={() => handleSwitch(index, "show_in_header")}
              />
              <span className="switch-state"></span>
            </Label>
          </Media>
          {/* <Label className="col-form-label m-r-10">Header Menu </Label> */}
        </Media>
      ),
      grow: 0.5,
    },
    {
      name: "Trending",
      selector: (row) => row.trending_category,
      // id: "is_active",
      // sortable: true,
      cell: (row, index) => (
        <Media className="d-flex mt-2">
          <Media
            body
            className={`flex-grow-1 text-end icon-state switch-outline`}
          >
            <Label className="switch">
              <Input
                type="checkbox"
                checked={row.trending_category}
                onChange={() => handleSwitch(index, "trending_category")}
              />
              <span className="switch-state"></span>
            </Label>
          </Media>
          {/* <Label className="col-form-label m-r-10">Trending </Label> */}
        </Media>
      ),
      grow: 0.5,
    },
    {
      name: "Featured",
      selector: (row) => row.featured_category,
      // id: "is_active",
      // sortable: true,
      cell: (row, index) => (
        <Media className="d-flex mt-2">
          <Media
            body
            className={`flex-grow-1 text-end icon-state switch-outline switch-md`}
          >
            <Label className="switch">
              <Input
                type="checkbox"
                checked={row.featured_category}
                onChange={() => handleSwitch(index, "featured_category")}
              />
              <span className="switch-state "></span>
            </Label>
          </Media>
          {/* <Label className="col-form-label m-r-10">Featured </Label> */}
        </Media>
      ),
      grow: 0.5,
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <button
          onClick={() => updatedCategory(d)}
          className="border border-none rounded text-success me-2"
        >
          <Edit3 />
        </button>,
        // <button
        //   onClick={() => {}}
        //   className="border border-none rounded text-danger"
        // >
        //   <Trash2 />
        // </button>,
      ],
    },
  ];

  const fetchCategories = async () => {
    let response = await restRequest.getAllCategories(
      page,
      sortDirection,
      sortColumn,
      searchValue
    );
    let data = response.data.data;
    console.log("Get Response ->", data);
    setCategories(data.categories);
    setTotalPage(data.pager.totalPages);
    setTotalCategories(data.pager.totalRecords);
    setIsUpdated(false);
  };

  console.log("IS UPDATED ->", isUpdated);

  useEffect(() => {
    setSelectedCategory(null);
    if (isUpdated) fetchCategories();
  }, [isUpdated, page]);

  const handlePageChange = (page) => {
    setPage(page);
    setIsUpdated(true);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: false,
  };
  console.log("Show modal ->", showModal);

  // const handleDelete = () => {
  //   if (
  //     window.confirm(
  //       `Are you sure you want to delete:\r ${selectedRows.map(
  //         (r) => r.title
  //       )}?`
  //     )
  //   ) {
  //     setToggleDelet(!toggleDelet);

  //     setData(
  //       data.filter((item) =>
  //         selectedRows.filter((elem) => elem.id === item.id).length > 0
  //           ? false
  //           : true
  //       )
  //     );
  //     setSelectedRows("");
  //   }
  // };
  return (
    <Fragment>
      <Row>
        <div className="d-flex ">
          <Breadcrumbs
            mainTitle={`Categories (${totalCategories})`}

            // title={title ? title : "Dashboard"}
            // parent={parent ? parent : ""}
          />
          <div className="d-flex gap-4">
            <div
              className={`input-group left-header horizontal-wrapper`}
              style={{ width: "300px" }}
            >
              <Input
                type="text"
                placeholder="Search.."
                value={searchValue}
                onChange={handleSearchValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchValue(e.target.value);
                    setIsUpdated(true);
                  }
                }}
              />
              <span className="input-group-text mobile-search">
                <Search
                  onClick={() => {
                    setIsUpdated(true);
                  }}
                />
              </span>
            </div>
            <Btn
              attrBtn={{
                color: "primary",
                className: "btn-block ",
                onClick: () => setShowModal(true),
              }}
            >
              <p className="mb-0 fs-7" style={{ width: "100px" }}>
                Add Categories
              </p>
            </Btn>
          </div>
        </div>
        <Col>
          <Card>
            <CardBody className="data-tables">
              <DataTable
                data={categories}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
                // selectableRows
                paginationServer
                paginationTotalRows={totalCategories}
                onChangePage={handlePageChange}
                paginationComponentOptions={paginationComponentOptions}
                paginationPerPage={20}
                onSort={handleSort}
                sortServer
                // onSelectedRowsChange={handleRowSelected}
                // clearSelectedRows={toggleDelet}
              />
            </CardBody>
          </Card>
        </Col>
        {showModal && (
          <AddNewCategory
            showModal={showModal}
            setShowModal={setShowModal}
            toggle={toggle}
            selectedCategory={selectedCategory}
            setIsUpdated={setIsUpdated}
          />
        )}
      </Row>
    </Fragment>
  );
};

export default CategoryWrapper;
