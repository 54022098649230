import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Bell } from "react-feather";
import { LI, P, H5 } from "../../../AbstractElements";
import { Link } from "react-router-dom";
import { restRequest } from "../../../api-utils/apiQueries";
import { NotificationContext } from "../../../NotificationContext";
import { Nav } from "reactstrap";

const Notifications = () => {
  let token = localStorage.getItem("token");
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { notificationCount, setNotificationCount } =
    useContext(NotificationContext);

  const [showModal, setShowModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [isFetching, setIsFetching] = useState(false);

  // Reference to the scrollable div
  const scrollRef = useRef(null);

  const fetchNotificationCount = async () => {
    let response = await restRequest.getNotificationCount();
    let count = response.data.data.count;
    setNotificationCount(count);
  };

  useEffect(() => {
    if (token) fetchNotificationCount();
  }, []);

  const fetchNotifications = async () => {
    let response = await restRequest.getAllNotification(offset, limit);
    let data = response.data.data;

    setNotifications((prev) => [...prev, ...data]);
    setOffset((prevOffset) => prevOffset + data.length);
    setIsLoading(false);
    setIsFetching(false);
  };

  const handleScroll = useCallback(() => {
    const scrollDiv = scrollRef.current;
    console.log("scroll div ->", scrollDiv);

    if (scrollDiv && !isFetching) {
      const { scrollTop, scrollHeight, clientHeight } = scrollDiv;

      // Check if user has scrolled to the bottom
      if (
        scrollTop + clientHeight >= scrollHeight - 5 &&
        notificationCount > notifications.length
      ) {
        setIsFetching(true);
        fetchNotifications();
      }
    }
  }, [isFetching, offset]);

  useEffect(() => {
    const scrollDiv = scrollRef.current;
    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const handleBellClick = () => {
    setShowModal(!showModal);

    // Fetch notifications when the modal is opened
    if (!showModal && !notifications.length) {
      fetchNotifications();
    }
  };

  return (
    <Fragment>
      <LI>
        <div className="notification-box" onClick={handleBellClick}>
          <Bell />
          <span className="badge rounded-pill badge-primary">
            {notificationCount}
          </span>
        </div>
        {showModal && (
          <div
            className="position-fixed right-0 bg-white "
            style={{
              height: "80vh",
              background: "white",
              overflowY: "auto",
              top: "90px",
            }}
            ref={scrollRef} // Attach the ref to the scrollable div
          >
            <Nav className="flex-column nac-pills p-4 h-full">
              <div className="customizer-header d-flex justify-content-between">
                <H5>Notifications</H5>
                <i
                  className="icofont-close icon-close"
                  onClick={() => setShowModal(false)}
                ></i>
              </div>
              <div className="customizer-body tab-content">
                {notifications.map((items, id) => (
                  <LI key={id}>
                    <Link
                      to={`${
                        items.type === "enquiry"
                          ? `/leads/${items._id}`
                          : items.type === "vendor"
                          ? `/verified-vendors/${items.notification_detail.data._id}`
                          : items.type === "vendor_service"
                          ? `/verified-vendors/${items.notification_detail.data.vendor_id}`
                          : ""
                      }`}
                    >
                      <P>
                        <i className="fa fa-circle-o me-3 font-success"></i>
                        {items.notification_detail.notification
                          ? items.notification_detail.notification.body
                          : "New Lead"}
                      </P>
                    </Link>
                  </LI>
                ))}
                {isFetching && <P>Loading more notifications...</P>}
              </div>
            </Nav>
          </div>
        )}
      </LI>
    </Fragment>
  );
};

export default Notifications;
