import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, P, H3 } from "../../AbstractElements";
import { BasicExamplestext, scrollpara, SampleCard } from "../../Constant";
import DashboardWrapper from "../Pages/dashboard/DashboardWrapper";

const Dashboard = ({ mainTitle, title, parent }) => {
  return (
    <Fragment>
      {/* <Breadcrumbs
        mainTitle={mainTitle ? mainTitle : "Dashboard"}
        // title={title ? title : "Dashboard"}
        // parent={parent ? parent : ""}
      />
      <Container fluid={true} className="fix-sample">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H3>{SampleCard}</H3>
                <span>{BasicExamplestext}</span>
              </CardHeader>
              <CardBody>
                <P>{scrollpara}</P>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container> */}

      <DashboardWrapper />
    </Fragment>
  );
};
export default Dashboard;
