import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";
import { H3, H5, Image, P } from "../../../AbstractElements";
import { TopProductsData } from "../../../Data/Dashboard/Ecommerce/Data";
import { Link } from "react-router-dom";
// import HeaderCard from "../../../Common/Component/HeaderCard";

const TopProducts = () => {
  return (
    <Fragment>
      <Col lg="4" sm="6 box-col-30">
        <Card className="top-products">
          <CardHeader className="pb-0">
            <H3>Top Products</H3>
          </CardHeader>
          <CardBody>
            <div className="table-responsive">
              <Table className="table table-bordernone">
                <tbody>
                  {TopProductsData.map((data) => {
                    return (
                      <tr key={data.id}>
                        <td>
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <div className="icon">
                                <Image
                                  attrImage={{
                                    className: "img-fluid",
                                    src: `${data.img}`,
                                    alt: "chair",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <Link
                              // to={`${process.env.PUBLIC_URL}/app/ecommerce/product-page/1`}
                              >
                                <H5>{data.title}</H5>
                              </Link>
                              <P>{data.para}</P>
                            </div>
                          </div>
                        </td>
                        <td>
                          <H5>{data.coupon}</H5>
                          <P>{data.coupon_para}</P>
                        </td>
                        <td className="text-center">
                          {" "}
                          <i className={`flag-icon ${data.icon}`}></i>
                        </td>
                        <td>
                          <H5>{data.percent}</H5>
                          <P>{data.price}</P>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default TopProducts;
